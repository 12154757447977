import React, { useState } from "react";
import axios from "axios";
import { apiKey, create_vendor } from "../../../Api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import { FaUsers } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CreateVendor({ openvendor, handleCloseVendor, fetchVendors }) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [vendor_name, setVendor_name] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [alternative_phone, setAlternativePhone] = useState("");
  const [country_code, setCountryCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [contact_name, setContact_name] = useState("");
  const [gst, setGST] = useState("");

  const handleAddVendor = () => {
    let formattedData = {
      name: vendor_name,
      email: email,
      phone: contact,
      alternative_phone: alternative_phone,
      country_code: country_code,
      address: address,
      city: city,
      state: state,
      country: country,
      pincode: pincode,
      contact_name: contact_name,
      gst_no: gst,
    };

    axios
      .post(create_vendor, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(
            `A new vendor ${vendor_name} has been created successfully!`
          );

          // Clear fields after success
          setVendor_name("");
          setEmail("");
          setContact("");
          setAlternativePhone("");
          setCountryCode("");
          setAddress("");
          setCity("");
          setState("");
          setCountry("");
          setPincode("");
          setContact_name("");
          setGST("");
          fetchVendors();
          handleCloseVendor(true);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setOpenError(true);
      });
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openvendor}
        onClose={handleCloseVendor}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <FaUsers style={{ fontSize: "25px" }} /> Create Vendor
          </Typography>
          <div className="row">
            <div className="col-md-12 my-2">
              <TextField
                value={vendor_name}
                label="Vendor Name"
                size="small"
                required
                fullWidth
                onChange={(e) => setVendor_name(e.target.value)}
              />
            </div>
            <div className="col-md-12 my-1">
              <TextField
                value={gst}
                label="GST Number"
                size="small"
                fullWidth
                onChange={(e) => setGST(e.target.value)}
              />
            </div>
            <div className="col-md-6 my-1">
              <TextField
                type="email"
                value={email}
                label="Email"
                size="small"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md-6 my-1">
              <TextField
                type="tel" // Change to "tel" for phone input
                value={contact}
                label="Phone"
                size="small"
                fullWidth
                onChange={(e) => setContact(e.target.value)}
              />
            </div>
          </div>
          <h6 style={{ marginTop: "5px" }}>Contact Details:</h6>
          <div className="row">
            <div className="col-md-6 my-1">
              <TextField
                value={contact_name}
                label="Contact Name"
                size="small"
                fullWidth
                onChange={(e) => setContact_name(e.target.value)}
              />
            </div>
            <div className="col-md-6 my-1">
              <TextField
                type="tel" // Change to "tel" for alternative phone
                value={alternative_phone}
                label="Alternative Phone"
                size="small"
                fullWidth
                onChange={(e) => setAlternativePhone(e.target.value)}
              />
            </div>

            <div className="col-md-4 my-1">
              <TextField
                type="text" // Ensure pincode is treated as text
                value={pincode}
                label="Pincode"
                size="small"
                fullWidth
                onChange={(e) => setPincode(e.target.value)}
              />
            </div>

            <div className="col-md-4 my-1">
              <TextField
                value={state}
                label="State"
                size="small"
                fullWidth
                onChange={(e) => setState(e.target.value)}
              />
            </div>

            <div className="col-md-4 my-1">
              <TextField
                value={country_code}
                label="Country Code"
                size="small"
                fullWidth
                onChange={(e) => setCountryCode(e.target.value)}
              />
            </div>
            <div className="col-md-6 my-1">
              <TextField
                value={city}
                label="City"
                size="small"
                fullWidth
                onChange={(e) => setCity(e.target.value)}
              />
            </div>
            <div className="col-md-6 my-1">
              <TextField
                value={country}
                label="Country"
                size="small"
                fullWidth
                onChange={(e) => setCountry(e.target.value)}
              />
            </div>
            <div className="col-md-12 my-1">
              <TextField
                multiline
                rows={3}
                value={address}
                label="Address"
                size="small"
                fullWidth
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
          <Button
            style={{ marginTop: "8px", float: "right", marginLeft: "5px" }}
            onClick={handleAddVendor}
            variant="outlined"
          >
            Create Vendor
          </Button>
          <Button
            style={{ marginTop: "8px", float: "right" }}
            onClick={handleCloseVendor}
            color="error"
            variant="outlined"
          >
            Cancel
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default CreateVendor;
