import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, add_rack, fetch_racks } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast, ToastContainer } from "react-toastify";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: "600px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AddNewRack({
  setOpenAddRackModal,
  openAddRackModal,
  setRacks,
  row_id,
  fetchData,
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [newrack, setRackName] = useState("");

  const handleClose = () => {
    setOpenAddRackModal(false);
    setError("");
    setRackName("");
  };

  const handleAddRow = (e) => {
    e.preventDefault();
    if (newrack && newrack.length == 0) {
      toast.error("Rack cannot be empty!");
      return;
    }

    const formattedData = {
      row_pk: parseInt(row_id),
      racks: newrack,
    };

    axios
      .post(add_rack, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          // setOpenSuccess(true);
          toast.success(`New Racks Added Successfully!`);
          fetchData();
          setRackName("");
          try {
            axios
              .get(fetch_racks, {
                params: {
                  row_pk: parseInt(row_id),
                },
                headers: {
                  "API-Key": apiKey,
                },
              })
              .then((response) => {
                setRacks(response.data.racks);
              })
              .catch((error) => {
                setError(error);
              });
          } catch (error) {
            console.log(error);
          }
          setOpenAddRackModal(false);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        console.log(error.message);
        setError(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
        setOpenError(true);
      });
  };

  return (
    <>
      <ToastContainer />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openAddRackModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="p"
            component="p"
            style={{ marginBottom: "15px" }}
          >
            Add New Rack
          </Typography>

          <div
            style={{
              maxHeight: "30vh",
            }}
          >
            <Autocomplete
              multiple
              id="racks"
              value={newrack}
              options={[]}
              freeSolo
              onChange={(event, newValue) => setRackName(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    key={index}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  multiline
                  rows={2}
                  size="small"
                  variant="outlined"
                  label="Racks"
                  placeholder="Add Rack"
                />
              )}
            />

            {/* <TextField
              id="outlined-row-name"
              label="Rack Name"
              value={newrack}
              size="small"
              required
              onChange={(e) => setRackName(e.target.value)}
              fullWidth
            /> */}
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "2px" }}
              variant="outlined"
              color="primary"
              onClick={handleAddRow}
              // disabled={
              //   Boolean(error) ||
              //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
              // }
            >
              Add Rack
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default AddNewRack;
