import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { fetch_allocation_history, apiKey, fetch_warehouse } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CustomToolbar from "../../Components/CustomToolbar";
import Error from "../../Messages/Error";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ViewAllocationHistoryModal from "./ViewAllocationHistoryModal";
import { Modal } from "@mui/material";
import { IoMdArrowRoundBack } from "react-icons/io";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1400,
  minWidth: 400,
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AllocationHistory() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(userDetails.primary_warehouse_pk);

  const handleWarehouseChange = (e) => {
    const ware = e.target.value;
    setWarehouse(ware);
  };

  useEffect(() => {
    fetchDataWarehouse();
    fetchData();
  }, [warehouse]);

  const fetchDataWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.warehouses);
        setWarehouses(response.data.warehouses);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 180000); // 3 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, [currentPage, pageSize, searchquery, filterModel]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      warehouse_pk: warehouse,
      selectedBrands: [],
      selectedTypes: [],
      selectedStocks: [],
      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_allocation_history, data, { headers })
      .then((response) => {
        console.log(response.data.allocation_history);
        setProducts(response.data.allocation_history);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params) => {
        const dateValue = params.row.date;

        // Check if dateValue is a valid date object
        const date = new Date(dateValue);
        const isValidDate = !isNaN(date.getTime()); // Check if it's a valid date

        return <span>{isValidDate ? date.toLocaleString() : "-"}</span>;
      },
    },

    { field: "user_name", headerName: "Allocated By", width: 250 },

    {
      field: "ware_house__ware_house",
      headerName: "Warehouse",
      width: 230,
    },
    {
      field: "row",
      headerName: "Row",
      width: 150,
      renderCell: (params) => params.value.rack_number__row__row_name,
    },
    {
      field: "rack",
      headerName: "Row",
      width: 150,
      renderCell: (params) => params.value.rack_number__rack_name,
    },

    {
      field: "history_detail",
      headerName: "Products",
      width: 120,
      renderCell: (params) => <span>{params.value.length} </span>,
    },

    {
      field: "details",
      headerName: "Action",
      width: 120,
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() => handleViewAllocationHistory(params.row)}
        >
          Details
        </Button>
      ),
    },
  ];

  // Modal of View Allocation

  const [opendetail, setOpenDetail] = useState(false);
  const [selectedAllocatedDetail, setSelectedAllocatedDetail] = useState();

  const handleViewAllocationHistory = (allocateDetails) => {
    setSelectedAllocatedDetail(allocateDetails);
    setOpenDetail(true);
  };

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <ViewAllocationHistoryModal
        opendetail={opendetail}
        allocateDetails={selectedAllocatedDetail}
        apiKey={apiKey}
        setOpenDetail={setOpenDetail}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/unallocated-products")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Allocation History
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6 md-my-0 my-2"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <FormControl size="small">
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    value={warehouse}
                    label="Select Warehouse"
                    onChange={handleWarehouseChange}
                  >
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              disableRowSelectionOnClick
              rows={products}
              columns={columns}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelection) =>
                setSelectionModel(newSelection)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllocationHistory;
