import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  fetch_warehouse,
  apiKey,
  printracksBarcode,
  add_warehouse,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
// import ImportProducts from "./ImportProductsModal";
import Delete from "../../Messages/DeleteWarehouse";
import CustomToolbar2 from "../../Components/CustomToolbar2";
import Error from "../../Messages/Error";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignWare from "./UpdateRows/EditWarehouse";
import PrintBarcode from "./PrintRackBarcode";
import DeleteWarehouse from "../../Messages/DeleteWarehouse/DeleteWarehouse";
import Success from "../../Messages/Success";
import AddWarehouseModal from "./AddWarehouseModal.js";
import { IoMdArrowRoundBack } from "react-icons/io";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Warehouses() {
  const [warehouses, setWarehouses] = useState([]);
  const [error, setError] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openError, setOpenError] = useState(false);
  const [warehouse, setWarehouse] = useState("");
  const [address, setAddress] = useState("");
  const [warepk, setWarePk] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalBarcode, setOpenModalBarcode] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setWarehouses(response.data.warehouses);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleEditClick = (id, ware_house, address) => {
    setOpenModal(true);
    setWarePk(id);
    setWarehouse(ware_house);
    setAddress(address);
  };

  const [racksbar, setRacksBar] = useState("");
  const handlePrintBarcode = (id) => {
    setOpenModalBarcode(true);
    try {
      axios
        .get(printracksBarcode, {
          params: {
            warehouse_pk: parseInt(id),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setRacksBar(response.data.racks_barcode);
          console.log(response.data.racks_barcode);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch {
      setError(error.response.data.message);
      setOpenError(true);
    }
  };

  const handleViewClick = (sku) => {
    navigate(`/row-rack/${sku}`);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "ware_house", headerName: "Warehouse", width: 200 },
    {
      field: "address",
      headerName: "Address",
      width: 500,
      renderCell: (params) => (
        <span>{params.row.address ? params.row.address : "-"}</span>
      ),
    },
    { field: "allocated", headerName: "Allocated", width: 300 },

    {
      field: "unallocated",
      headerName: "Unallocated",
      width: 200,
      renderCell: (params) => (
        <span style={{ color: "red" }}>{params.row.unallocated}</span>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            size="small"
            color="success"
            onClick={() => handleViewClick(params.row.id)}
          >
            View
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            variant="outlined"
            size="small"
            color="primary"
            onClick={() =>
              handleEditClick(
                params.row.id,
                params.row.ware_house,
                params.row.address
              )
            }
          >
            Edit
          </Button>
          <Button
            style={{ marginLeft: "5px" }}
            variant="outlined"
            size="small"
            color="error"
            onClick={() => handlePrintBarcode(params.row.id)}
          >
            Print
          </Button>
        </>
      ),
    },
  ];

  // Delete Warehouse

  const [pks, setPks] = useState([]);
  const [openDeleteWarehouse, setOpenDeleteWarehouse] = useState(false);

  const handleDeleteWarehouse = () => {
    const selectedPks = [];
    const ourDiv = document.querySelector(".all-warehouses");
    const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
    let loopCount = 0;
    Array.from(allCheckBoxes).forEach((single_input) => {
      if (single_input.checked) {
        loopCount++;
        if (loopCount > 1) {
          const cur_row = single_input.closest(".MuiDataGrid-row");
          const third_child = cur_row.children[2];
          const selected_pk = third_child.innerHTML;
          selectedPks.push(selected_pk);
        }
      }
    });

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      toast.error("No Warehouse selected!");
      // setOpenError(true)
      return;
    } else {
      // setOpenDelete(true);
      setOpenDeleteWarehouse(true);
    }
  };

  // Too add Warehouse
  const [openSuccess, setOpenSuccess] = useState(false);
  const [success, setSuccess] = useState("");

  const [OpenAddWarehouseModal, setOpenAddWarehouseModal] = useState(false); // State for modal

  const handleAddWarehouse = (warehouse, address) => {
    const formattedData = {
      warehouse: warehouse,
      address: address,
    };

    axios
      .post(add_warehouse, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(`A new warehouse has been added successfully!`);
          setTimeout(() => {
            setOpenAddWarehouseModal(false);
            fetchData();
          }, 2000);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
        setOpenError(true);
      });
  };

  return (
    <>
      {console.log("warehouses :", warehouses)}
      <ToastContainer />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <AddWarehouseModal
        open={OpenAddWarehouseModal}
        handleClose={() => setOpenAddWarehouseModal(false)}
        handleAddWarehouse={handleAddWarehouse}
      />

      <DeleteWarehouse
        openDeleteWarehouse={openDeleteWarehouse}
        setOpenDeleteWarehouse={setOpenDeleteWarehouse}
        selectedPks={pks}
        fetchData={fetchData}
      />

      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        warepk={warepk}
        warehouse={warehouse}
        address={address}
        fetchData={fetchData}
      />

      <PrintBarcode
        openModalBarcode={openModalBarcode}
        setOpenModalBarcode={setOpenModalBarcode}
        racksbar={racksbar}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-md-6">
              <div style={{ display: "flex" }}>
                <IoMdArrowRoundBack
                  id="backbtn"
                  onClick={() => navigate("/")}
                />
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="inherit">Inventory</Typography>
                  <Typography sx={{ color: "text.primary" }}>
                    Warehouses
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div
              className="col-md-6"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => setOpenAddWarehouseModal(true)}
              >
                Add Warehouse
              </Button>
              <Button
                variant="outlined"
                color="error"
                size="small"
                onClick={handleDeleteWarehouse}
              >
                Delete Warehouse
              </Button>
            </div>
          </div>

          <div
            style={{
              height: "85vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
            className="all-warehouses"
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar2 }}
              disableRowSelectionOnClick
              loading={loading}
              rows={warehouses}
              columns={columns}
              checkboxSelection
              isRowSelectable={(params) => params.row.deletable !== 0}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Warehouses;
