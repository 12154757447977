import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { fetch_sale, apiKey, fetch_sale_detail } from "../../Api";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CancelSale from "../../Messages/CancelSale";
import Error from "../../Messages/Error";
import { IoMdArrowRoundBack } from "react-icons/io";

function AddRequirements() {
  const [products, setWarehouses] = useState([]);
  const socketRef = useRef(null); // Create a ref to store the WebSocket instance
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Create a WebSocket connection
    socketRef.current = new WebSocket(
      "ws://192.168.1.7:8003/ws/fetch-requirement-list/"
    );

    // Connection opened
    socketRef.current.addEventListener("open", (event) => {
      console.log("WebSocket is open now.");
      socketRef.current.send(JSON.stringify({ type: "request_data" }));
    });

    // Listen for messages
    socketRef.current.addEventListener("message", (event) => {
      const data = JSON.parse(event.data);
      console.log("server response:", data.requirements);

      let finaldata = data.requirements;
      setWarehouses(data.requirements);

      // Update state with received warehouse data
      if (finaldata) {
        setWarehouses((prevWarehouses) => [...prevWarehouses, finaldata]);
      }
    });

    // Handle errors
    socketRef.current.addEventListener("error", (event) => {
      console.error("WebSocket error:", event);
    });

    // Connection closed
    socketRef.current.addEventListener("close", (event) => {
      console.log("WebSocket is closed now.");
    });

    // Cleanup on component unmount
    return () => {
      socketRef.current.close();
    };
  }, []); // Empty dependency array to run only on mount

  const sendMessage = () => {
    // Check if socket is defined before sending the message
    if (socketRef.current) {
      // Send a message to the WebSocket server
      const data = { message: "Hello from the client!" };
      socketRef.current.send(JSON.stringify(data));
    } else {
      console.error("WebSocket is not connected.");
    }
  };

  return (
    <>
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            {/* <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} /> */}
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Sales</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Add Requirement
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              {/* <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div> */}

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                {/* <Button
                  variant="outlined"
                  size="small"
                  onClick={handleSaleType}
                >
                  Add Product
                </Button> */}
              </div>
            </div>
          </div>

          <div
            className="card p-2"
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <TableContainer
              component={Paper}
              style={{
                marginTop: "16px",
                maxHeight: "350px",
                overflow: "auto",
              }}
            >
              <Table size="small">
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>SKU</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Required Qty</TableCell>
                    <TableCell>Avail Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {products.map((data) => (
                    <TableRow key={data.id}>
                      <TableCell>{data.id}</TableCell>
                      <TableCell>{data.ware_house}</TableCell>
                      <TableCell>{data.address}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddRequirements;
