import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, fetch_purchase_detail, fetch_warehouse } from "../../Api";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useParams } from "react-router-dom";
import ReceiveAllModal from "./RcvAlltoWarehouseModal";
import RcvImg from "../../Assets/Img/handshake.gif";

function GoodAllocation() {
  const [openModalWarehouse, setOpenModalWarehouse] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [rcvstatus, setRcvStatus] = useState("");
  const [rcvby, setRcvBy] = useState("");
  const [rcvdate, setRcvDate] = useState("");
  const [rcvwarehouse, setRcvWarehouse] = useState("");

  const [error, setError] = useState("");

  const { id } = useParams();

  useEffect(() => {
    fetchPurchases();
  }, []);

  const fetchPurchases = () => {
    axios
      .get(fetch_purchase_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          purchase_pk: parseInt(id),
        },
      })
      .then((response) => {
        console.log(response);
        setSelectedProducts(response.data.purchase_detail.items_detail);
        setRcvStatus(response.data.purchase_detail.receive_status);
        setRcvBy(response.data.purchase_detail.received_by);
        setRcvDate(response.data.purchase_detail.received_date);
        setRcvWarehouse(response.data.purchase_detail.received_warehouse);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [warehouses, setWarehouses] = useState([]);

  const handleRcvAll = () => {
    try {
      axios
        .get(fetch_warehouse, {
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          // console.log(response);
          setWarehouses(response.data.warehouses);
        })
        .catch((error) => {
          setError(error);
        });
    } catch {
      console.log("error");
      setOpenModalWarehouse(false);
    }
    setOpenModalWarehouse(true);
  };

  return (
    <>
      <ReceiveAllModal
        setOpenModalWarehouse={setOpenModalWarehouse}
        openModalWarehouse={openModalWarehouse}
        warehouses={warehouses}
        purchase_pk={id}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit" style={{ cursor: "pointer" }}>
              Purchases
            </Typography>
            <Typography sx={{ color: "text.primary" }}>
              Receive Goods
            </Typography>
          </Breadcrumbs>

          <div className="row my-2">
            <div className="col-lg-8">
              <div className="card p-4">
                <div className="products_table_class">
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell>SKU</TableCell>
                          <TableCell align="left">Product</TableCell>
                          <TableCell align="left">Purchased Qty</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedProducts && selectedProducts.length > 0 ? (
                          <>
                            {selectedProducts.map((product, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  style={{ width: "6.5vw" }}
                                  scope="row"
                                >
                                  {product.product_sku}
                                </TableCell>
                                <TableCell align="left">
                                  {product.product_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: "8vw" }}
                                >
                                  {product.quantity}
                                </TableCell>
                                {/*  <TableCell
                                  align="center"
                                  style={{ width: "8vw" }}
                                >
                                  {product.allocated_quantity}
                                </TableCell> */}

                                {/* <TableCell
                                  align="left"
                                  style={{ width: "9vw" }}
                                >
                                  {product.allocated_quantity === 0 ? (
                                    <>
                                      <Chip
                                        size="small"
                                        label="Pending"
                                        color="error"
                                        variant="outlined"
                                      />
                                    </>
                                  ) : product.quantity ===
                                    product.allocated_quantity ? (
                                    <>
                                      <Chip
                                        size="small"
                                        label="Completed"
                                        color="success"
                                        variant="outlined"
                                      />
                                    </>
                                  ) : (
                                    <Chip
                                      size="small"
                                      label="Partially Completed"
                                      color="warning"
                                      variant="outlined"
                                    />
                                  )}
                                </TableCell> */}
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <>
                            <TableRow>
                              <TableCell
                                colSpan="7"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                No Products Selected
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card p-4">
                {rcvstatus && rcvstatus === 1 ? (
                  <>
                    <h4>Received Details</h4>
                    <hr />
                    <div className="row">
                      <div className="col-6 my-2">Received Warehouse:</div>
                      <div className="col-6 my-2">
                        <h5>{rcvwarehouse}</h5>
                      </div>

                      <div className="col-6 my-2">Received Date:</div>
                      <div className="col-6 my-2">
                        <h5>{rcvdate}</h5>
                      </div>

                      <div className="col-6 my-2">Received By:</div>
                      <div className="col-6 my-2">
                        <h5>{rcvby}</h5>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="card" onClick={() => handleRcvAll()}>
                      <div className="row">
                        <div className="col-3">
                          <img
                            src={RcvImg}
                            className="img-fluid"
                            style={{
                              height: "auto",
                              width: "100%",
                              mixBlendMode: "multiply",
                            }}
                          />
                        </div>
                        <div
                          className="col-9"
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button variant="outlined">Receive All</Button>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GoodAllocation;
