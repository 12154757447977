import React from 'react';
import Navbar from './Components/Navbar';
import Sidebar from './Components/Sidebar';



const Layout = ({ children, toggleSidebar, isActive }) => {
    return (
        <>
            <Navbar toggleSidebar={toggleSidebar} />
            <div className="container-fluid page-body-wrapper">
                <Sidebar isActive={isActive} />
                {children}
            </div>
        </>
    );
};

export default Layout;
