import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { fetch_sale, apiKey, fetch_sale_detail } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Delete from "../../Messages/CancelSale";
import CustomToolbar from "../../Components/CustomToolbar";
import Error from "../../Messages/Error";
import { IoMdArrowRoundBack } from "react-icons/io";

function AllDispatch() {
  const [purchases, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, filterModel]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,

      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_sale, data, { headers })
      .then((response) => {
        console.log(response.data.data);
        const filteredSales = response.data.data.filter(
          (sale) => sale.type_of_sale === 2 && sale.dispatched_status === 0
        );
        setSales(filteredSales);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handleEditClick = (id) => {
    navigate(`/update-sale/${id}`);
  };

  const handleViewClick = (id) => {
    navigate(`/dispatch-sale-scan/${id}`);
  };

  const [pks, setPks] = useState([]);

  const handleDeleteClick = () => {
    const selectedPks = [];
    const ourDiv = document.querySelector(
      ".MuiDataGrid-main.css-3eek4p-MuiDataGrid-main"
    );
    const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
    let loopCount = 0;
    Array.from(allCheckBoxes).forEach((single_input) => {
      if (single_input.checked) {
        loopCount++;
        if (loopCount > 1) {
          const cur_row = single_input.closest(".MuiDataGrid-row");
          const third_child = cur_row.children[2];
          const selected_pk = third_child.innerHTML;
          selectedPks.push(parseInt(selected_pk));
        }
      }
    });

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      setError("No sale selected, please choose sale to continue!");
      setOpenError(true);
      return;
    } else {
      setOpenDelete(true);
    }

    // Perform the delete action
  };

  const columns = [
    {
      field: "id",
      headerName: "IDs",
      width: 10,
    },
    {
      field: "sale_type",
      headerName: "Sale Type",
      width: 120,
      renderCell: (params) =>
        params.row.type_of_sale == 2 ? "Party Sale" : "Cash Sale",
    },

    {
      field: "billing_customer",
      renderCell: (params) =>
        params.row.billing_customer?.customer_name || "N/A",
      headerName: "Customer",
      width: 280,
    },

    { field: "sale_date", headerName: "Sale Date", width: 180 },

    { field: "total_products", headerName: "Total Products", width: 120 },

    {
      field: "actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleViewClick(params.row.id)}
          >
            Dispatch
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <Delete
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_sales")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Dispatch</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Pending Dispatch
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              ></div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              checkboxSelection
              disableRowSelectionOnClick
              rows={purchases}
              columns={columns}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelection) =>
                setSelectionModel(newSelection)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllDispatch;
