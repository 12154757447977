import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import { apiKey, getNextSKU, getTypeBrand, add_product } from "../../Api.js";
import { Form } from "semantic-ui-react";

import { MdDoubleArrow } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";

import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SendIcon from "@mui/icons-material/Send";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import AddBrand from "./AddBrand";
import AddType from "./AddType";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: "5px",
};

const AddProductModal = ({ open, handleClose }) => {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();
  const [getsku, setGetSKU] = useState("");
  const [brands, setBrands] = useState([]);
  const [types, setTypes] = useState([]);

  /////////Set Input Values//////////

  useEffect(() => {
    fetchSKU();
    fetchBrandType();
  }, []);

  const fetchSKU = () => {
    axios
      .get(getNextSKU, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        setGetSKU(response.data.product_sku);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [product_name, setProduct_name] = useState("");
  const [oem_number, setOEM] = useState("");
  const [part_number, setPartNo] = useState("");
  const [product_brand, setProductBrand] = useState("");
  const [product_type, setProductType] = useState("");
  const [product_desc, setDesc] = useState("");
  const [mrp, setMrp] = useState("");
  const [in_stock, setInstock] = useState("");
  const [stock_alert, setStockAlert] = useState(1);
  const [length, setLength] = useState("");
  const [breadth, setBreadth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [hsn_code, setHSN] = useState("");
  const [gst_percent, setGST] = useState("");

  const fetchBrandType = () => {
    axios
      .get(getTypeBrand, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.product_types);
        setTypes(response.data.product_types);
        setBrands(response.data.product_brands);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
        setOpenError(true);
      });
  };

  const handleSubmit = () => {
    let formattedData = {
      product_sku: getsku,
      product_name: product_name,
      oem_number: oem_number || "",
      part_number: part_number,
      product_brand: product_brand,
      product_type: product_type,
      description: product_desc,
      length: length,
      breadth: breadth,
      height: height,
      weight: weight,
      mrp: parseFloat(mrp) || 0,
      in_stock: 0,
      stock_alert: parseInt(stock_alert),
      hsn_code: hsn_code,
      gst_percent: gst_percent,
    };

    axios
      .post(add_product, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.message);
        if (response.data.status === 1) {
          setSuccess(
            `A new product with SKU: ${getsku} has been created successfully!`
          );
          setOpenSuccess(true);
          // Close the modal
          handleClose();

          setProduct_name("");
          setProductBrand("");
          setProductType("");
          setPartNo("");
          setOEM("");
          setDesc("");
          setHSN("");
          setGST("");
          setLength("");
          setBreadth("");
          setHeight("");
          setWeight("");
          setMrp("");
          fetchSKU();
        } else {
          setError(response.data.message);
          setOpenError(true);
        }

        // fetchSKU(); // Refresh data
      })
      .catch((error) => {
        if (error.response.data.status === 3) {
          setError(
            "Product SKU Already Exists! You are assigned with a new SKU."
          );
          setOpenError(true);
          fetchSKU();
        } else {
          setError(error.response.data.message);
          setOpenError(true);
        }
      });
  };

  const [openaddbrand, setOpenAddBrand] = useState(false);
  const [openaddtype, setOpenAddType] = useState(false);

  const handleAddBrand = () => {
    setOpenAddBrand(true);
  };

  const handleAddType = () => {
    setOpenAddType(true);
  };

  return (
    <>
      <AddBrand
        setOpenAddBrand={setOpenAddBrand}
        openaddbrand={openaddbrand}
        fetchBrandType={fetchBrandType}
      />
      <AddType
        setOpenAddType={setOpenAddType}
        openaddtype={openaddtype}
        fetchBrandType={fetchBrandType}
      />

      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="row mb-2">
            <div className="col-md-6">
              <Typography variant="h6" component="h2" gutterBottom>
                Add New Product
              </Typography>
            </div>
            <div className="col-md-6">
              <Button
                variant="outlined"
                color="error"
                style={{ marginLeft: "2px", float: "right" }}
                onClick={() => {
                  handleClose();
                }}
              >
                Close
              </Button>
            </div>
          </div>
          <Form className="forms-sample" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-8">
                <div className="card">
                  <div className="card-body p-0">
                    <h5>
                      <MdDoubleArrow style={{ marginTop: "-4px" }} /> Product
                      Details
                    </h5>

                    <div className="row my-4">
                      <div className="col-lg-4 mb-2">
                        <TextField
                          id="outlined-basic"
                          label="Product SKU (Not Editable)"
                          value={getsku}
                          size="small"
                          style={{ width: "100%" }}
                          variant="outlined"
                        />
                      </div>
                      <div className="col-lg-8 mb-2">
                        <TextField
                          autoFocus
                          id="outlined-multiline-flexible"
                          label="Product Name"
                          value={product_name}
                          size="small"
                          required
                          style={{ width: "100%" }}
                          onChange={(e) => setProduct_name(e.target.value)}
                        />
                      </div>

                      <div
                        className="col-lg-6 my-3"
                        style={{ display: "flex" }}
                      >
                        <TextField
                          id="outlined-select-type"
                          select
                          required
                          size="small"
                          style={{ width: "100%" }}
                          label="Product Type"
                          value={product_type}
                          onChange={(e) => setProductType(e.target.value)}
                        >
                          <MenuItem value="">--Select--</MenuItem>
                          {types.map((type) => (
                            <MenuItem key={type.id} value={type.product_type}>
                              {type.product_type}
                            </MenuItem>
                          ))}
                        </TextField>

                        <Button
                          variant="outlined"
                          style={{ marginLeft: "3px" }}
                          onClick={handleAddType}
                        >
                          Add
                        </Button>
                      </div>
                      <div
                        className="col-lg-6 my-3"
                        style={{ display: "flex" }}
                      >
                        <TextField
                          id="outlined-select-brand"
                          select
                          required
                          style={{ width: "100%" }}
                          size="small"
                          label="Product Brand"
                          value={product_brand}
                          onChange={(e) => setProductBrand(e.target.value)}
                        >
                          <MenuItem value="">--Select--</MenuItem>
                          {brands.map((brand) => (
                            <MenuItem
                              key={brand.id}
                              value={brand.product_brand}
                            >
                              {brand.product_brand}
                            </MenuItem>
                          ))}
                        </TextField>

                        <Button
                          variant="outlined"
                          style={{ marginLeft: "3px" }}
                          onClick={handleAddBrand}
                        >
                          Add
                        </Button>
                      </div>

                      <div className="col-lg-6">
                        <TextField
                          id="outlined-multiline-partnumber"
                          label="Part Number"
                          multiline
                          required
                          style={{ width: "100%" }}
                          size="small"
                          value={part_number}
                          onChange={(e) => setPartNo(e.target.value)}
                          rows={4}
                        />
                      </div>

                      <div className="col-lg-6">
                        <TextField
                          id="outlined-multiline-oemnumber"
                          label="OEM Number"
                          multiline
                          style={{ width: "100%" }}
                          size="small"
                          value={oem_number}
                          onChange={(e) => setOEM(e.target.value)}
                          rows={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-2">
                  <div className="card-body p-0">
                    <h5>
                      <MdDoubleArrow style={{ marginTop: "-4px" }} /> Product
                      Description
                    </h5>

                    <div className="row my-4">
                      <div className="col-lg-12">
                        <TextField
                          id="outlined-multiline-flexible"
                          label="Description"
                          multiline
                          style={{ width: "100%" }}
                          size="small"
                          value={product_desc}
                          onChange={(e) => setDesc(e.target.value)}
                          rows={4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 my-2">
                <div className="card">
                  <div className="card-body p-0">
                    <h5>
                      <MdDoubleArrow style={{ marginTop: "-4px" }} /> Inventory
                      & Price
                    </h5>

                    <div className="row">
                      <div className="col-lg-6 my-2">
                        <TextField
                          id="outlined-multiline-mrp"
                          label="MRP"
                          value={mrp}
                          size="small"
                          style={{ width: "100%" }}
                          onChange={(e) => setMrp(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          id="outlined-multiline-stock-alert"
                          label="Stock Alert"
                          value={stock_alert}
                          size="small"
                          style={{ width: "100%" }}
                          onChange={(e) => setStockAlert(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          id="outlined-multiline-hsn"
                          label="HSN"
                          value={hsn_code}
                          size="small"
                          style={{ width: "100%" }}
                          onChange={(e) => setHSN(e.target.value)}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          label="GST"
                          id="outlined-start-gst"
                          style={{ width: "100%" }}
                          size="small"
                          value={gst_percent}
                          onChange={(e) => setGST(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-2 mt-3">
                  <div className="card-body p-0">
                    <h5>
                      <MdDoubleArrow style={{ marginTop: "-4px" }} /> Dimensions
                      & Weight
                    </h5>

                    <div className="row my-4">
                      <div className="col-lg-6 my-2">
                        <TextField
                          label="Length"
                          id="outlined-start-length"
                          style={{ width: "100%" }}
                          size="small"
                          value={length}
                          onChange={(e) => setLength(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                cm
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          label="Breadth"
                          id="outlined-start-breadth"
                          style={{ width: "100%" }}
                          size="small"
                          value={breadth}
                          onChange={(e) => setBreadth(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                cm
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          label="Height"
                          id="outlined-start-height"
                          style={{ width: "100%" }}
                          size="small"
                          value={height}
                          onChange={(e) => setHeight(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                cm
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div className="col-lg-6 my-2">
                        <TextField
                          label="Weight"
                          id="outlined-start-weight"
                          style={{ width: "100%" }}
                          size="small"
                          value={weight}
                          onChange={(e) => setWeight(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                kg
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <br />
                      <hr />
                      <div className="container text-center my-2">
                        {!product_desc &&
                        !product_brand &&
                        !product_type &&
                        !in_stock &&
                        !mrp ? (
                          <>
                            <Button
                              variant="outlined"
                              endIcon={<SendIcon />}
                              disabled
                              style={{ cursor: "pointer" }}
                            >
                              Confirm Product
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="outlined"
                              endIcon={<SendIcon />}
                              type="submit"
                            >
                              Confirm Product
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Box>
      </Modal>
    </>
  );
};

export default AddProductModal;
