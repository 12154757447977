import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Card,
  CardContent,
  Grid,
  Checkbox,
  DialogContent,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";
import { FaPhone } from "react-icons/fa6";
import {
  add_customer_address,
  apiKey,
  delete_customer_address,
  fetch_customer_address,
  update_customer_primary_address,
} from "../../../Api";
import axios from "axios";
import { IoClose } from "react-icons/io5";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { FaRegSquarePlus } from "react-icons/fa6";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EditCustomerAddress from "./EditCustomerAddress";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import { IoCopy } from "react-icons/io5";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const editstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "800px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const addAddressStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ViewCustomer({
  openViewCustomer,
  handleCloseCustomer,
  selectedCustomer,
  fetchCustomers,
}) {
  const [fetchedCustomerAddresses, setFetchedCustomerAddresses] = useState();
  const [addresses, setAddresses] = useState([]);
  const [editingAddressIndex, setEditingAddressIndex] = useState(null);
  const [
    selectedCustomerPrimaryAddressId,
    setSelectedCustomerPrimaryAddressId,
  ] = useState(selectedCustomer);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [newAddress, setNewAddress] = useState({
    contact_name: "",
    contact_no: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
  });

  const [openAddAddressModal, setOpenAddAddressModal] = useState(false);

  useEffect(() => {
    // Only fetch addresses if selectedCustomer is available
    if (selectedCustomer && selectedCustomer.id) {
      handleFetchAddressOfCustomer(selectedCustomer.id);
    }
  }, [selectedCustomer]);

  const handleAddAddress = () => {
    if (
      !newAddress.contact_name ||
      !newAddress.contact_no ||
      !newAddress.address
    ) {
      setError(
        "Please fill in all required fields: Contact Name, Contact Number, and Address."
      );
      setOpenError(true);
      return;
    }

    setAddresses([...addresses, newAddress]);
    setNewAddress({
      contact_name: "",
      contact_no: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    });
    // setOpenAddAddressModal(false); // Close the modal after adding
  };

  const handleDeleteSavedAddress = (index) => {
    setAddresses(addresses.filter((_, i) => i !== index));
  };

  const handleDeleteAddress = (id) => {
    axios
      .delete(delete_customer_address, {
        headers: {
          "API-key": apiKey,
        },
        data: {
          address_id: id,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          toast.success(response.data.message);
          handleFetchAddressOfCustomer(selectedCustomer.id);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleSetPrimary = (index) => {
    const updatedAddresses = addresses.map((address, i) => ({
      ...address,
      primary: i === index,
    }));
    setAddresses(updatedAddresses);
  };

  const handleFetchAddressOfCustomer = (id) => {
    axios
      .get(fetch_customer_address, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          customer_pk: id,
        },
      })
      .then((response) => {
        // Get the addresses from the response
        const fetchedAddresses = response.data.address || [];

        // Determine if there is a primary address
        const hasPrimaryAddress = fetchedAddresses.some(
          (address) => address.is_primary === 1
        );

        // Update the addresses state
        setFetchedCustomerAddresses(fetchedAddresses);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSaveAddAddress = () => {
    const updatedAddressesData = {
      customer_pk: selectedCustomer.id,
      shipping_detail: addresses,
    };

    axios
      .post(add_customer_address, updatedAddressesData, {
        headers: {
          "Content-Type": "application/json",
          "API-key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 0) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          setOpenAddAddressModal(false); // Close the modal after adding
          setAddresses([]);
          handleFetchAddressOfCustomer(selectedCustomer.id);
        }
      })
      .catch((error) => {
        console.error("Error updating customer:", error);
      });
  };

  const [selectedCustomerAddress, setSelectedCustomerAddress] = useState(null);
  const [openEditAddressModal, setOpenEditAddressModal] = useState(false);

  const handleEditAddress = (address) => {
    setSelectedCustomerAddress(address);
    setOpenEditAddressModal(true);
  };

  const handleCopyAddress = (address) => {
    // Create a formatted address string
    const formattedAddress = `
      Contact Name: ${address.contact_name}
      Contact Number: ${address.contact_no}
      Address: ${address.address}
      City: ${address.city}
      State: ${address.state}
      Country: ${address.country}
      Pincode: ${address.pincode}
    `;

    // Use navigator.clipboard.writeText to copy the formatted address to the clipboard
    navigator.clipboard
      .writeText(formattedAddress)
      .then(() => {
        // console.log('Address copied to clipboard successfully!');
        toast.success("Address copied to clipboard successfully!");
      })
      .catch((err) => {
        toast.error("Failed to copy the address: ", err);
      });
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [addressToUpdate, setAddressToUpdate] = useState(null);

  const handleOpenDialog = (id) => {
    setAddressToUpdate(id);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setAddressToUpdate(null);
  };

  const handleSetPrimaryAddress = (id) => {
    const sendData = {
      address_pk: id,
      customer_pk: selectedCustomer.id,
    };

    // Make an API call to update the primary address on the server
    axios
      .post(update_customer_primary_address, sendData, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          toast.success(response.data.message);

          fetchCustomers();
          handleFetchAddressOfCustomer(selectedCustomer.id);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message || "An error occurred");
      })
      .finally(() => {
        handleCloseDialog();
      });
  };

  // This allows the useEffect to still run.
  if (!selectedCustomer) return null;

  return (
    <>
      <ToastContainer />

      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      {/* Edit Address Modal */}
      <Modal
        open={openEditAddressModal}
        onClose={() => setOpenEditAddressModal(false)}
      >
        <Box sx={editstyle}>
          <EditCustomerAddress
            address={selectedCustomerAddress}
            apiKey={apiKey}
            closeModal={() => setOpenEditAddressModal(false)}
            fetchCustomerAddresses={() =>
              handleFetchAddressOfCustomer(selectedCustomer.id)
            }
          />
        </Box>
      </Modal>

      <Modal
        open={openViewCustomer}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseCustomer();
          }
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Customers</Typography>
            {/* <Typography color="inherit">Customer Details</Typography> */}
            <Typography sx={{ color: "text.primary" }}>
              Customer Details
            </Typography>
          </Breadcrumbs>

          <IoClose className="closeModalBtn" onClick={handleCloseCustomer} />
          <div className="row my-2">
            <div className="col-md-4">
              <div className="customer-card">
                {/* <span className="pro">PRO</span> */}
                <img
                  className="round"
                  src="https://cdn-icons-png.flaticon.com/512/1053/1053244.png"
                  alt="user"
                />
                <h3>{selectedCustomer.customer_name}</h3>
                <h6>
                  <FaPhone /> {selectedCustomer.phone}
                </h6>

                <div className="customer-card-buttons">
                  <button className="primary">Report</button>
                  <button className="primary ghost">Details</button>
                </div>
                <div className="skills">
                  <h6>Details</h6>
                  <ul>
                    <li>
                      Email: <b>{selectedCustomer.email}</b>
                    </li>
                    <li>
                      Alt. No.: <b>{selectedCustomer.alternativ_phone}</b>
                    </li>
                    <li>
                      GST: <b>{selectedCustomer.gst_number}</b>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div style={{ display: "flex" }}>
                <h6>Addresses</h6>
                <FaRegSquarePlus
                  onClick={() => setOpenAddAddressModal(true)}
                  style={{
                    marginLeft: "10px",
                    color: "red",
                    cursor: "pointer",
                    fontSize: "18px",
                  }}
                />
                {/* <Button
              style={{ float: "right" }}
              onClick={() => setOpenAddAddressModal(true)}
              variant="outlined"
              size="small"
            >
              Add New Address
            </Button> */}
              </div>

              <div
                style={{
                  height: "51.2vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                  paddingRight: "3px",
                }}
              >
                {fetchedCustomerAddresses &&
                  fetchedCustomerAddresses.map((address, index) => (
                    <div
                      className="card addresses_card my-1 p-2"
                      style={{
                        backgroundColor:
                          address.is_primary === 1
                            ? "#e0f1ffb0"
                            : "transparent",
                        border:
                          address.is_primary === 1
                            ? "1px solid #b0dbff"
                            : "1px solid #dbdbdb",
                      }}
                      key={index}
                    >
                      <div className="row">
                        <div className="col-1">
                          <Checkbox
                            checked={address.is_primary === 1}
                            onChange={() => handleOpenDialog(address.id)}
                            color="primary"
                          />
                        </div>
                        <div className="col-10">
                          <div className="row" style={{ marginTop: "5px" }}>
                            <div className="col-6">
                              Contact Name: <b>{address.contact_name}</b>
                            </div>
                            <div className="col-5">
                              Contact: <b>{address.contact_no}</b>
                            </div>
                            <div className="col-lg-12 my-2">
                              <p>{`${address.address}, ${address.city}, ${address.state}, ${address.pincode}, ${address.country}`}</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-1">
                          <IconButton>
                            <IoCopy
                              style={{ fontSize: "1rem" }}
                              onClick={() => {
                                handleCopyAddress(address);
                              }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              handleEditAddress(address);
                            }}
                          >
                            <Edit style={{ fontSize: "1rem" }} />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteAddress(address.id)}
                          >
                            <Delete
                              style={{ fontSize: "1.15rem", color: "red" }}
                            />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Modal for Adding New Address */}
      <Modal
        open={openAddAddressModal}
        onClose={() => setOpenAddAddressModal(false)}
        aria-labelledby="add-address-title"
        aria-describedby="add-address-description"
      >
        <Box sx={addAddressStyle}>
          <div className="row">
            <div className="col-md-6">
              <Typography id="add-address-title" variant="h6" component="h2">
                Add New Address
              </Typography>
            </div>
            <div className="col-md-6">
              <Button
                style={{ marginLeft: "5px", float: "right" }}
                className="mb-3"
                onClick={() => setOpenAddAddressModal(false)}
                color="error"
                variant="outlined"
              >
                Close
              </Button>
              <Button
                style={{ float: "right" }}
                className="mb-3"
                color="primary"
                variant="outlined"
                onClick={handleSaveAddAddress}
              >
                SAVE
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Contact Person"
                    value={newAddress.contact_name}
                    onChange={(e) =>
                      setNewAddress({
                        ...newAddress,
                        contact_name: e.target.value,
                      })
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    type="tel"
                    label="Contact Phone"
                    value={newAddress.contact_no}
                    onChange={(e) =>
                      setNewAddress({
                        ...newAddress,
                        contact_no: e.target.value,
                      })
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Address"
                    value={newAddress.address}
                    onChange={(e) =>
                      setNewAddress({
                        ...newAddress,
                        address: e.target.value,
                      })
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="City"
                    value={newAddress.city}
                    onChange={(e) =>
                      setNewAddress({
                        ...newAddress,
                        city: e.target.value,
                      })
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="State"
                    value={newAddress.state}
                    onChange={(e) =>
                      setNewAddress({
                        ...newAddress,
                        state: e.target.value,
                      })
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Country"
                    value={newAddress.country}
                    onChange={(e) =>
                      setNewAddress({
                        ...newAddress,
                        country: e.target.value,
                      })
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Pincode"
                    value={newAddress.pincode}
                    onChange={(e) =>
                      setNewAddress({
                        ...newAddress,
                        pincode: e.target.value,
                      })
                    }
                    fullWidth
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={handleAddAddress}
                    color="primary"
                    variant="contained"
                    style={{ marginTop: "10px" }}
                  >
                    Add Address
                  </Button>
                </Grid>
              </Grid>
            </div>
            <div
              className="col-md-6"
              style={{
                backgroundColor: "rgb(245, 245, 245)",
                maxHeight: "280px",
                overflow: "auto",
              }}
            >
              <h5 className="mb-0">Saved Addresses</h5>
              <div className="row p-1 g-3">
                {addresses.map((address, index) => (
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{
                      backgroundColor: "#ffffff", // Neutral background
                      margin: 1,
                      padding: 1,
                      border: "1px solid #ddd", // Light border for a clean look
                    }}
                  >
                    <CardContent
                      style={{
                        padding: "0.2rem",
                        width: "100%",
                        margin: "0rem",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          fontSize: "14px",
                        }}
                      >
                        <div>
                          <strong>Contact Person:</strong>{" "}
                          {address.contact_name}
                        </div>
                        <div>
                          <strong>Contact Phone:</strong> {address.contact_no}
                        </div>
                        <div>
                          <strong>Address:</strong>{" "}
                          {`${address.address}, ${address.city}, ${address.state}, ${address.pincode}`}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div style={{ marginTop: "8px" }}>
                          <label>
                            <input
                              type="checkbox"
                              checked={address.primary}
                              onChange={() => handleSetPrimary(index)} // Toggle primary address
                            />
                            Primary Address
                          </label>
                        </div>
                        <Button
                          onClick={() => handleDeleteSavedAddress(index)}
                          color="error" // Red color for delete action
                          variant="outlined"
                          size="small" // Smaller button
                        >
                          DELETE
                        </Button>
                      </div>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Confirmation Dialog */}

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle
          style={{ color: "#ed4928", fontSize: "1.4rem", fontWeight: "bold" }}
        >
          Change Primary Address
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to change the primary address?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleSetPrimaryAddress(addressToUpdate)}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ViewCustomer;
