import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { fetch_purchase, apiKey } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { format } from "date-fns";
import Delete from "../../Messages/DeletePurchase";
import CustomToolbar from "../../Components/CustomToolbar";
import Error from "../../Messages/Error";
import Chip from "@mui/material/Chip";
import { IoMdArrowRoundBack } from "react-icons/io";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

function Purchases() {
  const [purchases, setPurchases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, filterModel]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,
      selectedBrands: [],
      selectedTypes: [],
      selectedStocks: [],
      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_purchase, data, { headers })
      .then((response) => {
        // console.log(response);
        setPurchases(response.data.purchases);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handleEditClick = (id) => {
    navigate(`/update-purchase/${id}`);
  };

  const handleBarcodeClick = (id) => {
    navigate(`/barcode-purchase/${id}`);
  };

  const handleAllocateClick = (id) => {
    navigate(`/allocate-purchase-goods/${id}`);
  };

  const [pks, setPks] = useState([]);
  const handleDeleteClick = () => {
    const selectedPks = [];
    const ourDiv = document.querySelector(".all-purchases");
    const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
    let loopCount = 0;
    Array.from(allCheckBoxes).forEach((single_input) => {
      if (single_input.checked) {
        loopCount++;
        if (loopCount > 1) {
          const cur_row = single_input.closest(".MuiDataGrid-row");
          const third_child = cur_row.children[2];
          const selected_pk = third_child.innerHTML;
          selectedPks.push(selected_pk);
        }
      }
    });

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      setError("No products selected, please choose items to continue!");
      setOpenError(true);
      return;
    } else {
      setOpenDelete(true);
    }
  };

  const columns = [
    {
      field: "id",
      headerName: "IDs",
      width: 10,
    },

    {
      field: "reference_number",
      renderCell: (params) => params.row.reference_number || "N/A",
      headerName: "Reference No",
      width: 200,
    },
    {
      field: "vendor_ref",
      renderCell: (params) => params.row.vendor_ref?.name || "N/A",
      headerName: "Vendor",
      width: 400,
    },
    {
      field: "purchase_date",
      headerName: "Purchase Date",
      width: 200,
      renderCell: (params) => {
        const date = new Date(params.value);
        if (isNaN(date.getTime())) {
          return "";
        }
        return format(date, "dd MMMM yyyy");
      },
    },

    {
      field: "recive_status",
      headerName: "Receive Status",
      width: 230,
      renderCell: (params) => {
        const receive_status = params.row.receive_status;
        let chipColor = "error";
        let chipLabel = "Not Received";

        if (receive_status === 1) {
          chipColor = "success";
          chipLabel = "Received";
        }

        return (
          <Chip
            label={chipLabel}
            size="small"
            color={chipColor}
            variant="outlined"
          />
        );
      },
    },
    { field: "total_products", headerName: "Total Products", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      renderCell: (params) => (
        <>
          {params.row.receive_status !== 1 ? (
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => handleEditClick(params.row.id)}
            >
              Edit
            </Button>
          ) : null}

          {params.row.receive_status !== 1 ? null : (
            <Button
              variant="outlined"
              size="small"
              color="success"
              onClick={() => handleBarcodeClick(params.row.id)}
            >
              Print
            </Button>
          )}

          {params.row.receive_status !== 1 ? (
            <Button
              variant="outlined"
              size="small"
              style={{ marginLeft: "5px" }}
              color="primary"
              onClick={() => handleAllocateClick(params.row.id)}
            >
              Receive
            </Button>
          ) : (
            <Button
              variant="outlined"
              style={{ marginLeft: "5px" }}
              size="small"
              color="info"
              onClick={() => handleAllocateClick(params.row.id)}
            >
              History
            </Button>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <Delete
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Purchases</Typography>

              <Typography sx={{ color: "text.primary" }}>
                Purchase Orders
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-purchase")}
                >
                  Add Purchase
                </Button>

                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleDeleteClick}
                >
                  Delete Purchase
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
            className="all-purchases"
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              checkboxSelection
              disableRowSelectionOnClick
              rows={purchases}
              columns={columns}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelection) =>
                setSelectionModel(newSelection)
              }
              isRowSelectable={(params) => params.row.receive_status === 0}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Purchases;
