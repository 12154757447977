import React, { useState, useRef } from "react";
import axios from "axios";
import { apiKey, add_customer } from "../../../Api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";

import Breadcrumbs from "@mui/material/Breadcrumbs";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1400,
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function CreateCustomer({ opencustomer, handleClosecustomer, fetchCustomers }) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [customer_name, setCustomer_name] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [contact_name, setContact_name] = useState("");
  const [contact_no, setcontact_no] = useState("");
  const [gst, setGST] = useState("");
  const [city, setCity] = useState("");
  const [alt_contact, setAlt_contact] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [country_code, setCountryCode] = useState("");

  const [addresses, setAddresses] = useState([]);

  const modalRef = useRef(null);

  const handleSaveAddress = () => {
    // Check if all mandatory fields are filled
    if (
      contact_name.length < 2 ||
      address.length < 2 ||
      contact_no.length < 8
    ) {
      setOpenError(true);
      setError(
        "All fields are required: Contact Name, Contact Number , Address"
      );

      return;
    }

    const newAddress = {
      contact_name,
      contact_no,
      address,
      pincode,
      state,
      city,
      country: country_code,
      primary: addresses.length === 0, // New address is primary by default
    };

    setAddresses([...addresses, newAddress]);

    // Clear the fields after saving the address
    setContact_name("");
    setcontact_no("");
    setAddress("");
    setPincode("");
    setCity("");
    setState("");
    setCountryCode("");
  };

  const handleSetPrimary = (index) => {
    setAddresses(
      addresses.map((address, i) => ({
        ...address,
        primary: i === index ? !address.primary : false,
      }))
    );
  };

  const handleDeleteAddress = (index) => {
    setAddresses(addresses.filter((_, i) => i !== index));
  };

  const handleAddCustomer = () => {
    // Check if there is at least one primary address
    const hasPrimaryAddress = addresses.some((address) => address.primary);

    // if (!hasPrimaryAddress) {
    //   setOpenError(true);
    //   setError("You must select a primary address before adding the customer.");
    //   return;
    // }
    let customerData = {
      customer_name: customer_name,
      gst_number: gst,
      email: email,
      phone: contact,
      alternativ_phone: alt_contact,
    };

    const data_send = {
      customer_data: customerData,
      shipping_detail: addresses,
    };

    axios
      .post(add_customer, data_send, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(
            `A new customer ${customer_name} has been created successfully!`
          );

          setCustomer_name("");
          setEmail("");
          setContact("");
          setCity("");
          setAlt_contact("");
          setPincode("");
          setState("");
          setGST("");
          setAddress("");
          setCountryCode("");
          setAddresses([]);

          handleClosecustomer(true);

          fetchCustomers();
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setOpenError(true);
      });
  };

  const handleClose = (e) => {
    // Close modal only if clicking on the overlay
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleClosecustomer();
    }
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={opencustomer}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClosecustomer();
          }
        }}
        // onClose={handleClosecustomer}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Customers</Typography>
            <Typography sx={{ color: "text.primary" }}>Add Customer</Typography>
          </Breadcrumbs>

          <div className="row">
            <div className="col-md-6 my-2">
              <TextField
                value={customer_name}
                label="Customer Name"
                size="small"
                required
                fullWidth
                onChange={(e) => {
                  setCustomer_name(e.target.value);
                  setContact_name(e.target.value); // Set contact_name as well
                }}
              />
            </div>

            <div className="col-md-6 my-2">
              <TextField
                type="email"
                value={email}
                label="Email"
                size="small"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-md-6 my-1">
              <TextField
                value={contact}
                label="Contact"
                size="small"
                fullWidth
                onChange={(e) => setContact(e.target.value)}
              />
            </div>

            <div className="col-md-6 my-1">
              <TextField
                value={alt_contact}
                label="Alt Contact No."
                size="small"
                fullWidth
                onChange={(e) => setAlt_contact(e.target.value)}
              />
            </div>
            <div className="col-md-12 my-1">
              <TextField
                value={gst}
                label="GST"
                size="small"
                fullWidth
                onChange={(e) => setGST(e.target.value)}
              />
            </div>
          </div>
          <h6 style={{ marginTop: "5px" }}>Shipping Details</h6>
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-md-6 my-1">
                  <TextField
                    value={contact_name}
                    label="Contact name"
                    size="small"
                    required
                    fullWidth
                    onChange={(e) => setContact_name(e.target.value)}
                  />
                </div>
                <div className="col-md-6 my-1">
                  <TextField
                    value={contact_no}
                    label="Contact"
                    size="small"
                    fullWidth
                    onChange={(e) => setcontact_no(e.target.value)}
                  />
                </div>
                <div className="col-md-12 my-1">
                  <TextField
                    multiline
                    rows={3}
                    value={address}
                    label="Address"
                    size="small"
                    fullWidth
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div className="col-md-6 my-1">
                  <TextField
                    value={pincode}
                    label="Pincode"
                    size="small"
                    fullWidth
                    onChange={(e) => setPincode(e.target.value)}
                  />
                </div>
                <div className="col-md-6 my-1">
                  <TextField
                    value={city}
                    label="City"
                    size="small"
                    fullWidth
                    onChange={(e) => setCity(e.target.value)}
                  />
                </div>

                <div className="col-md-6 my-1">
                  <TextField
                    value={state}
                    label="State"
                    size="small"
                    fullWidth
                    onChange={(e) => setState(e.target.value)}
                  />
                </div>

                <div className="col-md-6 my-1">
                  <TextField
                    value={country_code}
                    label="Country"
                    size="small"
                    fullWidth
                    onChange={(e) => setCountryCode(e.target.value)}
                  />
                </div>

                <div className="col-md-12 my-1">
                  <Button
                    style={{ marginTop: "8px" }}
                    onClick={handleSaveAddress}
                    variant="outlined"
                  >
                    Save Address
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <h6 style={{ marginTop: "5px" }}>Saved Addresses</h6>

              <div
                className="card p-2"
                style={{
                  backgroundColor: "#f5f5f5",
                  fontSize: "12px",
                  overflowY: "scroll",
                  height: "24vh",
                  color: "black",
                }}
              >
                {addresses.length > 0 ? (
                  <ul>
                    {addresses.map((address, index) => (
                      <li key={index} style={{ marginBottom: "10px" }}>
                        <strong>Contact Person:</strong> {address.contact_name}
                        <br />
                        <strong>Contact Phone:</strong> {address.contact_no}
                        <br />
                        <strong>Address:</strong> {address.address},{" "}
                        {address.state}, {address.pincode},{" "}
                        {address.country_code}
                        <br />
                        <input
                          type="checkbox"
                          checked={address.primary}
                          onChange={() => handleSetPrimary(index)}
                        />
                        <label style={{ marginLeft: "5px" }}>
                          Primary Address
                        </label>
                        <Button
                          variant="outlined"
                          color="error"
                          size="small"
                          style={{ marginLeft: "10px" }}
                          onClick={() => handleDeleteAddress(index)}
                        >
                          Delete
                        </Button>
                        <hr />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography>No addresses saved yet.</Typography>
                )}
              </div>
            </div>
          </div>

          <div style={{ float: "right" }}>
            <Button
              style={{ marginTop: "8px", marginRight: "5px" }}
              onClick={handleClosecustomer}
              color="error"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              style={{ marginTop: "8px" }}
              onClick={handleAddCustomer}
              variant="contained"
            >
              Create Customer
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default CreateCustomer;
