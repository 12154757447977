import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import {
  apiKey,
  fetch_warehouse,
  fetch_all_product_transfer,
  TransferInventory,
} from "../../../Api";
import { debounce } from "lodash";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AssignWare from "./Inventory_ProductsModal";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import Quagga from "quagga";
import Webcam from "react-webcam";
import soundEffect from "../../../Assets/sounds/beep.mp3";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { toast, ToastContainer } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useNavigate, useNavigation } from "react-router-dom";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function AddStockTransfer() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [warehouses, seswarehouses] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const navigate = useNavigate();
  const [warehouse, seswarehouse] = useState("");
  const [swarehouse, setSWarehouse] = useState("");
  const webcamRef = useRef(null);
  const [scanData, setScanData] = useState("");
  const [scanInputData, setScanInputData] = useState("");
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);
  const [curr_series, setCurr_series] = useState("");
  const [openRemoveP, setOpenRemoveP] = useState(false);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  useEffect(() => {
    fetchWarehouse();
  }, []);

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        seswarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
      });
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
  }, []);

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const handleScanChange = (e) => {
    const scanquery = e.target.value;
    if (scanquery.length <= 5) {
      return;
    }
    if (scanquery.length > 5) {
      if (isValidProductSku(scanquery)) {
        let seriesExists = false;
        const [productSku, series] = scanquery.split("_");

        setScanInputData(scanquery);
        for (const product of selectedProducts) {
          if (
            product.sku === productSku &&
            product.additional_info?.includes(parseInt(series, 10))
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(productSku);
          setAdditionalInfoToRemove(parseInt(series, 10));
          setOpenRemoveP(true);
          return;
        } else {
          fetchProductData(scanquery);
        }

        // Call handleSeriesClick if newValue contains "_"
        if (scanquery.includes("_")) {
          handleSeriesClick(productSku, parseInt(series, 10));
        }
      } else {
        playSound();
      }
    }
  }; // Adjust the delay as needed

  const handleInputChange = (scanData) => {
    const newValue = scanData;

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    if (newValue.length > 5) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;
        const [productSku, series] = newValue.split("_");

        for (const product of selectedProducts) {
          if (
            product.sku === productSku &&
            product.additional_info?.includes(parseInt(series, 10))
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(productSku);
          setAdditionalInfoToRemove(parseInt(series, 10));
          setOpenRemoveP(true);
          return;
        } else {
          fetchProductData(newValue);
          setIsCameraOpen(false);
          playSound();
        }

        // Call handleSeriesClick if newValue contains "_"
        if (newValue.includes("_")) {
          handleSeriesClick(productSku, parseInt(series, 10));
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      const data = {
        search: sku,
        warehouse_pk: parseInt(swarehouse),
      };
      axios
        .post(fetch_all_product_transfer, data, {
          headers: {
            "API-Key": apiKey,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const productData = response.data.data;

          if (!productData || productData.length === 0) {
            console.log("No product data available");
            return; // Exit early if there's no data
          }

          const serieses = productData[0].serieses || [];
          const curr_series = sku.split("_")[1];

          // Check if curr_series exists in serieses
          const seriesExists = serieses.some(
            (seriesObj) => seriesObj.series == curr_series
          );

          if (seriesExists) {
            addScannedProduct(sku, productData);
          } else {
            toast.error("Product Not Found!");
          }

          // Clear the scan data
          setScanData("");
        })
        .catch((error) => {
          setOpenError(true);
          setError(error.response.data.message);
          setScanData("");
        });
    }, 500),
    [warehouse, swarehouse, selectedProducts]
  );

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const additionalInfo = parseInt(additionalInfoString, 10);

      if (!productData) {
        toast.error("Product not found for the scanned SKU.");
        return;
      }
      let splitSeries = sku.split("_")[1];
      let splittedSeries = parseInt(splitSeries);

      setSelectedProducts((prev) => {
        const existingProduct = prev.find((item) => item.sku === baseSku);

        if (existingProduct) {
          // Check if splittedSeries already exists in selectedSeries
          if (existingProduct.selectedSeries.includes(splittedSeries)) {
            setSkuToRemove(baseSku);
            setAdditionalInfoToRemove(splittedSeries);
            setOpenRemoveP(true);
            return prev.map((item) =>
              item.sku === baseSku
                ? {
                    ...item,
                    quantity: item.quantity + 1, // Increment quantity
                  }
                : item
            );
          } else {
            // If it does not exist, add it to selectedSeries
            return prev.map((item) =>
              item.sku === baseSku
                ? {
                    ...item,
                    selectedSeries: [
                      ...(item.selectedSeries || []),
                      splittedSeries,
                    ],
                  }
                : item
            );
          }
        } else {
          // If the product does not exist, add a new entry
          return [
            ...prev,
            {
              sku: baseSku,
              product_name: productData[0].product_name,
              product_sku: productData[0].product_sku,
              serieses: productData[0].serieses,
              selectedSeries: [splittedSeries],
              in_stock: productData[0].in_stock || 0,
            },
          ];
        }
      });
    }, 300),
    [] // dependencies
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"],
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected]);

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const fetchProducts = useCallback(() => {
    const data = {
      search: searchQuery,
      warehouse_pk: parseInt(swarehouse),
    };
    axios
      .post(fetch_all_product_transfer, data, {
        headers: {
          "API-Key": apiKey,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const fetchedProducts = response.data.data;

        // Extract specific keys from the fetched products
        const selectedKeysProducts = fetchedProducts.map((product) => ({
          product_name: product.product_name,
          product_sku: product.product_sku,
          serieses: product.serieses,
          selectedSeries: [],
          sku: product.product_sku.split("-")[1],
          in_stock: product.in_stock,

          // Add other keys you need here
        }));

        setProducts(selectedKeysProducts);

        setDisplay(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [searchQuery]);

  const handleSearchChange = useCallback(
    debounce((e) => {
      const query = e.target.value;
      setSearchQuery(query);
    }, 500), // Adjust the delay as needed
    []
  );

  useEffect(() => {
    if (searchQuery) {
      fetchProducts();
    }
  }, [searchQuery, fetchProducts]);

  const handleCheckboxChange = (product) => {
    setSelectedProducts((prevSelected) => {
      const productInSelected = prevSelected.find(
        (p) => p.product_sku === product.product_sku
      );

      if (productInSelected) {
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        return [
          ...prevSelected,
          { ...product, selectedSeries: [], smallQty: 0 },
        ];
      }
    });
  };

  const handleSeriesClick = (productSku, series) => {
    setSelectedProducts((prevSelected) => {
      return prevSelected.map((product) => {
        if (product.product_sku === productSku) {
          const isSelected = product.selectedSeries.includes(series);
          return {
            ...product,
            selectedSeries: isSelected
              ? product.selectedSeries.filter((s) => s !== series)
              : [...product.selectedSeries, series],
          };
        }
        return product;
      });
    });
  };

  const handleSmallQtyChange = (index) => (event) => {
    const newQty = parseInt(event.target.value, 10);
    setSelectedProducts((prevSelected) => {
      const newSelected = [...prevSelected];
      newSelected[index] = {
        ...newSelected[index],
        smallQty: newQty,
      };
      return newSelected;
    });
  };

  const isProductSelected = (product) => {
    return selectedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
      setSearchQuery("");
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCloseRemove = () => {
    setOpenRemoveP(false);
  };

  const handleRemoveProduct = () => {
    let ProductSku = "KTT-" + scanInputData.split("_")[0];
    let seriesToRemove = scanInputData.split("_")[1];
    let seriesToRemoveInt = parseInt(seriesToRemove);

    setSelectedProducts((prevSelected) => {
      return prevSelected.map((product) => {
        if (product.product_sku === ProductSku) {
          const updatedSeries = product.selectedSeries.filter(
            (series) => series !== seriesToRemoveInt
          );

          return {
            ...product,
            selectedSeries: updatedSeries,
          };
        }
        return product;
      });
    });

    setOpenRemoveP(false);
  };

  const [p_sku, setSKU] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [inventory, setInven] = useState([]);
  const [unallocated, setUnallocated] = useState(0);

  const [refreshKey, setRefreshKey] = useState(0);

  const handleTransfer = () => {
    let formattedData = {
      target_warehouse: parseInt(warehouse),
      source_warehouse: parseInt(swarehouse),
      user_name: userDetails.name,
      note: notes,
      transfered_data: selectedProducts.map((product) => {
        return {
          product_sku: product.product_sku,
          quantity:
            product.selectedSeries && product.selectedSeries.length > 0
              ? product.selectedSeries.length
              : product.smallQty,
          selected_series: product.selectedSeries,
        };
      }),
    };

    try {
      axios
        .post(TransferInventory, formattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            setOpenSuccess(true);
            setSuccess(`Stock Transferred Successfully!`);
            setProducts([]);
            setDisplay(false);
            setSelectedProducts([]);
            setRefreshKey((prevKey) => prevKey + 1);
            seswarehouse("");
            setNotes("");
          } else {
            setError(response.data.message);
            setOpenError(true);
            setDisplay(false);
          }
        });
    } catch (error) {
      console.log("Error", error);
    }
  };

  const [KhaliKaroKey, setKhaliKaroKey] = useState(0);
  const [inputStatus, setInputStatus] = useState(0);
  const handleScanButtonClick = () => {
    setInputStatus((prevStatus) => (prevStatus === 0 ? 1 : 0));
    setKhaliKaroKey((prevKey) => prevKey + 1);
  };

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <AssignWare
        key={refreshKey}
        openModal={openModal}
        setOpenModal={setOpenModal}
        inventory={inventory}
        p_sku={p_sku}
        setInven={setInven}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/stock_transfer")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>

              <Typography color="inherit">Transfer Stock</Typography>

              <Typography sx={{ color: "text.primary" }}>
                New Transfer Stock
              </Typography>
            </Breadcrumbs>
          </div>

          <div
            className="my-2"
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="row" style={{ padding: "5px 15px" }}>
              <div className="col-lg-2 p-3">
                <FormControl fullWidth size="small" required>
                  <InputLabel id="vendor_name">Source Warehouse</InputLabel>
                  <Select
                    labelId="vendor_name"
                    label="Source Warehouse"
                    required
                    value={swarehouse}
                    onChange={(e) => setSWarehouse(e.target.value)}
                  >
                    <MenuItem value="">--Select--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem
                        key={ware.id}
                        value={ware.id}
                        disabled={ware.id === warehouse}
                      >
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-3 p-3">
                <FormControl fullWidth size="small" required>
                  <InputLabel id="vendor_name">Target Warehouse</InputLabel>
                  <Select
                    labelId="vendor_name"
                    label="Target Warehouse"
                    required
                    value={warehouse}
                    onChange={(e) => seswarehouse(e.target.value)}
                  >
                    <MenuItem value="">--Select--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem
                        key={ware.id}
                        value={ware.id}
                        disabled={ware.id === swarehouse}
                      >
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-3 p-3">
                <TextField
                  size="small"
                  multiline
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={1}
                  style={{ width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>
              <div className="col-lg-3 p-3">
                <div style={{ display: "flex" }}>
                  <TextField
                    key={KhaliKaroKey}
                    fullWidth
                    size="small"
                    disabled={!swarehouse}
                    label={
                      inputStatus === 0 ? "Search Product" : "Scan Product"
                    }
                    variant="outlined"
                    color={inputStatus === 0 ? "primary" : "error"}
                    onInput={
                      inputStatus === 0 ? handleSearchChange : handleScanChange
                    }
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    style={{ marginLeft: "2px" }}
                    onClick={handleScanButtonClick}
                  >
                    Scan
                  </Button>
                </div>
                {displaySearch ? (
                  <>
                    <div
                      id="searchList"
                      ref={searchListRef}
                      style={{
                        border: "1px solid #dedede",
                        position: "absolute",
                        zIndex: "2",
                        right: "3.5vw",
                        marginTop: "3px",
                        maxHeight: "50vh",
                        overflowY: "scroll",

                        backgroundColor: "white",
                        width: "40%",
                      }}
                    >
                      {products.map((product, index) => (
                        <>
                          <div
                            className="card p-3"
                            style={{ display: "flex" }}
                            key={index}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={isProductSelected(product)}
                                  onChange={() => handleCheckboxChange(product)}
                                  name="check"
                                />
                              }
                              label={
                                product.product_sku +
                                " : " +
                                product.product_name
                              }
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </>
                ) : null}
              </div>
              <div className="col-lg-1 p-3">
                <Button
                  disabled={
                    !warehouse ||
                    !swarehouse ||
                    !selectedProducts ||
                    selectedProducts.length === 0
                  }
                  onClick={handleTransfer}
                  variant="contained"
                >
                  Transfer
                </Button>
              </div>
            </div>

            {isCameraOpen ? (
              <Webcam
                audio={false}
                ref={webcamRef}
                width={1920}
                height={220}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",

                  border: "none",
                }}
                style={{
                  marginBottom: "12px",
                  border: "3px dashed green",
                  padding: "0",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : null}
            {isCameraOpen ? (
              <>
                <Button
                  size="small"
                  id="mobile_scan"
                  onClick={handleCloseCamera}
                  color="error"
                  variant="outlined"
                >
                  Close
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  id="mobile_scan"
                  onClick={handleOpenCamera}
                  variant="outlined"
                >
                  SCAN
                </Button>
              </>
            )}

            <div className="p-3">
              <div className="products_table_class">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead className="table-head">
                      <TableRow>
                        <TableCell>SKU</TableCell>
                        <TableCell align="left">Product</TableCell>

                        <TableCell align="left">Quantity</TableCell>

                        <TableCell align="left">Product Series</TableCell>
                        <TableCell align="right">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedProducts && selectedProducts.length > 0 ? (
                        <>
                          {selectedProducts.map((product, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{
                                backgroundColor:
                                  product.product_status === 0
                                    ? "#e0f1ff"
                                    : "transparent",
                              }}
                            >
                              <TableCell
                                component="th"
                                style={{ width: "6.5vw" }}
                                scope="row"
                              >
                                {product.product_sku || ""}
                              </TableCell>
                              <TableCell align="left">
                                {product.product_name || ""}
                              </TableCell>
                              <TableCell align="left">
                                {product.product_status === 0 ? (
                                  <TextField
                                    inputProps={{ min: 0 }}
                                    fullWidth
                                    type="number"
                                    size="small"
                                    label="Quantity"
                                    variant="outlined"
                                    style={{ width: "120px" }}
                                    value={product.smallQty}
                                    onChange={handleSmallQtyChange(index)}
                                  />
                                ) : (
                                  <>
                                    <TextField
                                      inputProps={{ min: 0 }}
                                      fullWidth
                                      type="number"
                                      size="small"
                                      label="Quantity"
                                      variant="outlined"
                                      value={
                                        (product.selectedSeries &&
                                          product.selectedSeries.length) ||
                                        0
                                      }
                                      style={{ width: "120px" }}
                                    />
                                  </>
                                )}
                              </TableCell>

                              <TableCell align="left" style={{ width: "9vw" }}>
                                {product.product_status === 0 ? (
                                  <>--</>
                                ) : (
                                  <>
                                    <div
                                      className="card p-2"
                                      style={{
                                        backgroundColor: "white",
                                        color: "black",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns: "repeat(8, 1fr)",
                                          gap: "3px",
                                          border: "1px solid #e6e6e6",
                                          padding: "5px",
                                          borderRadius: "2px",
                                        }}
                                      >
                                        {product.serieses.map(
                                          (data, seriesIndex) => (
                                            <div
                                              className="ser_number"
                                              key={seriesIndex}
                                              onClick={() =>
                                                handleSeriesClick(
                                                  product.product_sku,
                                                  data.series
                                                )
                                              }
                                              style={{
                                                cursor: "pointer",
                                                backgroundColor:
                                                  product.selectedSeries.includes(
                                                    data.series
                                                  )
                                                    ? "#4c8cc2"
                                                    : "#ededed", // Highlight color
                                                color:
                                                  product.selectedSeries.includes(
                                                    data.series
                                                  )
                                                    ? "white"
                                                    : "black", // Text color
                                              }}
                                            >
                                              {data.series}
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ width: "2vw" }}
                              >
                                <></>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      ) : (
                        <>
                          <TableRow>
                            <TableCell
                              colSpan="9"
                              style={{
                                textAlign: "center",
                                color: "red",
                              }}
                            >
                              No Products Selected
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
      //Remove Product */}

      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default AddStockTransfer;
