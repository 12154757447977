import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_customer,
  fetch_sale_detail,
  validateProductForDispatch,
  DispatchSale,
  fetch_warehouse,
} from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Webcam from "react-webcam";
import Quagga from "quagga";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Switch from "@mui/material/Switch";
import { debounce } from "lodash";
import soundEffect from "../../Assets/sounds/beep.mp3";
import { toast, ToastContainer } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoMdArrowRoundBack } from "react-icons/io";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function DispatchDetails() {
  const webcamRef = useRef(null);
  const inputRef = useRef(null); // Create a ref for the input field
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState("");
  const { id } = useParams();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState("");
  const [sale_date, setS_date] = useState("");

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(
    userDetails.primary_warehouse_pk || ""
  );

  const handleWarehouseChange = (e) => {
    const ware = e.target.value;
    setWarehouse(ware);
    // console.log(warehouse);
  };

  useEffect(() => {
    fetchCustomers();
    fetchSales();
    fetchDataWarehouse();
  }, [searchquery, warehouse]);

  const fetchDataWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.warehouses);
        setWarehouses(response.data.warehouses);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const fetchCustomers = (query = searchquery) => {
    axios
      .get(fetch_customer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: query,
        },
      })
      .then((response) => {
        // console.log(response);
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [smallQty, setSmallQty] = useState([]);
  const fetchSales = () => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(id),
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setCustomer(response.data.data.billing_customer.id);
        setS_date(response.data.data.sale_date);
        const sortedProducts = response.data.data.items.sort((a, b) => {
          if (a.product_status > b.product_status) {
            return -1;
          }
          if (a.product_status > b.product_status) {
            return 1;
          }
          return 0;
        });

        setSelectedProducts(sortedProducts);
        setSmallQty(sortedProducts.map(() => ""));
        // setSelectedProducts(response.data.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const getCustomerDetails = (customerId) => {
      const foundCustomer = customers.find((cust) => cust.id === customerId);
      if (foundCustomer) {
        return {
          name: foundCustomer.customer_name,
          primaryShipping: foundCustomer.primary_shipping || {}, // Default to an empty object if not available
        };
      }
      return {
        name: "",
        primaryShipping: {},
      };
    };

    const customerDetails = getCustomerDetails(customer);
  }, [customer, customers]);

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [scanData, setScanData] = useState("");
  const [openRemoveP, setOpenRemoveP] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
  }, []);

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const handleInputChange = (scanData) => {
    const newValue = scanData;

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    const isValidProductSku = (value) => {
      const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
      return regex.test(value);
    };

    if (newValue.length > 5) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;

        for (const product of selectedProducts) {
          if (
            product.sku === newValue.split("_")[0] &&
            product.additional_info?.includes(
              parseInt(newValue.split("_")[1], 10)
            )
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(newValue.split("_")[0]);
          setAdditionalInfoToRemove(parseInt(newValue.split("_")[1], 10));
          setOpenRemoveP(true);

          return;
        } else {
          fetchProductData(newValue);
          setIsCameraOpen(false);
          playSound();
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      axios
        .get(validateProductForDispatch, {
          params: {
            product_value: sku,
            sale_pk: parseInt(id),
            warehouse_pk: parseInt(warehouse),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          const productData = response.data.product;
          //   console.log(productData, "data  ");
          addScannedProduct(sku, productData);
          setScanData("");
        })
        .catch((error) => {
          setOpenError(true);
          setError(error.response.data.message);
          setScanData("");
        });
    }, 600),
    [warehouse]
  );

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const additionalInfo = parseInt(additionalInfoString, 10);

      if (!productData) {
        toast.error("Product not found for the scanned SKU.");
        return;
      }

      setSelectedProducts((prev) => {
        const existingProduct = prev.find(
          (item) => item.product_sku === productData.product_sku
        );

        if (existingProduct) {
          if (existingProduct.additional_info?.includes(additionalInfo)) {
            setSkuToRemove(baseSku);
            setAdditionalInfoToRemove(additionalInfo);
            setOpenRemoveP(true);
            return prev;
          }

          return prev.map((item) =>
            item.product_sku === productData.product_sku
              ? {
                  ...item,
                  additional_info: [
                    ...(item.additional_info || []),
                    additionalInfo,
                  ],
                }
              : item
          );
        } else {
          return [
            ...prev,
            {
              sku: baseSku,
              product_name: productData.product_name,
              product_sku: productData.product_sku,
              additional_info: [additionalInfo],
            },
          ];
        }
      });
    }, 300),
    []
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"],
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 700);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected]);

  const handleCloseRemove = () => {
    setOpenRemoveP(false);
    setScanData("");
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const handleRemoveProduct = () => {
    setSelectedProducts((prev) => {
      return prev
        .map((item) => {
          if (item.sku === skuToRemove) {
            return {
              ...item,
              additional_info: item.additional_info.filter(
                (info) => info !== additionalInfoToRemove
              ),
              qty: item.additional_info.length === 1 ? 0 : item.qty,
            };
          }
          return item;
        })
        .filter((item) => item.qty > 0);
    });
    setOpenRemoveP(false);
    setSkuToRemove("");
    setAdditionalInfoToRemove(null);
  };

  const [checkedStates, setCheckedStates] = useState(
    selectedProducts.map(() => false)
  );
  const [textFieldValues, setOutsideQty] = useState(
    selectedProducts.map(() => "")
  );

  const handleChange = (index) => (event) => {
    const newCheckedStates = [...checkedStates];
    newCheckedStates[index] = event.target.checked;
    setCheckedStates(newCheckedStates);

    // Reset the text field value when unchecked
    if (!event.target.checked) {
      const newTextFieldValues = [...textFieldValues];
      newTextFieldValues[index] = "";
      setOutsideQty(newTextFieldValues);
    }
  };

  const handleOutSideQtyChange = (index) => (event) => {
    const value = event.target.value;

    // Calculate the maximum allowed value
    const maxAllowedValue =
      selectedProducts[index].quantity -
      selectedProducts[index].dispatched_quantity -
      (selectedProducts[index].additional_info
        ? selectedProducts[index].additional_info.length
        : smallQty[index] || 0);

    // Check if the value exceeds the maximum allowed
    if (value > maxAllowedValue) {
      toast.error(`Value cannot exceed ${maxAllowedValue}.`); // Show error toast
    }

    // Ensure the value does not exceed the maximum allowed
    const newValue = Math.min(value, maxAllowedValue);

    const newTextFieldValues = [...textFieldValues];
    newTextFieldValues[index] = newValue;
    setOutsideQty(newTextFieldValues);
  };

  const handleSmallQtyChange = (index) => (event) => {
    const newTextFieldValues = [...textFieldValues];
    const newValue = event.target.value;
    const maxAllowedValue = selectedProducts[index].quantity;
    const maxAvailableQty = selectedProducts[index].available_stock;
    // Check if the value exceeds the maximum allowed
    if (Number(newValue) > maxAvailableQty) {
      setErrorMsg("Stock Not Available!");
      setError(
        `Available Stock is ${maxAvailableQty}. Adjust Stock or Dipatch from Outside!`
      );
      setOpenError(true);
    } else if (Number(newValue) > maxAllowedValue) {
      toast.error(`Quantity cannot exceed ${maxAllowedValue}.`); // Show error toast
    } else {
      newTextFieldValues[index] = newValue;
      setSmallQty(newTextFieldValues); // Update the state correctly
    }
  };

  const handleSubmit = () => {
    const products = selectedProducts.map((product, index) => {
      return {
        product_sku: product.product_sku,
        outside_quantity: textFieldValues[index] || 0, // Get the quantity from the state
        serieses: product.additional_info || [],
        quantity:
          product.product_status === 0
            ? parseInt(smallQty[index]) || 0
            : product.additional_info
            ? product.additional_info.length
            : 0,
      };
    });

    const finalData = {
      user_name: userDetails.name,
      sale_pk: parseInt(id),
      warehouse_pk: parseInt(warehouse),
      products, // Add the products array
    };

    console.log(finalData);

    if (!id) {
      setError("Sale Invalid!");
      setOpenError(true);
      return;
    }

    try {
      axios
        .post(DispatchSale, finalData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            setOpenSuccess(true);
            setSuccess("Sale Dispatched Successfully!");
            fetchSales();
          } else {
            setError(response.data.message);
            setOpenError(true);
          }
        })
        .catch((error) => {
          setOpenError(true);
          setError(error.response.data.message);
        });
    } catch (error) {
      console.log("error While Creating Draft :", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <audio id="scanSound" src={soundEffect} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error
        openError={openError}
        setOpenError={setOpenError}
        error={error}
        errormsg={errormsg}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/dispatch-sales")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Dispatch</Typography>
              <Typography color="inherit">Dispatch Details</Typography>
              <Typography sx={{ color: "text.primary" }}>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="card p-4">
              <div className="row">
                <div className="col-lg-3 my-1">
                  Customer:{" "}
                  {customers.find((cust) => cust.id === customer) ? (
                    <span>
                      {
                        customers.find((cust) => cust.id === customer)
                          .customer_name
                      }
                    </span>
                  ) : (
                    <span>No matching customer found</span>
                  )}
                </div>
                <div className="col-lg-5 my-1">Date: {sale_date}</div>
                <div className="col-lg-2 my-1">
                  <FormControl size="small" fullWidth>
                    <InputLabel>Select Warehouse</InputLabel>
                    <Select
                      value={warehouse}
                      label="Select Warehouse"
                      onChange={handleWarehouseChange}
                    >
                      <MenuItem value="">--Select Warehouse--</MenuItem>
                      {warehouses.map((ware) => (
                        <MenuItem key={ware.id} value={ware.id}>
                          {ware.ware_house}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-lg-2 text-end">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSubmit}
                  >
                    Confirm Dispatch
                  </Button>
                </div>
              </div>
            </div>

            {isCameraOpen ? (
              <Webcam
                audio={false}
                ref={webcamRef}
                width={1920}
                height={220}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  facingMode: "environment",

                  border: "none",
                }}
                style={{
                  marginBottom: "12px",
                  border: "3px dashed green",
                  padding: "0",
                  width: "100%",
                  objectFit: "cover",
                }}
              />
            ) : null}

            {isCameraOpen ? (
              <>
                <Button
                  size="small"
                  id="mobile_scan"
                  onClick={handleCloseCamera}
                  color="error"
                  variant="contained"
                  style={{
                    marginTop: "5px",
                    maxWidth: "150px",
                    position: "fixed",
                    zIndex: "5",
                    bottom: "5px",
                    right: "5px",
                  }}
                >
                  Close
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  id="mobile_scan"
                  onClick={handleOpenCamera}
                  variant="contained"
                  style={{
                    marginTop: "5px",
                    maxWidth: "150px",
                    position: "absolute",

                    bottom: "5px",
                    right: "5px",
                  }}
                >
                  Scan Products
                </Button>
              </>
            )}

            <div className="card my-2 ">
              <input
                autoFocus
                value={scanData}
                ref={inputRef}
                style={{
                  border: "1px solid #d1d1d1",
                  borderRadius: "4px",
                  maxWidth: "150px",
                  fontSize: "15px",
                  color: "grey",
                  marginTop: "5px",
                  padding: "5px",
                }}
                onChange={(e) => setScanData(e.target.value)}
              />
              <div className="row">
                <div className="card p-2 my-2">
                  <div className="products_table_class">
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 600 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead className="table-head">
                          <TableRow>
                            <TableCell>SKU</TableCell>
                            <TableCell align="left">Product</TableCell>

                            <TableCell align="left">Sale Qty</TableCell>
                            <TableCell align="left">Quantity</TableCell>
                            <TableCell align="left">Left Qty</TableCell>
                            {/* <TableCell align="right">Last Price</TableCell> */}

                            <TableCell
                              align="left"
                              style={{
                                width: "180px",
                              }}
                            >
                              Dispatch From Outside
                            </TableCell>
                            <TableCell align="right">Dispatched</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {selectedProducts && selectedProducts.length > 0 ? (
                            <>
                              {selectedProducts.map((product, index) => (
                                <TableRow
                                  key={index}
                                  style={{
                                    backgroundColor:
                                      product.product_status === 0
                                        ? "#e0f1ff"
                                        : "transparent",
                                  }}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    style={{ width: "6.5vw" }}
                                    scope="row"
                                  >
                                    {product.product_sku}
                                  </TableCell>
                                  <TableCell align="left">
                                    {product.product_name}
                                  </TableCell>

                                  <TableCell align="left">
                                    {product.quantity}
                                  </TableCell>

                                  <TableCell align="left">
                                    {product.product_status === 0 ? (
                                      <TextField
                                        inputProps={{ min: 0 }} // Set the minimum value to 0
                                        fullWidth
                                        type="number"
                                        size="small"
                                        label="Quantity"
                                        variant="outlined"
                                        style={{ width: "100px" }}
                                        value={smallQty[index]} // Bind the value to the state
                                        onChange={handleSmallQtyChange(index)} // Handle changes
                                      />
                                    ) : (
                                      (product.additional_info &&
                                        product.additional_info.length) ||
                                      0
                                    )}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    style={{ color: "red" }}
                                  >
                                    {product.product_status === 0
                                      ? product.quantity -
                                        product.dispatched_quantity -
                                        (smallQty[index] || 0)
                                      : product.quantity -
                                        product.dispatched_quantity -
                                        (product.additional_info
                                          ? product.additional_info.length
                                          : 0)}
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    style={{
                                      width: "180px",
                                      fontWeight: "bold",
                                      display: "flex",
                                    }}
                                  >
                                    <Switch
                                      disabled={
                                        product.quantity -
                                          product.dispatched_quantity -
                                          (product.additional_info
                                            ? product.additional_info.length
                                            : 0) ===
                                        0
                                      }
                                      checked={checkedStates[index]}
                                      onChange={handleChange(index)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                    />
                                    <TextField
                                      inputProps={{ min: 0 }} // Set the minimum value to 0
                                      fullWidth
                                      type="number"
                                      size="small"
                                      label="Quantity"
                                      variant="outlined"
                                      style={{ width: "100px" }}
                                      disabled={!checkedStates[index]}
                                      value={textFieldValues[index]} // Bind the value to the state
                                      onChange={handleOutSideQtyChange(index)} // Handle changes
                                    />
                                  </TableCell>

                                  <TableCell align="right">
                                    {product.dispatched_quantity}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          ) : (
                            <>
                              <TableRow>
                                <TableCell
                                  colSpan="9"
                                  style={{
                                    textAlign: "center",
                                    color: "red",
                                  }}
                                >
                                  No Products Selected
                                </TableCell>
                              </TableRow>
                            </>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      {/* //Remove Product */}
      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default DispatchDetails;
