import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { fetch_sale, apiKey, fetch_sale_detail } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import PrintSlipModal from "./PrintSlipModal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CancelSale from "../../Messages/CancelSale";
import CustomToolbar from "../../Components/CustomToolbar";
import Error from "../../Messages/Error";
import SaleTypeModal from "./SelectSaleTypeModal";
import { IoMdArrowRoundBack } from "react-icons/io";

function Sales() {
  const [purchases, setSales] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);

  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, filterModel]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: query,

      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_sale, data, { headers })
      .then((response) => {
        console.log(response);
        setSales(response.data.data);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setOpenError(true);
        setLoading(false);
        console.log(error.response.data.message);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handleViewClick = (id) => {
    navigate(`/view-sale/${id}`);
  };

  const columns = [
    {
      field: "id",
      headerName: "IDs",
      width: 10,
    },
    {
      field: "sale_type",
      headerName: "Sale Type",
      width: 120,
      renderCell: (params) =>
        params.row.type_of_sale == 2 ? "Party Sale" : "Cash Sale",
    },

    {
      field: "billing_customer",
      renderCell: (params) =>
        params.row.billing_customer?.customer_name || "N/A",
      headerName: "Customer",
      width: 250,
    },

    { field: "sale_date", headerName: "Sale Date", width: 180 },

    { field: "created_by", headerName: "Created By", width: 300 },
    {
      field: "total_price",
      renderCell: (params) =>
        "₹" +
          (params.row.total_price -
            params.row.discount_amount +
            params.row.courier_charge +
            params.row.other_charge) || "N/A",
      headerName: "Amount",
      width: 120,
    },

    { field: "total_products", headerName: "Total Products", width: 120 },

    {
      field: "actions",
      headerName: "Actions",
      width: 400,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleViewClick(params.row.id)}
          >
            View
          </Button>
          {/* <Button
            style={{ marginLeft: "5px" }}
            variant="outlined"
            size="small"
            onClick={() => handleEditClick(params.row.id)}
          >
            Edit
          </Button> */}

          {params.row.type_of_sale === 2 ? (
            <>
              <Button
                style={{ marginLeft: "5px" }}
                disabled={
                  params.row.dispatched_status === 1 ||
                  params.row.is_cancelled === 1
                }
                variant="outlined"
                color="info"
                size="small"
                onClick={() => navigate(`/dispatch-sale-scan/${params.row.id}`)}
              >
                Dispatch
              </Button>
            </>
          ) : null}

          <Button
            disabled={params.row.is_cancelled === 1}
            variant="outlined"
            style={{ marginLeft: "5px" }}
            color="primary"
            size="small"
            onClick={() => handlePrintSlip(params.row.id)}
          >
            Print
          </Button>

          {params.row.type_of_sale === 2 ? (
            <>
              <Button
                style={{ marginLeft: "5px" }}
                disabled={
                  params.row.dispatched_status === 1 ||
                  params.row.dispatched_status === 2 ||
                  params.row.is_cancelled === 1
                }
                variant="outlined"
                color="error"
                size="small"
                onClick={() => handleCancelSale(params.row.id)}
              >
                Cancel
              </Button>
            </>
          ) : null}
        </>
      ),
    },
  ];

  const [openModal, setOpenModal] = useState(false);
  const [openSaleType, setOpenSaleType] = useState(false);

  const [data, setData] = useState([]);
  const [addressdata, setAddressData] = useState([]);
  const [saleProducts, setSaleProducts] = useState([]);

  const handlePrintSlip = (pk) => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(pk),
        },
      })
      .then((response) => {
        setData(response.data.data);
        setAddressData(response.data.data.billing_customer);
        setSaleProducts(response.data.data.items);
        console.log(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    setOpenModal(true);
  };

  const handleSaleType = () => {
    setOpenSaleType(true);
  };

  const [cancelpk, setCancelPk] = useState("");
  const handleCancelSale = (pk) => {
    setCancelPk(pk);
    setOpenDelete(true);
  };
  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <SaleTypeModal
        openSaleType={openSaleType}
        setOpenSaleType={setOpenSaleType}
      />

      <CancelSale
        cancelpk={cancelpk}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <PrintSlipModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        fetchData={fetchData}
        saleProducts={saleProducts}
        data={data}
        addressdata={addressdata}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Sales</Typography>
              <Typography sx={{ color: "text.primary" }}>All Sales</Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleSaleType}
                >
                  Create Sale
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              checkboxSelection
              disableRowSelectionOnClick
              rows={purchases}
              columns={columns}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelection) =>
                setSelectionModel(newSelection)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Sales;
