import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { apiKey, delete_vendors } from "../Api";
import Success from "./Success";
import Error from "./Error";
import { FaTrashCan } from "react-icons/fa6";
import Box from "@mui/material/Box";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function DeleteVendor({ openDelete, setOpenDelete, selectedPks, fetchData }) {
  const handleClose = () => {
    setOpenDelete(false);
  };

  const [existingVendorsListError, setExistingVendorsListError] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleDelete = () => {
    axios
      .delete(delete_vendors, {
        headers: {
          "API-Key": apiKey,
        },
        data: {
          vendor_pks: selectedPks,
        },
      })
      .then(() => {
        setOpenSuccess(true);
        setSuccess(`Selected Vendors Deleted Successfully!`);
        setOpenDelete(false);
        fetchData();
      })
      .catch((error) => {
        setOpenError(true);

        if (error.response?.data?.existing_vendors_list) {
          const existingVendors = error.response.data.existing_vendors_list
            .map(([id, name]) => `${name} (ID: ${id})`)
            .join(", ");
          setError(
            `These Vendors cannot be deleted because they have associated purchase data: ${existingVendors}`
          );
          setExistingVendorsListError(existingVendors);
        } else {
          setError(error.response.data.message);
        }

        console.error("Failed to delete Vendors", error.response);
        console.error(
          "Purchase Error: ",
          error.response?.data?.existing_vendors_list
        );
      });
  };

  return (
    <>
      {existingVendorsListError.length > 0 &&
        console.log("existingVendorsListError : ", existingVendorsListError)}

      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Dialog open={openDelete} onClose={handleClose}>
        <Box sx={style}>
          <DialogTitle style={{ color: "red" }}>
            <FaTrashCan style={{ marginTop: "-6px" }} /> Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleDelete}>Confirm</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default DeleteVendor;
