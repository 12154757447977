import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    BarChart,
    Bar,
} from 'recharts';
import { Button } from "@mui/material";
import { apiKey, fetch_sale } from "../../Api";
import { useNavigate } from "react-router-dom";
import './Dashboard.css'
import { FaArrowUpRightFromSquare } from "react-icons/fa6";

const data = [
    { name: 'Jan', sales: 4000, purchases: 2400 },
    { name: 'Feb', sales: 3000, purchases: 1398 },
    { name: 'Mar', sales: 2000, purchases: 9800 },
    { name: 'Apr', sales: 2780, purchases: 3908 },
    { name: 'May', sales: 1890, purchases: 4800 },
    { name: 'Jun', sales: 2390, purchases: 3800 },
    { name: 'Jul', sales: 3490, purchases: 4300 },
];

function Dashboard() {

    const [dispatchData, setDispatchData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        fetchDispatchData();
    }, []);

    const fetchDispatchData = () => {
        const headers = {
            "Content-Type": "application/json",
            "API-Key": apiKey,
        };

        axios
            .post(fetch_sale, {}, { headers })
            .then((response) => {
                const filteredDispatches = response.data.data.filter(
                    (sale) => sale.type_of_sale === 2 && sale.dispatched_status === 0
                );
                setDispatchData(filteredDispatches);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    };

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="row my-4">
                        <div className="col-md-3 mb-2">
                            <div className="card rounded text-center">
                                <div className="card-body ">
                                    <h5 className="card-title">Total Products</h5>
                                    <h4 className="card-text">1,234</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-2">
                            <div className="card rounded text-center">
                                <div className="card-body">
                                    <h5 className="card-title">Total Sales</h5>
                                    <h4 className="card-text">₹12,345</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-2">
                            <div className="card rounded text-center">
                                <div className="card-body">
                                    <h5 className="card-title">Total Purchases</h5>
                                    <h4 className="card-text">₹9,876</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 mb-2">
                            <div className="card rounded text-center">
                                <div className="card-body">
                                    <h5 className="card-title">Total Clients</h5>
                                    <h4 className="card-text">567</h4>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Sales and Purchases</h5>
                                    <ResponsiveContainer width="100%" height={300}>
                                        <LineChart data={data}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                            <Tooltip />
                                            <Legend />
                                            <Line type="monotone" dataKey="sales" stroke="#8884d8" />
                                            <Line type="monotone" dataKey="purchases" stroke="#82ca9d" />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="card-title">Pending Dispatches</h5>
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : error ? (
                                        <p>Error fetching data: {error.message}</p>
                                    ) : (
                                        <>
                                            {dispatchData.length > 0 ? (
                                                <div className="dispatch-list">
                                                    {dispatchData.map((dispatch, index) => (
                                                        <div key={dispatch.id} className="dispatch-item">
                                                            <span className="dispatch-rank">{index + 1}</span>
                                                            <span className="sale-type">
                                                                {dispatch.type_of_sale === 2 ? "Party Sale" : "Cash Sale"}
                                                            </span>
                                                            <span className="customer-name">
                                                                {dispatch.billing_customer?.customer_name || "N/A"}
                                                            </span>
                                                            <span className="sale-date">
                                                                {dispatch.sale_date}
                                                            </span>
                                                            <Button
                                                                variant="outlined"
                                                                size="small"
                                                                style={{width: "max-content"}}
                                                                onClick={() => navigate(`/dispatch-sale-scan/${dispatch.id}`)}
                                                            >
                                                                Dispatch
                                                                {/* <FaArrowUpRightFromSquare /> */}
                                                            </Button>
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                <p>No pending dispatches found.</p>
                                            )}
                                        </>
                                    )}
                                    <div className="text-center mt-2">
                                        <button className="btn-1 float-right">View All Dispatches</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    );
}

export default Dashboard;
