import React, { useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { cancel_sale, apiKey } from "../Api";
import Success from "./Success";
import Error from "./Error";
import { FaTrashCan } from "react-icons/fa6";
import Box from "@mui/material/Box";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};
function CancelSale({ openDelete, setOpenDelete, cancelpk, fetchData }) {
  const handleClose = () => {
    setOpenDelete(false);
  };

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleDelete = () => {
    const data = {
      sale_pk: parseInt(cancelpk),
    };

    axios
      .post(cancel_sale, data, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then(() => {
        setOpenSuccess(true);
        setSuccess(`Sale Cancelled Successfully!`);
        setOpenDelete(false);
        fetchData();
      })
      .catch((error) => {
        setOpenError(true);
        setError("Oops!", error);
        setOpenDelete(false);

        console.error("Failed to cancelled sale", error);
      });
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Dialog open={openDelete} onClose={handleClose}>
        <Box sx={style}>
          <DialogTitle style={{ color: "red" }}>
            <FaTrashCan style={{ marginTop: "-6px" }} /> Cancel
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? You won't be able to revert this!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="outlined" color="primary" onClick={handleDelete}>
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default CancelSale;
