import React, { useEffect, useState } from "react";
import { Modal, Button } from "@mui/material";

function SeriesModal({ open, handleClose, product, updateSelectedSeries }) {
  const [tooltip, setTooltip] = useState(null); // State for managing tooltip visibility
  const [selectedSeries, setSelectedSeries] = useState([]);

  useEffect(() => {
    // Initialize the selected series from the product's selectedSeries if available
    if (product && product.selectedSeries) {
      setSelectedSeries(product.selectedSeries);
    }
  }, [product]);

  const handleSelectSeries = (series) => {
    const seriesName = series.series;
    const newSelected = [...selectedSeries];

    // Add or remove the series from selectedSeries array
    if (newSelected.includes(seriesName)) {
      newSelected.splice(newSelected.indexOf(seriesName), 1); // Deselect if already selected
    } else {
      newSelected.push(seriesName); // Select if not already selected
    }

    // Update the selected series state
    setSelectedSeries(newSelected);
  };

  const handleSaveAndClose = () => {
    // Call the callback to update the selected series in the parent component
    updateSelectedSeries(product.sku, selectedSeries);
    handleClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    bgcolor: "background.paper",
    overflow: "auto",
  };

  return (
    <>
      {console.log("product : ", product)}
      <Modal
        className="inner-bg-white p-0 pb-2"
        open={open}
        sx={style}
        style={{ height: "max-content" }}
      >
        <div style={{ padding: "20px", backgroundColor: "white" }}>
          <div className="row align-items-center">
            <div className="col-md-12 text-center">
              <h4 className="mb-0">{product.product_name}</h4>
            </div>
            <div className="col-md-12 mt-2 text-center row">
              <div className="col-md-6">
                <strong>Selected : </strong> {selectedSeries.length || 0}
              </div>
              <div className="col-md-6">
                <strong>Remaining : </strong>{" "}
                {product.all_series.length - selectedSeries.length}
              </div>
            </div>
          </div>

          <div
            className="row my-3"
            style={{ maxHeight: "200px", overflow: "auto" }}
          >
            {product.all_series.map((series) => (
              <div className={`col-md-1  p-1 position-relative `}>
                <div
                  className={`  d-flex align-items-center justify-content-center border  p-2 position-relative  ${
                    selectedSeries.includes(series.series) ? "" : ""
                  }`}
                  key={series.series}
                  onClick={() => handleSelectSeries(series)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    backgroundColor: selectedSeries.includes(series.series)
                      ? "#4c8cc2"
                      : "#ededed",
                    color: selectedSeries.includes(series.series)
                      ? "white"
                      : "",
                  }}
                  onMouseEnter={() => setTooltip(series)} // Set tooltip content on mouse enter
                  onMouseLeave={() => setTooltip(null)} // Clear tooltip content on mouse leave
                >
                  <span>{series.series}</span>
                </div>
              </div>
            ))}
          </div>

          <div>
            <Button
              className="float-end d-block ms-2"
              onClick={handleSaveAndClose} // Close the modal and save
              variant="contained"
              color="primary"
            >
              Save
            </Button>
            <Button
              className="d-block float-end"
              onClick={handleClose} // Close the modal
              variant="outlined"
              color="error"
            >
              Close
            </Button>
          </div>

          {/* Tooltip */}
          {tooltip && (
            <div
              style={{
                position: "absolute",
                backgroundColor: "#ffffff",
                border: "1px solid #ccc",
                padding: "5px",
                zIndex: "1000",
                borderRadius: "5px",
                boxShadow: "0 0 5px rgba(0,0,0,0.3)",
              }}
            >
              {/* Tooltip content with series details */}
              <div>
                {tooltip.series}, {tooltip.ware_house__ware_house},{" "}
                {tooltip.rack__row__row_name || "N/A"},{" "}
                {tooltip.rack__rack_name || "N/A"}
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}

export default SeriesModal;
