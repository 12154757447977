import {
  Box,
  Breadcrumbs,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { apiKey, fetch_customer } from "../../../Api";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import CreateCustomer from "./CreateCustomer";
import CustomToolbar from "../../../Components/CustomToolbar";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteCustomer from "./DeleteCustomer";
import ViewCustomer from "./ViewCustomer";
import EditCustomer from "./EditCustomer";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 1400,
  minWidth: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AllClients() {
  const [allClients, setAllClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchCustomers = (page = currentPage, query = searchquery) => {
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    setLoading(true);

    axios
      .get(fetch_customer, {
        params: {
          page: parseInt(page),
          per_page: parseInt(pageSize),
          search: query,
        },
        headers,
      })
      .then((response) => {
        if (response.data.status === 1 && response.data.customers) {
          console.log(response.data.customers);
          setAllClients(response.data.customers);
          setTotalPages(response.data.num_pages);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching clients:", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchCustomers();
  }, [currentPage, pageSize, searchquery]);

  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const handleOpenDetailModal = (customer) => {
    console.log(customer);
    setSelectedCustomer(customer);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setSelectedCustomer(null);
    setOpenDetailModal(false);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  // Define columns for DataGrid
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "customer_name", headerName: "Customer Name", width: 180 },
    { field: "email", headerName: "Email", width: 280 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "gst_number", headerName: "GST Number", width: 200 },
    {
      field: "address",
      headerName: "Address",
      width: 420,
      renderCell: (params) => {
        const shipping = params.row.primary_shipping;
        if (shipping) {
          const { address, city, state, country } = shipping;
          return `${address || ""}, ${city || ""}, ${state || ""}, ${
            country || ""
          }`.trim();
        }
        return "";
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => handleOpenDetailModal(params.row)}
          >
            View
          </Button>
          <Button
            color="error"
            variant="outlined"
            size="small"
            onClick={() => handleEditCustomer(params.row)}
            style={{ marginLeft: "5px" }}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  const [openAddcustomer, setAddCustomerOpen] = useState(false);
  const handleOpenAddcustomer = () => setAddCustomerOpen(true);
  const handleCloseAddcustomer = () => setAddCustomerOpen(false);

  // Delete Customer
  const [openDelete, setOpenDelete] = useState(false);

  const [pks, setPks] = useState([]);
  const handleDeleteCustomer = () => {
    const selectedPks = [];
    const ourDiv = document.querySelector(".all-customers-grid");
    const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
    let loopCount = 0;
    Array.from(allCheckBoxes).forEach((single_input) => {
      if (single_input.checked) {
        loopCount++;
        if (loopCount > 1) {
          const cur_row = single_input.closest(".MuiDataGrid-row");
          const third_child = cur_row.children[2];
          const selected_pk = third_child.innerHTML;
          selectedPks.push(selected_pk);
        }
      }
    });

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      toast.error("No Customers selected!");
      return;
    } else {
      setOpenDelete(true);
    }
  };

  // const handleDeleteCustomer = () => {
  //   const selectedPks = [];
  //   const ourDiv = document.querySelector(
  //     ".MuiDataGrid-main.css-3eek4p-MuiDataGrid-main"
  //   );
  //   const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
  //   let loopCount = 0;
  //   Array.from(allCheckBoxes).forEach((single_input) => {
  //     if (single_input.checked) {
  //       loopCount++;
  //       if (loopCount > 1) {
  //         const cur_row = single_input.closest(".MuiDataGrid-row");
  //         const third_child = cur_row.children[2];
  //         const selected_pk = third_child.innerHTML;
  //         selectedPks.push(selected_pk);
  //       }
  //     }
  //   });

  //   setPks(selectedPks);

  //   if (selectedPks.length === 0) {
  //     toast.error("No Customers selected!");
  //     return;
  //   } else {
  //     setOpenDelete(true);
  //   }
  // };

  // Edit Customer

  const [selectedEditCustomer, setSelectedEditCustomer] = useState();
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState(false);

  const handleEditCustomer = (customer) => {
    // console.log(customer);
    setSelectedEditCustomer(customer);
    setOpenEditCustomerModal(true);
  };

  return (
    <>
      <ToastContainer />

      <Modal
        open={openEditCustomerModal}
        onClose={() => setOpenEditCustomerModal(false)}
      >
        <Box sx={style}>
          <EditCustomer
            customerEditDetail={selectedEditCustomer}
            apiKey={apiKey}
            closeModal={() => setOpenEditCustomerModal(false)}
            fetchCustomers={fetchCustomers}
          />
        </Box>
      </Modal>

      <DeleteCustomer
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchCustomers}
      />

      <CreateCustomer
        opencustomer={openAddcustomer}
        handleOpencustomer={handleOpenAddcustomer}
        handleClosecustomer={handleCloseAddcustomer}
        fetchCustomers={fetchCustomers}
      />

      <ViewCustomer
        openViewCustomer={openDetailModal}
        handleCloseCustomer={handleCloseDetailModal}
        selectedCustomer={selectedCustomer}
        fetchCustomers={fetchCustomers}
      />

      {/* Detail Modal */}
      {/* <Dialog open={openDetailModal} onClose={handleCloseDetailModal}>
                <DialogTitle>Custumer Details</DialogTitle>
                <DialogContent>
                    {selectedCustumer && (
                        <div>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td><strong>ID:</strong></td>
                                            <td>{selectedCustumer.id}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Customer Name:</strong></td>
                                            <td>{selectedCustumer.customer_name}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Email:</strong></td>
                                            <td>{selectedCustumer.email}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Phone:</strong></td>
                                            <td>{selectedCustumer.phone}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Alternative Phone:</strong></td>
                                            <td>{selectedCustumer.alternativ_phone || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>GST Number:</strong></td>
                                            <td>{selectedCustumer.gst_number || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Shipping Contact Name:</strong></td>
                                            <td>{selectedCustumer.primary_shipping?.contact_name || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Shipping Contact No:</strong></td>
                                            <td>{selectedCustumer.primary_shipping?.contact_no || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Shipping Address:</strong></td>
                                            <td>{selectedCustumer.primary_shipping?.address || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Shipping City:</strong></td>
                                            <td>{selectedCustumer.primary_shipping?.city || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Shipping State:</strong></td>
                                            <td>{selectedCustumer.primary_shipping?.state || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Shipping Country:</strong></td>
                                            <td>{selectedCustumer.primary_shipping?.country || 'N/A'}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Shipping Pincode:</strong></td>
                                            <td>{selectedCustumer.primary_shipping?.pincode || 'N/A'}</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDetailModal} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog> */}

      <div className="main-panel">
        <div className="content-wrapper">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography color="inherit">Customers</Typography>
            <Typography sx={{ color: "text.primary" }}>
              All Customers
            </Typography>
          </Breadcrumbs>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleOpenAddcustomer}
                >
                  {" "}
                  Add Customer
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/import-customers")}
                >
                  Import Customers
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleDeleteCustomer}
                >
                  Delete Customers
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              marginTop: "2px",
              backgroundColor: "white",
            }}
            className="all-customers-grid"
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              checkboxSelection
              disableRowSelectionOnClick
              rows={allClients}
              columns={columns}
              loading={loading}
              pagination
              paginationMode="server"
              rowCount={totalPages * pageSize}
              pageSize={pageSize}
              onPageChange={(newPage) => setCurrentPage(newPage + 1)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              getRowId={(row) => row.id} // Unique ID for each row
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AllClients;
