import { TextField, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { edit_customer } from '../../../Api';
import axios from 'axios';

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditCustomer({ customerEditDetail, apiKey, closeModal, fetchCustomers }) {
  const [customerName, setCustomerName] = useState(customerEditDetail.customer_name);
  const [email, setEmail] = useState(customerEditDetail.email);
  const [phone, setPhone] = useState(customerEditDetail.phone);
  const [alternativePhone, setAlternativePhone] = useState(customerEditDetail.alternativ_phone);
  const [gstNumber, setGstNumber] = useState(customerEditDetail.gst_number);

  useEffect(() => {
    setCustomerName(customerEditDetail.customer_name);
    setEmail(customerEditDetail.email);
    setPhone(customerEditDetail.phone);
    setAlternativePhone(customerEditDetail.alternativ_phone);
    setGstNumber(customerEditDetail.gst_number);
  }, [customerEditDetail]);

  const handleSave = () => {
    const customer_data = {
      customer_name: customerName,
      email,
      phone,
      alternativ_phone: alternativePhone,
      gst_number: gstNumber,
    };

    const updatedData = {
      customer_pk : customerEditDetail.id,
      customer_data,
    } 

    console.log("updatedCustomer : ", updatedData);

    axios.post(edit_customer, updatedData, {
      headers: {
        "API-key": apiKey
      }
    })
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data)
          toast.success(response.data.message);
          fetchCustomers();
          closeModal();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log('Error Updating Customer Detail:', error);
      });
  };

  return (
    <>
      <ToastContainer />
      <div>
        <h2>Edit Customer</h2>
        <div className="row">
          <div className="col-md-6">
            <TextField
              label="Customer Name"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              fullWidth
              margin="normal"
            />
          </div>
          <div className="col-md-6">
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
            />
          </div>
          <div className="col-md-6">
            <TextField
              label="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              fullWidth
              margin="normal"
            />
          </div>
          <div className="col-md-6">
            <TextField
              label="Alternative Phone"
              value={alternativePhone}
              onChange={(e) => setAlternativePhone(e.target.value)}
              fullWidth
              margin="normal"
            />
          </div>
          <div className="col-md-6">
            <TextField
              label="GST Number"
              value={gstNumber}
              onChange={(e) => setGstNumber(e.target.value)}
              fullWidth
              margin="normal"
            />
          </div>
        </div>
        <Button variant="contained" color="primary" onClick={handleSave}>
          Save Changes
        </Button>
        <Button variant="outlined" onClick={closeModal} style={{ marginLeft: '10px' }}>
          Cancel
        </Button>
      </div>
    </>
  );
}

export default EditCustomer;
