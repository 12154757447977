import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { MdSpaceDashboard } from "react-icons/md";
import { AiOutlineProduct } from "react-icons/ai";
import { MdInventory } from "react-icons/md";
import { BiSolidPurchaseTag } from "react-icons/bi";
import SaleTypeModal from "../Pages/Sales/SelectSaleTypeModal";

function Sidebar({ isActive }) {
  const [openSaleType, setOpenSaleType] = useState(false);
  const handleSaleType = () => {
    setOpenSaleType(true);
  };

  const location = useLocation();

  const menuItems = [
    {
      title: "Dashboard",
      path: "/",
      icon: <MdSpaceDashboard />,
    },
    {
      title: "Products",
      icon: <AiOutlineProduct />,
      subItems: [
        { path: "/all_products", label: "All Products" },
        { path: "/add_product", label: "Add Products" },
        { path: "/unallocated-products", label: "Unallocated Products" },
      ],
    },
    {
      title: "Inventory",
      icon: <MdInventory />,
      subItems: [
        { path: "/warehouses", label: "Warehouses" },
        { path: "/stock_adjustments", label: "Adjustments" },
        { path: "/conversions", label: "Conversion" },
        { path: "/stock_transfer", label: "Stock Transfer" },
      ],
    },
    {
      title: "Our Clients",
      icon: <BiSolidPurchaseTag />,
      subItems: [
        { path: "/all-vendors", label: "Our Vendors" },
        { path: "/all-customers", label: "Our Customers" },
      ],
    },
    {
      title: "Purchases",
      icon: <BiSolidPurchaseTag />,
      subItems: [
        { path: "/all_purchases", label: "Purchase Orders" },
        { path: "/all-draft-purchase", label: "Draft Purchases" },
      ],
    },
    {
      title: "Sales",
      icon: <BiSolidPurchaseTag />,
      subItems: [
        { path: "/all_sales", label: "Sales" },
        { label: "Create Sale", onClick: handleSaleType },
        { path: "/requirements-list", label: "Requirement List" },
      ],
    },

    {
      title: "Dispatch",
      icon: <BiSolidPurchaseTag />,
      subItems: [{ path: "/dispatch-sales", label: "Dispatch" }],
    },
  ];

  const isActiveSubItem = (subItems) => {
    return subItems.some((subItem) => subItem.path === location.pathname);
  };

  return (
    <>
      <SaleTypeModal
        openSaleType={openSaleType}
        setOpenSaleType={setOpenSaleType}
      />

      <nav
        className={`sidebar sidebar-offcanvas ${isActive ? "active" : ""}`}
        id="sidebar"
        style={{ backgroundColor: "##00265c" }}
      >
        <ul className="nav scrollNav">
          {/* <li className="nav-item nav-category">Main</li> */}
          {menuItems.map((item, index) => (
            <li
              className={`nav-item ${
                item.subItems && isActiveSubItem(item.subItems) ? "active" : ""
              }`}
              key={index}
            >
              {item.path ? (
                <NavLink className="nav-link" to={item.path}>
                  <span className="icon-bg">{item.icon}</span>
                  <span className="menu-title">{item.title}</span>
                </NavLink>
              ) : (
                <>
                  <a
                    className="nav-link"
                    data-bs-toggle="collapse"
                    href={`#${item.title.toLowerCase().replace(/\s/g, "-")}`}
                    aria-expanded="false"
                    aria-controls="forms"
                  >
                    <span className="icon-bg">{item.icon}</span>
                    <span className="menu-title">{item.title}</span>
                    <i className="menu-arrow"></i>
                  </a>
                  {item.subItems && (
                    <div
                      className="collapse"
                      id={item.title.toLowerCase().replace(/\s/g, "-")}
                    >
                      <ul className="nav flex-column sub-menu">
                        {item.subItems.map((subItem, subIndex) => (
                          <li className="nav-item" key={subIndex}>
                            {subItem.onClick ? (
                              <a
                                className="nav-link"
                                style={{ cursor: "pointer" }}
                                onClick={subItem.onClick}
                              >
                                {subItem.label}
                              </a>
                            ) : (
                              <NavLink className="nav-link" to={subItem.path}>
                                {subItem.label}
                              </NavLink>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </>
              )}
            </li>
          ))}
          <li style={{ position: "absolute", bottom: "0px", left: "20px" }}>
            <p className="menu-title">Version 1.0.0</p>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Sidebar;
