import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, edit_warehouse } from "../../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { MdDoubleArrow } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";
import TextField from "@mui/material/TextField";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "600px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditWarehouse({
  setOpenModal,
  openModal,
  warepk,
  warehouse,
  address,
  fetchData,
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [updatewarehouse, setWarehouse] = useState("");
  const [updateaddress, setAddress] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (warehouse) {
      setWarehouse(warehouse);
    }
    if (address) {
      setAddress(address);
    } else {
      setAddress("");
    }
  }, [warehouse, address]);

  const handleEditWarehouse = (e) => {
    e.preventDefault();

    const trimmedWarehouse = updatewarehouse.trim();
    if (trimmedWarehouse.length < 2) {
      setError("Warehouse name must be at least 2 characters long.");
      setOpenError(true);
      return;
    }

    const formattedData = {
      warehouse_pk: warepk,
      warehouse_name: updatewarehouse || warehouse,
      address: updateaddress || "",
    };

    axios
      .post(edit_warehouse, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess(`Warehouse has been updated successfully!`);
          fetchData();
          setTimeout(() => {
            setOpenModal(false);
          }, 1000);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(
          error.response?.data?.message ||
            "Something went wrong! Please try again."
        );
        setOpenError(true);
      });
  };

  const handleClose = () => {
    setOpenModal(false);
    setWarehouse(warehouse);
    setAddress(address);
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            style={{
              overflowX: "hidden",
            }}
          >
            <div className="card-body">
              <h5 style={{ marginBottom: "25px" }}>
                <MdDoubleArrow style={{ marginTop: "-4px" }} /> Update Warehouse
              </h5>

              <TextField
                id="outlined-warehouse-name"
                label="Warehouse Name"
                value={updatewarehouse || warehouse}
                size="small"
                required
                onChange={(e) => setWarehouse(e.target.value)}
                fullWidth
              />

              <TextField
                style={{ marginTop: "10px" }}
                id="outlined-address"
                label="Address"
                multiline
                rows={4}
                value={updateaddress}
                size="small"
                fullWidth
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "2px" }}
              variant="outlined"
              color="primary"
              onClick={handleEditWarehouse}
              // disabled={
              //   Boolean(error) ||
              //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
              // }
            >
              Update
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EditWarehouse;
