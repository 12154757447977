import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PartSale from "../../Assets/Img/party.gif";
import CashSale from "../../Assets/Img/cash.gif";
import { IoClose } from "react-icons/io5";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function SelectSaleTypeModal({ openSaleType, setOpenSaleType }) {
  const handleClose = () => {
    setOpenSaleType(false);
  };

  const handleOpenCashSale = () => {
    setOpenSaleType(false);
    navigate("/add-cash-sale");
  };

  const handleOpenPartySale = () => {
    setOpenSaleType(false);
    navigate("/add-party-sale");
  };

  const navigate = useNavigate();

  return (
    <>
      <Dialog open={openSaleType} onClose={handleClose}>
        <Box sx={style}>
          <div style={{ padding: "15px 0px 0px 25px" }}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Sales</Typography>
              {/* <Typography color="inherit">Customer Details</Typography> */}
              <Typography sx={{ color: "text.primary" }}>
                Select Sale Type
              </Typography>
            </Breadcrumbs>
          </div>

          <IoClose className="closeModalBtn" onClick={handleClose} />
          <DialogContent>
            <div className="row">
              <div className="col-sm-6">
                <Card variant="outlined">
                  <CardContent style={{ textAlign: "center" }}>
                    <img src={CashSale} className="img-fluid" />

                    <Button
                      size="medium"
                      color="warning"
                      style={{ marginTop: "1vh" }}
                      variant="outlined"
                      onClick={handleOpenCashSale}
                    >
                      Cash Sale
                    </Button>
                  </CardContent>
                </Card>
              </div>
              <div className="col-sm-6">
                <Card variant="outlined">
                  <CardContent style={{ textAlign: "center" }}>
                    <img src={PartSale} className="img-fluid" />

                    <Button
                      size="medium"
                      style={{ marginTop: "1vh" }}
                      variant="outlined"
                      onClick={handleOpenPartySale}
                    >
                      Party Sale
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </div>
          </DialogContent>
          {/* <DialogActions>
            <Button onClick={handleClose} color="error" variant="outlined">
              Close
            </Button>
          </DialogActions> */}
        </Box>
      </Dialog>
    </>
  );
}

export default SelectSaleTypeModal;
