import {
  Breadcrumbs,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import { apiKey, fetch_vendor } from "../../../Api";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import CustomToolbar from "../../../Components/CustomToolbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateVendor from "./CreateVendor";
import DeleteVendor from "../../../Messages/DeleteVendor";
import EditVendor from "./EditVendor";
import { IoMdArrowRoundBack } from "react-icons/io";
import Box from "@mui/material/Box";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function AllVendor() {
  const [openvendor, setVendorOpen] = useState(false);
  const handleOpenVendor = () => setVendorOpen(true);
  const handleCloseVendor = () => setVendorOpen(false);

  const [openEditVendor, setEditVendorOpen] = useState(false);
  const handleOpenEditVendor = () => setEditVendorOpen(true);
  const handleCloseEditVendor = () => setEditVendorOpen(false);

  const [allvendors, setAllVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);

  const [selectedVendor, setSelectedVendor] = useState(null);
  const [openDetailModal, setOpenDetailModal] = useState(false);

  const [selectedEditVendorDetail, setSelectedEditVendorDetail] = useState();

  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenDetailModal = (vendor) => {
    setSelectedVendor(vendor);
    setOpenDetailModal(true);
  };

  const handleCloseDetailModal = () => {
    setSelectedVendor(null);
    setOpenDetailModal(false);
  };

  const navigate = useNavigate();

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handleEdit = (vendor) => {
    // console.log(vendor);
    setSelectedEditVendorDetail(vendor);
    handleOpenEditVendor();
  };

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page: parseInt(page),
      per_page: parseInt(pageSize),
      search: searchquery,
    };

    setLoading(true); // Start loading before the request

    axios
      .get(fetch_vendor, {
        params: data,
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.vendors);

        if (response.data && response.data.vendors) {
          //   console.log(response.data);
          setAllVendors(response.data.vendors.reverse());
          setTotalPages(response.data.num_pages);
        } else {
          console.log("Unexpected response structure:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching vendors:", error.response.data);
        setError(error);
      })
      .finally(() => {
        setLoading(false); // Stop loading regardless of success or error
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  // To navigate to the Upload Vendor
  const navigateToUploadVendor = () => {
    navigate("/upload-vendors");
  };

  useEffect(() => {
    if (searchquery) {
      if (searchquery.length > 2) {
        fetchData();
      }
    } else {
      fetchData();
    }
  }, [currentPage, pageSize, searchquery, filterModel]);

  // To delete the Vendors
  const [pks, setPks] = useState([]);
  const handleDeleteVendor = () => {
    const selectedPks = [];
    const ourDiv = document.querySelector(".all-vendors");
    const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
    let loopCount = 0;
    Array.from(allCheckBoxes).forEach((single_input) => {
      if (single_input.checked) {
        loopCount++;
        if (loopCount > 1) {
          const cur_row = single_input.closest(".MuiDataGrid-row");
          const third_child = cur_row.children[2];
          const selected_pk = third_child.innerHTML;
          selectedPks.push(selected_pk);
        }
      }
    });

    setPks(selectedPks);

    if (selectedPks.length === 0) {
      toast.error("No Vendors selected, please choose items to continue!");
      return;
    } else {
      setOpenDelete(true);
    }
  };

  // To show the Vendors in the Table
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    { field: "name", headerName: "Name", width: 250 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "phone", headerName: "Phone", width: 150 },
    {
      field: "address",
      headerName: "Address",
      width: 400,
      renderCell: (params) => (
        <span>
          {params.row.state} ,{params.row.address} {params.row.city}{" "}
          {params.row.pincode}
        </span>
      ),
    },
    { field: "gst_no", headerName: "GST Number", width: 200 },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => (
        <>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={() => handleOpenDetailModal(params.row)}
          >
            View
          </Button>
          <Button
            color="error"
            variant="outlined"
            size="small"
            onClick={() => handleEdit(params.row)}
            style={{ marginLeft: "5px" }}
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  // Example function to determine row class based on deletable value
  const getRowClassName = (params) => {
    // if (params.row.deletable === 0) {
    //     return 'not-deletable';
    // }
    // if (params.row.deletable === 1) {
    //     return 'success';
    // }
    return "";
  };

  return (
    <>
      <ToastContainer />

      <DeleteVendor
        selectedPks={pks}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <CreateVendor
        openvendor={openvendor}
        handleOpenVendor={handleOpenVendor}
        handleCloseVendor={handleCloseVendor}
        fetchVendors={fetchData}
      />

      <EditVendor
        openvendor={openEditVendor}
        handleCloseVendor={handleCloseEditVendor}
        selectedEditVendorDetail={selectedEditVendorDetail}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Vendors</Typography>
              <Typography sx={{ color: "text.primary" }}>
                All Vendors
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  onClick={handleOpenVendor}
                  size="small"
                >
                  Add Vendor
                </Button>

                <Button
                  variant="outlined"
                  size="small"
                  // onClick={uploadVendors}
                  // onClick={handleOpenModal}
                  onClick={navigateToUploadVendor} // Open the modal
                >
                  Upload Vendors
                </Button>

                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleDeleteVendor}
                >
                  Delete Vendors
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
            className="all-vendors"
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              checkboxSelection
              disableRowSelectionOnClick
              rows={allvendors}
              columns={columns}
              filterMode="server"
              //   onFilterModelChange={handleFilterModelChange}
              getRowClassName={getRowClassName}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelection) =>
                setSelectionModel(newSelection)
              }
              isRowSelectable={(params) => params.row.deletable !== 0}
            />
          </div>

          {/* Detail Modal */}
          {/* <Dialog open={openDetailModal} onClose={handleCloseDetailModal}>
            <Box sx={style}>
              <DialogTitle>Vendor Details</DialogTitle>
              <DialogContent>
                {selectedVendor && (
                  <div>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Name:</strong>
                            </td>
                            <td>{selectedVendor.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email:</strong>
                            </td>
                            <td>{selectedVendor.email}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Phone:</strong>
                            </td>
                            <td>{selectedVendor.phone}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Alternative Phone:</strong>
                            </td>
                            <td>{selectedVendor.alternative_phone || "N/A"}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Country Code:</strong>
                            </td>
                            <td>{selectedVendor.country_code}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Address:</strong>
                            </td>
                            <td>{selectedVendor.address}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>City:</strong>
                            </td>
                            <td>{selectedVendor.city}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>State:</strong>
                            </td>
                            <td>{selectedVendor.state}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Country:</strong>
                            </td>
                            <td>{selectedVendor.country}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Pincode:</strong>
                            </td>
                            <td>{selectedVendor.pincode}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Contact Name:</strong>
                            </td>
                            <td>{selectedVendor.contact_name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>GST Number:</strong>
                            </td>
                            <td>{selectedVendor.gst_no}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseDetailModal}
                  variant="outlined"
                  color="error"
                >
                  Close
                </Button>
              </DialogActions>
            </Box>
          </Dialog> */}

          <Dialog open={openDetailModal} onClose={handleCloseDetailModal}>
            <Box sx={style} className="p-2">
              <div className="row m-0 align-items-center">
                <div className="col-md-6">
                  <DialogTitle className="px-3 py-0">
                    Vendor Details
                  </DialogTitle>
                </div>
                <div className="col-md-6">
                  <DialogActions>
                    <Button
                      onClick={handleCloseDetailModal}
                      variant="outlined"
                      color="error"
                    >
                      Close
                    </Button>
                  </DialogActions>
                </div>
              </div>
              <Divider />

              <DialogContent>
                {selectedVendor && (
                  <>
                    <div className="row vendor-detail">
                      <div className="col-md-4">
                        <h3 className="mb-0">{selectedVendor.name}</h3>
                        <div className="info">
                          <small>{selectedVendor.contact_name}</small>
                          <small>
                            {selectedVendor.phone}{" "}
                            {selectedVendor.alternative_phone &&
                              `, ${selectedVendor.alternative_phone}`}
                          </small>
                          <small>{selectedVendor.email}</small>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                      <div className="col-md-7 text-end">
                        <p className="mb-2">
                          <strong>Address:</strong>
                          {(selectedVendor.address &&
                            ` ${selectedVendor.address}`) ||
                            " N/A"}
                          {selectedVendor.pincode &&
                            ` - ${selectedVendor.pincode}`}
                          {selectedVendor.city && `, ${selectedVendor.city}`}
                          {selectedVendor.state && `, ${selectedVendor.state}`}
                          {selectedVendor.country &&
                            `, ${selectedVendor.country}`}
                          {selectedVendor.country_code &&
                            ` ${selectedVendor.country_code}`}
                        </p>
                        <p>
                          <strong>GST No : </strong>{" "}
                          {selectedVendor.gst_no || " N/A"}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </DialogContent>
            </Box>
          </Dialog>
        </div>
      </div>
    </>
  );
}

export default AllVendor;
