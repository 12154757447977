import React, { useState } from "react";
import axios from "axios";
import { apiKey, loginURL } from "../Api";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Alert } from "react-bootstrap";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    setLoading(true);

    setError("");

    const headers = {
      "content-type": "application/json",
      "API-Key": apiKey,
    };

    const payload = {
      email: email,
      password: password,
    };

    try {
      const response = await axios.post(loginURL, payload, { headers });
      // console.log(response);
      if (response.data.status === 1) {
        const userDetails = {
          token: response.data.user.token,
          email: email,
          name: response.data.user.name,
          user_pk: response.data.user.user_pk,
          primary_warehouse_pk: response.data.user.primary_warehouse_pk || "",
        };
        localStorage.setItem("ktt_users", JSON.stringify(userDetails));
        setLoading(false);
        navigate("/");
        setTimeout(() => {
          toast.success("Logged In Successfully!");
        }, 1500);
      } else {
        setError(response.data.message);
        setLoading(false);
        setTimeout(() => {
          setError("");
        }, 4000);
      }
    } catch (error) {
      setError("An error occurred during signup. Please try again.");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 5000);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-scroller">
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <div className="content-wrapper d-flex align-items-center auth">
            <div className="row flex-grow">
              <div className="col-lg-4 mx-auto">
                <div className="auth-form-light text-left p-5">
                  <div className="brand-logo">
                    <h1 style={{ color: "#3b579d", fontWeight: "900" }}>
                      KuberTurboTech
                    </h1>
                    {/* <img src="../../assets/images/logo-dark.svg" /> */}
                  </div>
                  <h4>Hello! let's get started</h4>
                  <h6 className="font-weight-light">Sign in to continue.</h6>
                  <form className="pt-3" onSubmit={handleSignup}>
                    {error ? (
                      <>
                        <Alert variant="danger">{error}</Alert>
                      </>
                    ) : null}
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Username"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="mt-3">
                      {loading ? (
                        <>
                          <button
                            disabled
                            style={{ letterSpacing: "0.9px" }}
                            className="btn d-grid btn-dark btn-lg font-weight-medium auth-form-btn w-100"
                          >
                            AUTHENTICATING...
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn d-grid btn-dark btn-lg font-weight-medium auth-form-btn w-100"
                          >
                            SIGN IN
                          </button>
                        </>
                      )}
                    </div>
                    {/* <div className="my-5 d-flex justify-content-between align-items-center">
                      <a href="#" className="auth-link text-black">
                        Forgot password?
                      </a>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
