import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { delete_products, apiKey } from "../Api";
import Success from "./Success";
import Error from "./Error";
import { FaTrashCan } from "react-icons/fa6";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function Delete({ openDelete, setOpenDelete, selectedSKUs, fetchData }) {
  const [uniqueNumber, setUniqueNumber] = useState(null);
  const [timer, setTimer] = useState(60);
  const otpRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  useEffect(() => {
    const generateUniqueNumber = () => {
      const newNumber = Math.floor(1000 + Math.random() * 9000); // Generate a 4-digit number
      setUniqueNumber(newNumber);
    };

    generateUniqueNumber(); // Generate number on component mount
  }, []); // Empty dependency array ensures this runs once on mount

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          setTimer(60); // Reset timer to 60 seconds
          setUniqueNumber(Math.floor(1000 + Math.random() * 9000)); // Generate a new number
          return 60; // Ensure timer is reset to 60 seconds
        } else {
          return prevTimer - 1; // Decrement the timer
        }
      });
    }, 1000); // Update timer every second

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [timer]); // Depend on timer to keep interval running

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);

  const handleDelete = () => {
    const enteredOtp = otp.join("");
    if (enteredOtp == uniqueNumber) {
      axios
        .delete(delete_products, {
          headers: {
            "API-Key": apiKey,
          },
          data: {
            all_skus: selectedSKUs,
          },
        })
        .then(() => {
          setOpenSuccess(true);
          setSuccess(`Selected Products Deleted Successfully!`);
          setOpenDelete(false);
          fetchData();
          setOtp(["", "", "", ""]);
          setTimer(60); // Reset the timer
          setUniqueNumber(Math.floor(1000 + Math.random() * 9000));
        })
        .catch((error) => {
          setOpenError(true);
          setError(error.response.data.message || error.message);
          setTimeout(() => {
            setOtp(["", "", "", ""]);
            setTimer(60); // Reset the timer
            setUniqueNumber(Math.floor(1000 + Math.random() * 9000));
          }, 600);
        });
    } else {
      setOpenError(true);
      setError("Oops! OTP does not match");
      setTimeout(() => {
        setOtp(["", "", "", ""]);
        setTimer(60); // Reset the timer
        setUniqueNumber(Math.floor(1000 + Math.random() * 9000));
      }, 600);
    }
  };

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== "" && index < 3) {
      otpRefs[index + 1].current.focus();
    }
  };

  const handleClose = () => {
    setOpenDelete(false);
    setOtp(["", "", "", ""]);
    setTimer(60); // Reset the timer
    setUniqueNumber(Math.floor(1000 + Math.random() * 9000)); // Generate a new unique number
  };
  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Dialog open={openDelete} onClose={handleClose}>
        <Box sx={style}>
          <DialogTitle style={{ color: "red" }}>
            <FaTrashCan style={{ marginTop: "-6px" }} /> Delete
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure? You won't be able to revert this!
              <div
                className="card p-3 my-3"
                style={{ border: "1px solid #e3e3e3", borderRadius: "3px" }}
              >
                <div className="row">
                  <div className="col-lg-6">
                    <h3
                      style={{
                        letterSpacing: "5px",
                        textAlign: "center",
                        marginTop: "6px",
                        alignItems: "center",
                        fontSize: "30px",
                      }}
                    >
                      {uniqueNumber !== null ? (
                        <span style={{ color: "black" }}>{uniqueNumber}</span>
                      ) : (
                        <span style={{ color: "grey" }}>Generating...</span>
                      )}

                      <span
                        style={{
                          fontSize: "12px",
                          color: "red",
                          marginLeft: "10px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        ({timer} seconds)
                      </span>
                    </h3>
                  </div>
                  <div className="col-lg-6 d-flex justify-content-between">
                    {otp.map((num, index) => (
                      <TextField
                        key={index}
                        inputRef={otpRefs[index]}
                        type="text"
                        size="small"
                        variant="outlined"
                        value={num}
                        onChange={(e) => handleChange(index, e.target.value)}
                        inputProps={{
                          maxLength: 1,
                          style: { textAlign: "center" },
                        }}
                        sx={{ mx: 0.5 }}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleDelete} variant="outlined" color="error">
              Confirm
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default Delete;
