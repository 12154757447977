import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  validateWarehouse,
  validateProduct,
  inventory_mapping,
} from "../../../Api";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import Quagga from "quagga";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BsUpcScan } from "react-icons/bs";
import Error from "../../../Messages/Error";
import Success from "../../../Messages/Success";
import soundEffect from "../../../Assets/sounds/beep.mp3";
import { debounce } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { Popup } from "semantic-ui-react";
import { IoAlertCircle } from "react-icons/io5";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IoMdArrowRoundBack } from "react-icons/io";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

const BarcodeScanner = () => {
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const webcamRef = useRef(null);

  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState(null);
  const [error2, setError2] = useState(null);

  const [openReallocate, setOpenReallocate] = useState(false);

  const [openchangeRack, setOpenChangeRack] = useState(false);

  const [openRemoveP, setOpenRemoveP] = useState(false);

  const [success, setSuccess] = useState("");
  const [openSuccess, setOpenSuccess] = useState(false);

  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const [scanData, setScanData] = useState("");

  const [scanData2, setScanData2] = useState("");

  const [warehouseId, setWarehouseId] = useState("");
  const [rack_pk, setRackPk] = useState("");
  const [scannedProducts, setScannedProducts] = useState([]);

  const [rackName, setRackName] = useState("");
  const [sku, setSku] = useState("");
  const [productDataa, setProductDataa] = useState([]);

  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

  const navigate = useNavigate();
  const inputRef = useRef(null);

  useEffect(() => {
    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }

      handleInputChange(scanData);
    };

    document.addEventListener("click", handleFocus);

    return () => {
      document.removeEventListener("click", handleFocus);
    };
  }, []);

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
  };

  const handleDetected = useCallback((result) => {
    const scannedCode = result.codeResult.code;
    setScanData(scannedCode);
  }, []);

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const isValidWarehouseId = (value) => {
    const regex = /^W\d+-R\d+-R\d+$/;
    return regex.test(value);
  };

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const handleInputChange = (scanData) => {
    const newValue = scanData;
    const checkValue = newValue[0];

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    if (newValue.length > 5) {
      if (checkValue == "W") {
        if (isValidWarehouseId(newValue)) {
          setScanData2(newValue);

          if (warehouseId === newValue) {
            setError("Warehouse already selected!");
            setOpenError(true);
            setScanData2("");
            playSound();
            setOpenChangeRack(false);
            return;
          }

          if (warehouseId && warehouseId !== newValue) {
            setOpenChangeRack(true);
            return;
          }

          setWarehouseId(newValue);
          fetchWarehouseData(newValue);
          setIsCameraOpen(false);

          playSound();
        } else {
          // If the warehouse ID is not valid
          playSound();
        }
      } else {
        if (isValidProductSku(newValue)) {
          let seriesExists = false;

          for (const product of scannedProducts) {
            if (
              product.sku === newValue.split("_")[0] &&
              product.additional_info?.includes(
                parseInt(newValue.split("_")[1], 10)
              )
            ) {
              seriesExists = true;
              break;
            }
          }

          if (seriesExists) {
            setSkuToRemove(newValue.split("_")[0]);
            setAdditionalInfoToRemove(parseInt(newValue.split("_")[1], 10));
            setOpenRemoveP(true);

            return;
          } else {
            fetchProductData(newValue);
            setIsCameraOpen(false);
            playSound();
          }
        } else {
          playSound();
        }
      }
    }
    setScanData("");
  };

  const handleChangeRack = () => {
    // console.log(scanData2, "scandata 2");
    fetchWarehouseData(scanData2);
    setWarehouseId(scanData2);
    setOpenChangeRack(false);
    setScanData2("");
    setScanData("");
  };

  const fetchWarehouseData = useCallback(
    debounce((pk) => {
      axios
        .get(validateWarehouse, {
          params: {
            scanned_data: pk,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          setRackName(response.data.rack);
          setRackPk(response.data.rack.id);
        })
        .catch((error) => {
          setOpenError(true);
          setError("Warehouse ID Invalid. Scan Again!");
        });
    }, 600),
    []
  );

  const fetchProductData = useCallback(
    debounce((sku) => {
      axios
        .get(validateProduct, {
          params: {
            product_value: sku,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          const productData = response.data.product;
          addScannedProduct(sku, productData);
          setScanData("");
        })
        .catch((error) => {
          let errorstatus = error.response.data.status;
          const productData = error.response.data.product;
          if (errorstatus == 2) {
            setOpenReallocate(true);
            setError(
              `${error.response.data.message}. Do you want to Re-allocate ?`
            );
            setError2("Product already allocated!");
            setProductDataa(productData);
            setSku(sku);
            setScanData("");
          } else {
            setOpenError(true);
            setError(error.response.data.message);
            setScanData("");
          }
        });
    }, 600),
    []
  );

  const handleReallocate = () => {
    addScannedProduct(sku, productDataa);
    // toast.success("Product Added Successfully!");
    setOpenReallocate(false);
    setTimeout(() => {
      setSku("");
      setProductDataa([]);
    }, 500);
  };

  useEffect(() => {
    return () => {
      fetchWarehouseData.cancel();
      fetchProductData.cancel();
    };
  }, [fetchWarehouseData, fetchProductData]);

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const additionalInfo = parseInt(additionalInfoString, 10);

      if (!productData) {
        toast.error("Product not found for the scanned SKU.");
        return;
      }

      setScannedProducts(
        (prev) => {
          const existingProduct = prev.find((item) => item.sku === baseSku);

          if (existingProduct) {
            if (existingProduct.additional_info?.includes(additionalInfo)) {
              setSkuToRemove(baseSku);
              setAdditionalInfoToRemove(additionalInfo);
              setOpenRemoveP(true);

              return prev;
            }

            return prev.map((item) =>
              item.sku === baseSku
                ? {
                    ...item,
                    qty: item.qty + 1,
                    additional_info: [
                      ...(item.additional_info || []),
                      additionalInfo,
                    ],
                  }
                : item
            );
          } else {
            return [
              ...prev,
              {
                sku: baseSku,
                product_name: productData.product_name,
                product_sku: productData.product_sku,
                qty: 1,
                additional_info: [additionalInfo],
              },
            ];
          }
        },
        (notAdded) => {
          if (notAdded) {
            alert("Product was scanned but not added. Try Again!");
          }
        }
      );
    }, 300),
    [] // dependencies
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"],
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 700);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected]);

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const handleCloseAllocate = () => {
    setOpenReallocate(false);
    setScanData("");
    setTimeout(() => {
      setSku("");
      setProductDataa([]);
    }, 1000);
  };

  const handleCloseRemove = () => {
    setOpenRemoveP(false);
    setScanData("");
  };

  const handleCloseChangeRack = () => {
    setOpenChangeRack(false);
    setScanData("");
  };

  const handleRemoveProduct = () => {
    setScannedProducts((prev) => {
      return prev
        .map((item) => {
          if (item.sku === skuToRemove) {
            return {
              ...item,
              additional_info: item.additional_info.filter(
                (info) => info !== additionalInfoToRemove
              ),
              qty: item.additional_info.length === 1 ? 0 : item.qty,
            };
          }
          return item;
        })
        .filter((item) => item.qty > 0);
    });
    setOpenRemoveP(false);
    setSkuToRemove("");
    setAdditionalInfoToRemove(null);
  };

  // console.log(scannedProducts);
  const handleSubmit = () => {
    const finalData = {
      user_name: userDetails.name,
      rack: parseInt(rack_pk),
      products: scannedProducts,
    };

    if (!rack_pk) {
      setError("Please Scan Rack. Rack cannot be empty!");
      setOpenError(true);
      return;
    } else if (scannedProducts.length === 0) {
      setError("Please Scan Products. Products cannot be empty!");
      setOpenError(true);
      return;
    }

    try {
      axios
        .post(inventory_mapping, finalData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status === 1) {
            setOpenSuccess(true);
            setSuccess("Products Allocated Successfully!");
            setScannedProducts([]);
            setWarehouseId("");
            setRackName("");
          } else {
            setError(response.data.message);
            setOpenError(true);
          }
        })
        .catch((error) => {
          setOpenError(true);
          setError(error.response.data.message);
        });
    } catch (error) {
      console.log("error While Creating Draft :", error);
    }
  };

  const handleResetOperation = () => {
    setOpenSuccess(true);
    setSuccess("Operation Reset Successfully!");
    setScannedProducts([]);
    setWarehouseId("");
    setRackName("");
  };

  const handleCancelOperation = () => {
    setScannedProducts([]);
    setWarehouseId("");
    setRackName("");
    navigate("/unallocated-products");
  };

  const [speedopen, setSpeedOpen] = useState(false);
  const handleSpeedOpen = () => setSpeedOpen(true);
  const handleSpeedClose = () => setSpeedOpen(false);

  const actions = [
    { icon: <SaveIcon onClick={handleSubmit} />, name: "Save" },
    { icon: <RestartAltIcon onClick={handleResetOperation} />, name: "Reset" },
    {
      icon: <HighlightOffIcon onClick={handleCancelOperation} />,
      name: "Cancel",
    },
  ];
  return (
    <>
      <ToastContainer />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/unallocated-products")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Products</Typography>
              <Typography color="inherit">Unallocated Products</Typography>
              <Typography sx={{ color: "text.primary" }}>Allocation</Typography>
            </Breadcrumbs>
          </div>

          <div className="row">
            <div className="col-lg-3 my-2">
              <div className="card">
                <div className="card-body" style={{ padding: "10px" }}>
                  <div className="row">
                    {isCameraOpen ? (
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        width={1920}
                        height={220}
                        screenshotFormat="image/jpeg"
                        videoConstraints={{
                          facingMode: "environment",

                          border: "none",
                        }}
                        style={{
                          marginBottom: "12px",
                          border: "3px dashed green",
                          padding: "0",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    ) : null}

                    <h4 style={{ color: "grey", fontSize: "14px" }}>
                      SCANNED RACK
                    </h4>
                    <hr />
                    <h5
                      style={{
                        color: "grey",
                        fontWeight: "normal",
                        marginBottom: "15px",
                      }}
                    >
                      {rackName ? (
                        <b style={{ color: "red" }}>
                          {rackName.warehouse_name} - {rackName.row_name} -{" "}
                          {rackName.rack_name}
                        </b>
                      ) : (
                        <span style={{ color: "#e3e3e3" }}>--</span>
                      )}
                    </h5>
                    <span style={{ fontSize: "12px" }}>
                      Rack ID:{" "}
                      {warehouseId ? (
                        <b style={{ color: "grey" }}>{warehouseId}</b>
                      ) : (
                        <span style={{ color: "#e3e3e3" }}>--</span>
                      )}
                    </span>
                    {isCameraOpen ? (
                      <>
                        <Button
                          size="small"
                          id="mobile_scan"
                          onClick={handleCloseCamera}
                          color="error"
                          variant="outlined"
                          style={{
                            marginTop: "5px",
                            maxWidth: "150px",
                            position: "absolute",

                            bottom: "5px",
                            right: "5px",
                          }}
                        >
                          Close
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          size="small"
                          id="mobile_scan"
                          onClick={handleOpenCamera}
                          variant="outlined"
                          style={{
                            marginTop: "5px",
                            maxWidth: "150px",
                            position: "absolute",

                            bottom: "5px",
                            right: "5px",
                          }}
                        >
                          Change Rack
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 my-2">
              <div className="card">
                <div className="card-header">
                  <h5 style={{ marginTop: "6px" }}>Scan Products</h5>
                  <input
                    autoFocus
                    value={scanData}
                    ref={inputRef}
                    style={{
                      border: "1px solid #d1d1d1",
                      borderRadius: "4px",
                      maxWidth: "150px",
                      fontSize: "15px",
                      color: "grey",
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      padding: "5px",
                    }}
                    onChange={(e) => setScanData(e.target.value)}
                  />
                </div>

                <div className="card-body" style={{ padding: "0px" }}>
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 300 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell>SKU</TableCell>
                          <TableCell align="left">Product</TableCell>
                          <TableCell align="right">Qty</TableCell>
                          <TableCell align="left">Series</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {scannedProducts.length > 0 ? (
                          <>
                            {scannedProducts &&
                              scannedProducts.map((product) => (
                                <TableRow key={product.product_sku}>
                                  <TableCell component="th" scope="row">
                                    {product.product_sku}
                                  </TableCell>
                                  <TableCell align="left">
                                    {product.product_name}
                                  </TableCell>
                                  <TableCell align="right">
                                    <b style={{ color: "red" }}>
                                      {product.additional_info.length}
                                    </b>
                                  </TableCell>
                                  <TableCell align="left">
                                    <Popup
                                      style={{
                                        backgroundColor: "white",
                                        border: "1px solid grey",
                                        borderRadius: "5px",
                                        color: "black",
                                        padding: "5px",
                                        marginLeft: "5px",
                                        cursor: "pointer",
                                      }}
                                      on="click"
                                      pinned
                                      content={
                                        product.additional_info &&
                                        product.additional_info.join(", ")
                                      }
                                      trigger={
                                        <IoAlertCircle
                                          style={{
                                            cursor: "pointer",
                                            marginLeft: "5px",
                                            fontSize: "18px",
                                            color: "cornflowerblue",
                                          }}
                                        />
                                      }
                                    />

                                    {/* {product.additional_info &&
                                      product.additional_info.join(", ")} */}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </>
                        ) : (
                          <>
                            <TableRow>
                              <TableCell
                                colSpan={4}
                                style={{
                                  textAlign: "center",
                                  color: "grey",
                                  fontSize: "11px",
                                }}
                              >
                                No Products Found
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
          <audio id="scanSound" src={soundEffect} />
          {isCameraOpen ? (
            <>
              <Button
                variant="contained"
                color="error"
                id="mobile_scan"
                onClick={handleCloseCamera}
                style={{
                  position: "fixed",
                  bottom: "15px",
                  right: "15px",
                  padding: "10px 10px",
                }}
              >
                <BsUpcScan style={{ fontSize: "35px", marginRight: "5px" }} />{" "}
                Close
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                id="mobile_scan"
                onClick={handleOpenCamera}
                style={{
                  position: "fixed",
                  bottom: "15px",
                  right: "15px",
                  padding: "10px 10px",
                }}
              >
                <BsUpcScan style={{ fontSize: "35px", marginRight: "5px" }} />
                Scan
              </Button>
            </>
          )}

          <Box
            id="mobile_scan"
            style={{ position: "fixed", bottom: "0px" }}
            sx={{
              height: 320,
              transform: "translateZ(0px)",
              flexGrow: 1,
            }}
          >
            <SpeedDial
              ariaLabel="SpeedDial controlled open example"
              sx={{ position: "absolute", bottom: 15, left: 5 }}
              icon={<SpeedDialIcon />}
              onClose={handleSpeedClose}
              onOpen={handleSpeedOpen}
              open={speedopen}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={handleSpeedClose}
                />
              ))}
            </SpeedDial>
          </Box>

          <div id="desktop_scan">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit}
              style={{ float: "right", marginLeft: "5px" }}
            >
              Save Inventory
            </Button>
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleResetOperation}
              style={{ float: "right", marginLeft: "5px" }}
            >
              Reset
            </Button>

            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={handleCancelOperation}
              style={{ float: "right" }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>

      {/* 
      //Add allocated Product */}

      <Dialog
        open={openReallocate}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseAllocate();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product already allocated!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {error}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseAllocate}
              color="error"
            >
              Cancel
            </Button>
            <Button size="small" variant="outlined" onClick={handleReallocate}>
              Reallocate
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* 
      //Remove Product */}

      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>

      {/* 
      //Change RAck */}

      <Dialog
        open={openchangeRack}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Want to change Rack?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Are you sure you want to change rack?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button size="small" variant="outlined" onClick={handleChangeRack}>
              Change Rack
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseChangeRack}
              color="error"
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default BarcodeScanner;
