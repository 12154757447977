import React, { useState } from "react";
import axios from "axios";
import { Button, TextField } from "@mui/material";
import { edit_customer_address } from "../../../Api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { IoClose } from "react-icons/io5";

function EditCustomerAddress({
  address,
  apiKey,
  closeModal,
  fetchCustomerAddresses,
}) {
  const [contactName, setContactName] = useState(address.contact_name);
  const [contactNo, setContactNo] = useState(address.contact_no);
  const [addressDetails, setAddressDetails] = useState(address.address);
  const [city, setCity] = useState(address.city);
  const [state, setState] = useState(address.state);
  const [country, setCountry] = useState(address.country);
  const [pincode, setPincode] = useState(address.pincode);

  const handleUpdateAddress = () => {
    const updatedAddress = {
      address_pk: address.id,
      contact_name: contactName,
      contact_no: contactNo,
      address: addressDetails,
      city,
      state,
      country,
      pincode,
      customer_ref: address.customer_ref,
    };

    console.log(updatedAddress);
    axios
      .post(edit_customer_address, updatedAddress, {
        headers: {
          "API-key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          console.log(response.data);
          toast.success(response.data.message);
          fetchCustomerAddresses();
          closeModal();
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log("Error updating customer address:", error);
      });
  };

  return (
    <>
      <ToastContainer />
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Customers</Typography>

          <Typography sx={{ color: "text.primary" }}>
            Update Customer
          </Typography>
        </Breadcrumbs>

        <IoClose className="closeModalBtn" onClick={closeModal} />
        <div className="row my-3">
          <div className="col-md-6 my-2">
            <TextField
              label="Contact Name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              fullWidth
              size="small"
            />
          </div>
          <div className="col-md-6 my-2">
            <TextField
              label="Contact No"
              value={contactNo}
              onChange={(e) => setContactNo(e.target.value)}
              fullWidth
              size="small"
            />
          </div>
          <div className="col-md-12 my-2">
            <TextField
              label="Address"
              rows={3}
              multiline
              value={addressDetails}
              onChange={(e) => setAddressDetails(e.target.value)}
              fullWidth
              size="small"
            />
          </div>
          <div className="col-md-3 my-2">
            <TextField
              label="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              fullWidth
              size="small"
            />
          </div>
          <div className="col-md-3 my-2">
            <TextField
              label="State"
              value={state}
              onChange={(e) => setState(e.target.value)}
              fullWidth
              size="small"
            />
          </div>

          <div className="col-md-3 my-2">
            <TextField
              label="Pincode"
              value={pincode}
              onChange={(e) => setPincode(e.target.value)}
              fullWidth
              size="small"
            />
          </div>
          <div className="col-md-3 my-2">
            <TextField
              label="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              fullWidth
              size="small"
            />
          </div>
          <div className="col-md-12 text-end" style={{ marginTop: "10px" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleUpdateAddress}
            >
              Update Address
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCustomerAddress;
