import React, { useState } from "react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Breadcrumbs from "@mui/material/Breadcrumbs";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "600px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AddWarehouseModal({ open, handleClose, handleAddWarehouse }) {
  const [warehouse, setWarehouse] = useState("");
  const [address, setAddress] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    handleAddWarehouse(warehouse, address);
    setWarehouse("");
    setAddress("");
  };

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            overflowX: "hidden",
          }}
        >
          <div className="card-body">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              {/* <Typography color="inherit">Customer Details</Typography> */}
              <Typography sx={{ color: "text.primary" }}>
                Add Warehouse
              </Typography>
            </Breadcrumbs>

            <div className="my-2">
              <TextField
                id="outlined-warehouse-name"
                label="Warehouse Name"
                value={warehouse}
                size="small"
                required
                fullWidth
                onChange={(e) => setWarehouse(e.target.value)}
              />
              <TextField
                style={{ marginTop: "10px" }}
                id="outlined-address"
                label="Address"
                multiline
                rows={4}
                value={address}
                size="small"
                fullWidth
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end mt-3">
          <Button
            style={{ marginTop: "8px" }}
            variant="outlined"
            color="error"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            style={{ marginTop: "8px", marginLeft: "2px" }}
            variant="outlined"
            color="primary"
            onClick={onSubmit}
            // disabled={
            //   Boolean(error) ||
            //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
            // }
          >
            Add Warehouse
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default AddWarehouseModal;
