import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { apiKey, TransferHistory } from "../../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Delete from "../../../Messages/Delete";
import CustomToolbar from "../../../Components/CustomToolbar";
import Error from "../../../Messages/Error";
import { IoMdArrowRoundBack } from "react-icons/io";

function StockTransfer() {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, searchquery, filterModel]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,

      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(TransferHistory, data, { headers })
      .then((response) => {
        console.log(response);
        setWarehouses(response.data.data);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response.data.message);
        setOpenError(true);
        console.log(error.response.data.message);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handleEditClick = (pk) => {
    navigate(`/transfer-history-detail/${pk}`);
  };

  const [skus, setSKus] = useState([]);

  const columns = [
    {
      field: "ID",
      renderCell: (params) =>
        "#TR" + params.row.id.toString().padStart(5, "0") || "N/A",
      headerName: "ID",
      width: 150,
    },

    {
      field: "date",
      renderCell: (params) => params.row.date || "N/A",
      headerName: "Transfer Date",
      width: 190,
    },

    {
      field: "note",
      renderCell: (params) => params.row.note || "N/A",
      headerName: "Notes",
      width: 350,
    },
    {
      field: "ware_house",
      renderCell: (params) => params.row.source_warehouse.ware_house || "N/A",
      headerName: "Source",
      width: 190,
    },

    {
      field: "target_warehouse",
      renderCell: (params) => params.row.target_warehouse.ware_house || "N/A",
      headerName: "Target",
      width: 190,
    },

    {
      field: "total",
      renderCell: (params) =>
        params.row.total_products + "(" + params.row.total_quantity + ")" ||
        "N/A",
      headerName: "Products",
      width: 100,
    },
    { field: "user_name", headerName: "Created by", width: 200 },

    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="error"
          size="small"
          onClick={() => handleEditClick(params.row.id)}
        >
          View
        </Button>
      ),
    },
  ];

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Delete
        selectedSKUs={skus}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Stock Transfer
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add-stock-transfer")}
                >
                  New Stock Transfer
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              disableRowSelectionOnClick
              rows={warehouses}
              columns={columns}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StockTransfer;
