import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Breadcrumbs,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as XLSX from "xlsx";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@mui/material";
import { apiKey, import_vendors } from "../../../Api";
import { FaTrashAlt } from "react-icons/fa";
import MenuItem from "@mui/material/MenuItem";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import { LiaSitemapSolid } from "react-icons/lia";
import Select from "@mui/material/Select";
import { IoMdArrowRoundBack } from "react-icons/io";

function UploadVendor() {
  const navigate = useNavigate();

  const [headers, setHeaders] = useState([]);
  const [fileName, setFileName] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoader] = useState(false);
  const [existingGstNumbers, setExistingGstNumbers] = useState([]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [alternative_phone, setAlternative_phone] = useState("");
  const [countrycode, setCountryCode] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [contactName, setContactName] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [gst, setGST] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    if (!file) return;

    if (
      ![
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ].includes(file.type)
    ) {
      alert("Only .xlsx or .xls file format are allowed");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      jsonData = jsonData.map((row) =>
        row.map((cell) => {
          if (typeof cell === "string") {
            return cell.replace(/\s{2,}/g, "-"); // Replace sequences of more than 2 spaces with commas
          }
          return cell;
        })
      );

      setHeaders(jsonData[0]);
      setData(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleMappingChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const getColumnIndex = (header) => headers.indexOf(header);
  useEffect(() => {
    const defaultName = headers.find((header) => {
      try {
        return header.toLowerCase().includes("name");
      } catch {
        return false;
      }
    });

    const defaultPhone = headers.find((header) => {
      try {
        return header.toLowerCase().includes("phone");
      } catch {
        return false;
      }
    });

    const defaultEmail = headers.find((header) => {
      try {
        return header.toLowerCase().includes("email");
      } catch {
        return false;
      }
    });

    const defaultAltNo = headers.find((header) => {
      try {
        return header.toLowerCase().includes("alt number");
      } catch {
        return false;
      }
    });

    const defaultCountryCode = headers.find((header) => {
      try {
        return header.toLowerCase().includes("country code");
      } catch {
        return false;
      }
    });

    const defaultAddress = headers.find((header) => {
      try {
        return header.toLowerCase().includes("address");
      } catch {
        return false;
      }
    });

    const defaultCity = headers.find((header) => {
      try {
        return header.toLowerCase().includes("city");
      } catch {
        return false;
      }
    });

    const defaultPincode = headers.find((header) => {
      try {
        return header.toLowerCase().includes("pincode");
      } catch {
        return false;
      }
    });

    const defaultState = headers.find((header) => {
      try {
        return header.toLowerCase().includes("state");
      } catch {
        return false;
      }
    });

    const defaultCountry = headers.find((header) => {
      try {
        return header.toLowerCase().includes("country");
      } catch {
        return false;
      }
    });

    const defaultContactName = headers.find((header) => {
      try {
        return header.toLowerCase().includes("contact name");
      } catch {
        return false;
      }
    });

    const defaultGST = headers.find((header) => {
      try {
        return header.toLowerCase().includes("gst");
      } catch {
        return false;
      }
    });

    if (defaultName) setName(defaultName);
    if (defaultPhone) setPhone(defaultPhone);
    if (defaultEmail) setEmail(defaultEmail);
    if (defaultAltNo) setAlternative_phone(defaultAltNo);
    if (defaultCountryCode) setCountryCode(defaultCountryCode);
    if (defaultAddress) setAddress(defaultAddress);
    if (defaultCity) setCity(defaultCity);
    if (defaultPincode) setPincode(defaultPincode);
    if (defaultState) setState(defaultState);
    if (defaultCountry) setCountry(defaultCountry);
    if (defaultContactName) setContactName(defaultContactName);
    if (defaultGST) setGST(defaultGST);
  }, [headers]);

  const handleDeleteRow = (index) => {
    const updatedData = data.filter((_, rowIndex) => rowIndex !== index);
    setData(updatedData);
    toast.success("Row deleted successfully!");
  };

  const handleSubmit = async () => {
    if (data && data.length === 0) {
      toast.error("No vendor data to upload.");
      return;
    }

    const previewData = data.slice(1).map((row, index) => {
      return {
        rowNumber: index + 2, // +2 for headers
        name: row[getColumnIndex(name)] || "",
        phone: row[getColumnIndex(phone)] || "",
        email: row[getColumnIndex(email)] || "",
        alternative_phone: row[getColumnIndex(alternative_phone)] || "",
        country_code: row[getColumnIndex(countrycode)] || "",
        address: row[getColumnIndex(address)] || "",
        city: row[getColumnIndex(city)] || "",
        state: row[getColumnIndex(state)] || "",
        country: row[getColumnIndex(country)] || "",
        pincode: row[getColumnIndex(pincode)] || "",
        contact_name: row[getColumnIndex(contactName)] || "",
        gst_no: row[getColumnIndex(gst)] || "",
      };
    });

    const formattedData = data.slice(1).map((row) => {
      return {
        name: row[getColumnIndex(name)] || "",
        phone: row[getColumnIndex(phone)] || "",
        email: row[getColumnIndex(email)] || "",
        alternative_phone: row[getColumnIndex(alternative_phone)] || "",
        country_code: row[getColumnIndex(countrycode)] || "",
        address: row[getColumnIndex(address)] || "",
        city: row[getColumnIndex(city)] || "",
        state: row[getColumnIndex(state)] || "",
        country: row[getColumnIndex(country)] || "",
        pincode: row[getColumnIndex(pincode)] || "",
        contact_name: row[getColumnIndex(contactName)] || "",
        gst_no: row[getColumnIndex(gst)] || "",
      };
    });

    const missingRows = previewData.filter((row) => !row.name);
    const rowNumbers = missingRows.map((row) => row.rowNumber).join(", ");

    if (missingRows.length > 0) {
      setError(`Please add vendor name for the following rows: ${rowNumbers}`);
      setOpenError(true);
      return;
    }

    try {
      const response = await axios.post(
        import_vendors,
        { vendors: formattedData },
        {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        }
      );

      if (response.data.status === 1) {
        // console.log("Upload Response: ", response.data);
        setOpenSuccess(true);
        setSuccess(response.data.message);
        navigate("/all-vendors");
      } else {
        setError(response.data);
        setOpenError(true);
      }
    } catch (error) {
      const existingGstNumbers = error.response.data.existing_gst_numbers || [];
      setExistingGstNumbers(error.response.data.existing_gst_numbers || []);

      const existingGstMessage =
        existingGstNumbers.length > 0
          ? `The following GST numbers already exist: ${existingGstNumbers.join(
              ", "
            )}`
          : error.response.data.message;

      setError(existingGstMessage || "Something went wrong");
      setOpenError(true);
    }
  };

  return (
    <>
      {/* {excelData && console.log("excelData :  : ", excelData)} */}

      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <ToastContainer />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all-vendors")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">All Vendors</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Upload Vendors
              </Typography>
            </Breadcrumbs>
          </div>

          <Button
            variant="contained"
            size="small"
            style={{ float: "right", marginTop: "-3.5vh" }}
            onClick={handleSubmit}
          >
            Import Vendors
          </Button>

          <div
            className="card my-2 p-2"
            style={{
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="row p-2">
              <div className="col-lg-3 col-md-3 bg-white">
                <div className="mb-3">
                  <input
                    className="form-control"
                    type="file"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                  />
                </div>

                <h5>Map Your Columns</h5>

                <div className="card p-2">
                  <div className="row my-2">
                    <div className="col-lg-6">Name</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={name}
                        onChange={handleMappingChange(setName)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">Email</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={email}
                        onChange={handleMappingChange(setEmail)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">Contact</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={phone}
                        onChange={handleMappingChange(setPhone)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">Contact Name</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={contactName}
                        onChange={handleMappingChange(setContactName)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">Alternate Contact</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={alternative_phone}
                        onChange={handleMappingChange(setAlternative_phone)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">Address</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={address}
                        onChange={handleMappingChange(setAddress)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">City</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={city}
                        onChange={handleMappingChange(setCity)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">Pincode</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={pincode}
                        onChange={handleMappingChange(setPincode)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">State</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={state}
                        onChange={handleMappingChange(setState)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">Country</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={country}
                        onChange={handleMappingChange(setCountry)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">Country Code</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={countrycode}
                        onChange={handleMappingChange(setCountryCode)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>

                  <div className="row my-1">
                    <div className="col-lg-6">GST</div>
                    <div className="col-lg-6">
                      <Select
                        fullWidth
                        size="small"
                        value={gst}
                        onChange={handleMappingChange(setGST)}
                      >
                        <MenuItem value="">--Select--</MenuItem>
                        {headers.map((header, index) => (
                          <MenuItem key={index} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-md-9 p-2">
                <div>
                  <h5>
                    <LiaSitemapSolid style={{ fontSize: "25px" }} /> Mapped Data
                    Preview
                  </h5>
                  <div class="table-wrapper" style={{ marginTop: "2px" }}>
                    <div class="responsive-table2">
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead className="table-head">
                            <TableRow>
                              <TableCell>#</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Email</TableCell>
                              <TableCell>Phone</TableCell>
                              <TableCell>Contact Name</TableCell>
                              <TableCell>Alternate Phone</TableCell>
                              <TableCell>Address</TableCell>
                              <TableCell>City</TableCell>
                              <TableCell>Pincode</TableCell>
                              <TableCell>State</TableCell>
                              <TableCell>Country</TableCell>
                              <TableCell>Code</TableCell>
                              <TableCell>GST Number</TableCell>
                              <TableCell>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data && data.length > 0 ? (
                              data.slice(1).map((row, rowIndex) => {
                                const isGstNumberExisting =
                                  existingGstNumbers.includes(
                                    row[getColumnIndex(gst)]
                                  );

                                return (
                                  <TableRow
                                    key={`selected-row-${rowIndex}`}
                                    style={{
                                      background: !row[getColumnIndex(name)]
                                        ? "#f8d7da" // Highlight for missing name
                                        : isGstNumberExisting
                                        ? "#ffeeba"
                                        : "transparent",
                                    }}
                                  >
                                    <TableCell>{rowIndex + 2}</TableCell>
                                    <TableCell style={{ minWidth: "120px" }}>
                                      {row[getColumnIndex(name)] || "-"}
                                    </TableCell>
                                    <TableCell>
                                      {row[getColumnIndex(email)] || "-"}
                                    </TableCell>
                                    <TableCell>
                                      {row[getColumnIndex(phone)] || "-"}
                                    </TableCell>
                                    <TableCell style={{ minWidth: "150px" }}>
                                      {row[getColumnIndex(contactName)] || "-"}
                                    </TableCell>
                                    <TableCell style={{ minWidth: "180px" }}>
                                      {row[getColumnIndex(alternative_phone)] ||
                                        "-"}
                                    </TableCell>
                                    <TableCell style={{ minWidth: "150px" }}>
                                      {row[getColumnIndex(address)] || "-"}
                                    </TableCell>
                                    <TableCell>
                                      {row[getColumnIndex(city)] || "-"}
                                    </TableCell>
                                    <TableCell>
                                      {row[getColumnIndex(pincode)] || "-"}
                                    </TableCell>
                                    <TableCell>
                                      {row[getColumnIndex(state)] || "-"}
                                    </TableCell>
                                    <TableCell style={{ minWidth: "100px" }}>
                                      {row[getColumnIndex(country)] || "-"}
                                    </TableCell>
                                    <TableCell>
                                      {row[getColumnIndex(countrycode)] || "-"}
                                    </TableCell>
                                    <TableCell style={{ minWidth: "170px" }}>
                                      {row[getColumnIndex(gst)] || "-"}
                                    </TableCell>
                                    <TableCell>
                                      <FaTrashAlt
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        className="delete-btn text-center"
                                        onClick={() =>
                                          handleDeleteRow(rowIndex + 1)
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })
                            ) : (
                              <TableRow>
                                <TableCell
                                  colSpan={14}
                                  style={{ textAlign: "center", color: "grey" }}
                                >
                                  No File Selected
                                </TableCell>
                              </TableRow>
                            )}
                            {/* );
                              })} */}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UploadVendor;
