import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { fetch_all_product, apiKey, fetchProductInven } from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ImportProducts from "./ImportProductsModal";
import Delete from "../../Messages/Delete";
import CustomToolbar from "../../Components/CustomToolbar";
import Error from "../../Messages/Error";
import AssignWare from "./ProductAllocations/Inventory_ProductsModal";
import { MdInventory } from "react-icons/md";
import { FaPencilAlt } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeriesModal from "./SeriesModal";
import { IoMdArrowRoundBack } from "react-icons/io";

function MasterProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [openImport, setOpenImport] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [openError, setOpenError] = useState(false);

  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 180000); // 3 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, [currentPage, pageSize, searchquery, filterModel]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      selectedBrands: [],
      selectedTypes: [],
      selectedStocks: [],
      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_all_product, data, { headers })
      .then((response) => {
        console.log(response.data.data.products);
        setProducts(response.data.data.products);
        setTotalPages(response.data.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const handleEditClick = (sku) => {
    navigate(`/update-product/${sku}`);
  };

  const [skus, setSKus] = useState([]);
  const handleDeleteClick = () => {
    const selectedSKUs = [];
    const ourDiv = document.querySelector(".all-master-products");
    const allCheckBoxes = ourDiv.querySelectorAll("input[type='checkbox']");
    let loopCount = 0;
    Array.from(allCheckBoxes).forEach((single_input) => {
      if (single_input.checked) {
        loopCount++;
        if (loopCount > 1) {
          const cur_row = single_input.closest(".MuiDataGrid-row");
          const third_child = cur_row.children[2];
          const selected_sk = third_child.innerHTML;
          selectedSKUs.push(selected_sk);
        }
      }
    });

    setSKus(selectedSKUs);

    if (selectedSKUs.length === 0) {
      setError("No products selected, please choose items to continue!");
      setOpenError(true);
      return;
    } else {
      setOpenDelete(true);
    }

    // Perform the delete action
  };

  const [inventory, setInven] = useState([]);
  const [p_sku, setSKU] = useState([]);
  const [unallocated, setUnallocated] = useState(0);

  const handleInven = (sku) => {
    setOpenModal(true);
    setSKU(sku);

    try {
      axios
        .get(fetchProductInven, {
          params: {
            warehouse_pk: userDetails.primary_warehouse_pk,
            product_sku: sku,
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          console.log(response.data.product_inventory);
          setInven(response.data.product_inventory.inventory);
          setUnallocated(response.data.product_inventory.unallocated);
          setOpenModal(true);
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    { field: "product_sku", headerName: "SKU", width: 100 },
    { field: "part_number", headerName: "Part Number", width: 210 },
    { field: "product_name", headerName: "Product", width: 250 },
    { field: "product_type__product_type", headerName: "Type", width: 130 },
    { field: "brand__product_brand", headerName: "Brand", width: 130 },
    {
      field: "mrp",
      headerName: "MRP",
      width: 100,
      renderCell: (params) => (
        <span style={{ color: "red" }}>₹{params.value.toFixed(2) || 0}</span>
      ),
    },
    { field: "oem_number", headerName: "OEM Number", width: 210 },

    {
      field: "in_stock",
      headerName: "Stock",
      width: 60,
      renderCell: (params) => (
        <span
          onClick={() =>
            handleClickOpen(
              params.row.product_sku,
              params.row.product_name,
              params.row.serieses,
              params.row
            )
          }
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: params.value <= 2 ? "red" : "green",
            cursor: "pointer",
          }}
        >
          {params.value || 0}
        </span>
      ),
    },
    { field: "length", headerName: "Length(cm)", width: 60 },
    { field: "breadth", headerName: "Breadth(cm)", width: 60 },
    { field: "height", headerName: "Height(cm)", width: 60 },
    { field: "weight", headerName: "Weight(Kg)", width: 60 },
    {
      field: "actions",
      headerName: "Actions",
      width: 75,
      renderCell: (params) => (
        <>
          <MdInventory
            style={{ color: "cornflowerblue", cursor: "pointer" }}
            onClick={() => handleInven(params.row.product_sku)}
          />
          <FaPencilAlt
            style={{ marginLeft: "20px", color: "red", cursor: "pointer" }}
            onClick={() => handleEditClick(params.row.product_sku)}
          />
        </>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [pseriessku, setPseriessku] = useState("");
  const [pseriesname, setPseriesname] = useState("");
  const [serieses, setSeries] = useState([]);
  const [allData, setAllData] = useState();
  const [sendData, setSendData] = useState({}); // New state for sendData

  const handleClickOpen = (sku, name, data, allData) => {
    setOpen(true);
    setSeries(data);
    setPseriessku(sku);
    setPseriesname(name);

    const newData = {
      brand__product_brand: allData.brand__product_brand,
      part_number: allData.part_number,
      sku: sku,
      series: data,
      name: name,
    };

    setSendData(newData); // Update sendData state
  };

  return (
    <>
      <SeriesModal
        open={open}
        setOpen={setOpen}
        pseriessku={pseriessku}
        pseriesname={pseriesname}
        serieses={serieses}
        sendData={sendData} // Pass sendData to SeriesModal
      />

      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Delete
        selectedSKUs={skus}
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        fetchData={fetchData}
      />

      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        inventory={inventory}
        p_sku={p_sku}
        setInven={setInven}
        fetchData={fetchData}
        unallocated={unallocated}
        setUnallocated={setUnallocated}
        warehouseId={userDetails.primary_warehouse_pk}
        // row_name={row}
        // warehouse={warehouse}
        // fetchData={fetchData}
      />

      <ImportProducts
        openImport={openImport}
        setOpenImport={setOpenImport}
        fetchData={fetchData}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack id="backbtn" onClick={() => navigate("/")} />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Products</Typography>
              <Typography sx={{ color: "text.primary" }}>
                All Products
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => navigate("/add_product")}
                >
                  Add Product
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setOpenImport(true)}
                >
                  Import Products
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleDeleteClick}
                >
                  Delete Selected
                </Button>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
            className="all-master-products"
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              checkboxSelection
              disableRowSelectionOnClick
              rows={products}
              columns={columns}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelection) =>
                setSelectionModel(newSelection)
              }
              isRowSelectable={(params) => params.row.deletable !== 0}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MasterProducts;
