import React, { useState, useEffect } from "react";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import {
  fetch_all_unallocated_product,
  apiKey,
  fetch_warehouse,
} from "../../Api";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import CustomToolbar from "../../Components/CustomToolbar";
import Error from "../../Messages/Error";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Popup } from "semantic-ui-react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IoAlertCircleOutline } from "react-icons/io5";
import SeriesModal from "./SeriesModal";
import { IoMdArrowRoundBack } from "react-icons/io";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function UnallocatedProducts() {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [totalPages, setTotalPages] = useState(0);
  const [searchquery, setSearchQuery] = useState("");
  const [filterModel, setFilterModel] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [openError, setOpenError] = useState(false);
  const navigate = useNavigate();

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(userDetails.primary_warehouse_pk);

  const handleWarehouseChange = (e) => {
    const ware = e.target.value;
    setWarehouse(ware);
  };

  useEffect(() => {
    fetchDataWarehouse();
    fetchData();
  }, [warehouse]);

  const fetchDataWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.warehouses);
        setWarehouses(response.data.warehouses);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    // fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 180000); // 3 minutes in milliseconds

    return () => clearInterval(intervalId);
  }, [currentPage, pageSize, searchquery, filterModel]);

  const fetchData = (page = currentPage, query = searchquery) => {
    const data = {
      page,
      per_page: pageSize,
      search: query,
      warehouse_pk: warehouse,
      selectedBrands: [],
      selectedTypes: [],
      selectedStocks: [],
      filters: filterModel.items || [],
    };
    const headers = {
      "Content-Type": "application/json",
      "API-Key": apiKey,
    };

    axios
      .post(fetch_all_unallocated_product, data, { headers })
      .then((response) => {
        // console.log(response.data.data);
        setProducts(response.data.data);
        setTotalPages(response.data.num_pages);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handlePaginationModelChange = (paginationModel) => {
    setCurrentPage(paginationModel.page + 1);
    setPageSize(paginationModel.pageSize);
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setCurrentPage(1);
  };

  const columns = [
    { field: "product_sku", headerName: "SKU", width: 120 },
    { field: "part_number", headerName: "Part Number", width: 250 },
    { field: "product_name", headerName: "Product", width: 300 },
    // { field: "product_type__product_type", headerName: "Type", width: 150 },
    {
      field: "product_type",
      headerName: "Type",
      width: 150,
      renderCell: (params) => <span>{params.value.product_type}</span>,
    },
    // { field: "brand__product_brand", headerName: "Brand", width: 150 },
    {
      field: "brand",
      headerName: "Brand",
      width: 140,
      renderCell: (params) => <span>{params.value.product_brand}</span>,
    },
    {
      field: "mrp",
      headerName: "MRP",
      width: 100,
      renderCell: (params) => (
        <span style={{ color: "red" }}>₹{params.value.toFixed(2) || 0}</span>
      ),
    },
    { field: "oem_number", headerName: "OEM Number", width: 210 },

    {
      field: "in_stock",
      headerName: "Stock",
      width: 90,
      renderCell: (params) => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            color: params.value <= 2 ? "red" : "green",
          }}
        >
          {params.value || 0}
        </span>
      ),
    },

    {
      field: "serieses",
      headerName: "",
      width: 60,
      renderCell: (params) => (
        <>
          <IoAlertCircleOutline
            onClick={() =>
              handleClickOpen(
                params.row.product_sku,
                params.row.product_name,
                params.value
              )
            }
            style={{
              fontSize: "16px",
              textAlign: "center",
              cursor: "pointer",
            }}
          />
        </>
      ),
    },
  ];

  const [open, setOpen] = useState(false);
  const [pseriessku, setPseriessku] = useState("");
  const [pseriesname, setPseriesname] = useState("");
  const [serieses, setSeries] = useState([]);

  const handleClickOpen = (sku, name, data) => {
    setOpen(true);
    setSeries(data);
    setPseriessku(sku);
    setPseriesname(name);
    console.log(data);
  };

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <SeriesModal
        open={open}
        setOpen={setOpen}
        pseriessku={pseriessku}
        pseriesname={pseriesname}
        serieses={serieses}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_products")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Products</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Unallocated Products
              </Typography>
            </Breadcrumbs>
          </div>

          <div className="card my-2 p-2">
            <div className="row">
              <div className="col-lg-6">
                <input
                  className="form-control"
                  placeholder="Search..."
                  style={{ backgroundColor: "white" }}
                  type="search"
                  value={searchquery}
                  onChange={handleSearchChange}
                />
              </div>

              <div
                className="col-lg-6 md-my-0 my-2"
                style={{
                  alignItems: "center",
                  display: "flex",
                  gap: "5px",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => navigate(`/allocate-products-scan`)}
                >
                  Allocate Products
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={() => navigate("/allocation-history")}
                >
                  Allocate History
                </Button>
                <FormControl size="small">
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    style={{ minWidth: "200px" }}
                    value={warehouse}
                    label="Select Warehouse"
                    onChange={handleWarehouseChange}
                  >
                    <MenuItem value="">--Select Warehouse--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <DataGrid
              slots={{ toolbar: CustomToolbar }}
              checkboxSelection
              disableRowSelectionOnClick
              rows={products}
              columns={columns}
              filterMode="server"
              onFilterModelChange={handleFilterModelChange}
              loading={loading}
              paginationMode="server"
              paginationModel={{
                pageSize: pageSize,
                page: currentPage - 1,
              }}
              rowCount={totalPages * pageSize}
              onPaginationModelChange={handlePaginationModelChange}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelection) =>
                setSelectionModel(newSelection)
              }
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default UnallocatedProducts;
