import React, { useState, useEffect } from "react";
import axios from "axios";
import { apiKey, edit_rack, fetch_racks } from "../../../Api";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "600px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function EditRackModal({
  setOpenEditRack,
  openEditRack,
  rack_name,
  rack_id,
  setRacks,
  row_id,
}) {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [newrack, setRackName] = useState(rack_name);

  useEffect(() => {
    if (rack_name) {
      setRackName(rack_name);
    }
    fetchData();
  }, [rack_name]);

  const fetchData = () => {
    try {
      axios
        .get(fetch_racks, {
          params: {
            row_pk: parseInt(row_id),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status == 1) {
            setRacks(response.data.racks);
          } else {
            setError(response.data.message);
            setOpenError(true);
          }
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpenEditRack(false);
    setError("");
  };

  const handleAddRow = (e) => {
    e.preventDefault();

    if (!newrack) {
      toast.error("Rack cannot be empty!");
      return;
    }

    const formattedData = {
      rack_pk: parseInt(rack_id),
      rack_name: newrack,
    };

    axios
      .post(edit_rack, formattedData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          setOpenSuccess(true);
          setSuccess(`Rack has been updated successfully!`);
          setRackName("");
          fetchData();
          setOpenEditRack(false);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setError(error.response.data.message);
        setOpenError(true);
      });
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Modal
        open={openEditRack}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="p"
            component="p"
            style={{ marginBottom: "15px" }}
          >
            Update Rack
          </Typography>

          <div
            style={{
              maxHeight: "30vh",
            }}
          >
            <TextField
              id="outlined-row-name"
              label="Row Name"
              value={newrack}
              size="small"
              required
              onChange={(e) => setRackName(e.target.value)}
              fullWidth
            />
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button
              style={{ marginTop: "8px", marginLeft: "2px" }}
              variant="outlined"
              color="primary"
              disabled={newrack == rack_name}
              onClick={handleAddRow}
              // disabled={
              //   Boolean(error) ||
              //   allocations.some((alloc) => !alloc.warehouse || !alloc.qty)
              // }
            >
              Update Rack
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default EditRackModal;
