import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import AddRackModal from "./AddNewRack";
import DeleteRackModal from "../../../Messages/DeleteWarehouse/DeleteRack";
import EditRackModal from "./EditRackModal";
import PrintBarcode from "../PrintRackBarcode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function RacksUpdateModal({
  setOpenModal2,
  openModal2,
  racks,
  row_name,
  row_id,
  setRacks,
  ware_id,
  warehouse,
  fetchData,
}) {
  const handleClose = () => {
    setOpenModal2(false);
  };

  const [openModalBarcode, setOpenModalBarcode] = useState(false);

  const [openAddRackModal, setOpenAddRackModal] = useState(false);

  const handleAddRack = () => {
    setOpenAddRackModal(true);
  };

  const [rack_id, setRackId] = useState("");
  const [rack_name, setRackname] = useState("");

  const [openDeleteRack, setOpenDeleteRack] = useState(false);
  const [racksbar, setRacksData] = useState([]);

  const handlePrintRack = (ware_id, row, rack_id, rackname) => {
    // Ensure racksbar is declared properly
    const racksbarData = [
      {
        barcode_value: `W${ware_id}-R${row}-R${rack_id}`,
        row_name: row_name,
        warehouse_name: warehouse,
        rack_name: rackname,
      },
    ];

    setOpenModalBarcode(true);
    setRacksData(racksbarData);

    console.log(racksbar); // Logging the barcode value for debugging
  };

  const [openEditRack, setOpenEditRack] = useState(false);

  const handleEditRack = (rackid, rackname) => {
    setRackId(rackid);
    setRackname(rackname);
    setOpenEditRack(true);
  };

  return (
    <>
      <PrintBarcode
        openModalBarcode={openModalBarcode}
        setOpenModalBarcode={setOpenModalBarcode}
        racksbar={racksbar}
      />

      <AddRackModal
        openAddRackModal={openAddRackModal}
        setOpenAddRackModal={setOpenAddRackModal}
        row_id={row_id}
        setRacks={setRacks}
        fetchData={fetchData}
      />

      <EditRackModal
        openEditRack={openEditRack}
        setOpenEditRack={setOpenEditRack}
        rack_name={rack_name}
        rack_id={rack_id}
        row_id={row_id}
        setRacks={setRacks}
        fetchData={fetchData}
      />

      <DeleteRackModal
        openDeleteRack={openDeleteRack}
        setOpenDeleteRack={setOpenDeleteRack}
        rack_id={rack_id}
        row_id={row_id}
        setRacks={setRacks}
      />

      <Modal
        open={openModal2}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Racks
          </Typography>

          <Button
            variant="outlined"
            style={{ float: "right", marginTop: "-3vh" }}
            size="small"
            onClick={() => handleAddRack()}
          >
            Add New Rack
          </Button>

          <div
            style={{
              overflowY: "scroll",
              maxHeight: "30vh",
              overflowX: "hidden",
              marginTop: "2vh",
            }}
          >
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="a dense table"
              >
                <TableHead className="table-head">
                  <TableRow>
                    <TableCell style={{ width: "5px" }}>#</TableCell>
                    <TableCell align="left">Row Name</TableCell>
                    <TableCell align="left">Rack Name</TableCell>
                    <TableCell align="left">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {racks &&
                    racks.map((row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ width: "5px" }}
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                        </TableCell>

                        <TableCell
                          align="left"
                          style={{ width: "33%", color: "#bababa" }}
                        >
                          {row_name}
                        </TableCell>

                        <TableCell align="left" style={{ width: "33%" }}>
                          {row.rack_name}
                        </TableCell>

                        <TableCell align="left" style={{ width: "33%" }}>
                          <Button
                            onClick={() =>
                              handleEditRack(row.id, row.rack_name)
                            }
                            variant="outlined"
                            style={{ marginLeft: "5px" }}
                            color="info"
                            size="small"
                          >
                            Edit
                          </Button>

                          <Button
                            onClick={() =>
                              handlePrintRack(
                                ware_id,
                                row_id,
                                row.id,
                                row.rack_name
                              )
                            }
                            variant="outlined"
                            style={{ marginLeft: "5px" }}
                            color="error"
                            size="small"
                          >
                            Print
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              style={{ marginTop: "8px" }}
              variant="outlined"
              color="error"
              onClick={handleClose}
            >
              Close
            </Button>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default RacksUpdateModal;
