import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  apiKey,
  TransferHistoryDetails,
  TransferHistoryDetailsInven,
} from "../../../Api";
import { useParams, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { IoMdArrowRoundBack } from "react-icons/io";
import InputLabel from "@mui/material/InputLabel";
import { MdOutlineInventory } from "react-icons/md";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AssignWare from "./InvenModal";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import CustomToolbar from "../../../Components/CustomToolbar";

function ViewStockTransfer() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [products, setProducts] = useState([]);
  const [notes, setNotes] = useState("");
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [sourcewarehouse, setSourceWarehouse] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [openModal, setOpenModal] = useState(false);

  const { pk } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get(TransferHistoryDetails, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          history_pk: pk,
        },
      })
      .then((response) => {
        setProducts(response.data.data);
        setSourceWarehouse(response.data.obj.source_warehouse);
        setWarehouse(response.data.obj.target_warehouse);
        setNotes(response.data.obj.note);
        // setWarehouses(response.data.warehouses);
      })
      .catch((error) => {
        setError(
          "Something went wrong while fetching SKU. Refresh the page once or contact support team."
        );
      });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 20,
    },
    {
      field: "product_sku",
      renderCell: (params) => params.row.product_ref.product_sku || "N/A",
      headerName: "SKU",
      width: 200,
    },

    {
      field: "product_name",
      renderCell: (params) => params.row.product_ref.product_name || "N/A",
      headerName: "Product",
      width: 600,
    },
    {
      field: "Total Qty",
      renderCell: (params) => params.row.quantity,
      headerName: "Total Qty",
      width: 400,
    },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <MdOutlineInventory
            style={{
              marginLeft: "20px",
              color: "cornflowerblue",
              cursor: "pointer",
            }}
            onClick={() => handleInvenDetail(params.row.id)}
          />
        </>
      ),
    },
  ];

  const [invenDetails, setInvenDetails] = useState([]);

  const handleInvenDetail = (id) => {
    setOpenModal(true);
    try {
      axios
        .get(TransferHistoryDetailsInven, {
          params: {
            history_detail_pk: parseInt(id),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          setInvenDetails(response.data.data);
        })
        .catch((error) => {
          setError(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <AssignWare
        openModal={openModal}
        setOpenModal={setOpenModal}
        invenDetails={invenDetails}
      />
      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/stock_transfer")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography color="inherit">Stock Transfer</Typography>
              <Typography sx={{ color: "text.primary" }}>Details</Typography>
            </Breadcrumbs>
          </div>

          <div
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="row" style={{ padding: "5px 15px" }}>
              <div className="col-lg-3 p-3">
                {/* <h5>Source Warehouse</h5> */}

                <FormControl fullWidth size="small" required>
                  <InputLabel id="vendor_name">Source Warehouse</InputLabel>
                  <Select
                    disabled
                    label="Source Warehouse"
                    value={sourcewarehouse}
                  >
                    <MenuItem value={sourcewarehouse}>
                      {sourcewarehouse}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-3 p-3">
                {/* <h5>Target Warehouse</h5> */}
                <FormControl fullWidth size="small" required>
                  <InputLabel id="vendor_name">Target Warehouse</InputLabel>
                  <Select disabled label="Source Warehouse" value={warehouse}>
                    <MenuItem value={warehouse}>{warehouse}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-lg-3 p-3">
                <TextField
                  size="small"
                  multiline
                  readOnly
                  disabled
                  value={notes}
                  rows={1}
                  style={{ width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>
            </div>

            <div className="p-3">
              <DataGrid
                slots={{ toolbar: CustomToolbar }}
                // checkboxSelection
                disableRowSelectionOnClick
                rows={products}
                columns={columns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewStockTransfer;
