import { WidthFull } from "@mui/icons-material";
import {
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { Modal, Box } from "@mui/material";
import { IoClose } from "react-icons/io5";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import { FaWarehouse } from "react-icons/fa";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Use percentage for responsiveness
  maxWidth: "1000px", // Set a maximum width
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ViewAdjustmentModal({
  open,
  setOpenAdjustmentModal,
  selectedAdjustmentDetail,
}) {
  return (
    <Modal
      open={open}
      onClose={() => setOpenAdjustmentModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <Box sx={style}>
        <Breadcrumbs aria-label="breadcrumb">
          <Typography color="inherit">Inventory</Typography>
          <Typography color="inherit">Stock Adjustments</Typography>
          <Typography sx={{ color: "text.primary" }}>Details</Typography>
        </Breadcrumbs>
        <IoClose
          className="closeModalBtn"
          onClick={() => setOpenAdjustmentModal(false)}
        />

        <div>
          {selectedAdjustmentDetail && (
            <>
              <div className="row my-2">
                <div
                  className="col-md-3"
                  style={{
                    display: "flex",
                    gap: "5px",
                    backgroundColor: "#e0f1ff",
                    color: "cornflowerblue",
                    padding: "9px 10px 5px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <FaWarehouse style={{ fontSize: "1.2rem" }} />
                  <Typography variant="body1">
                    {selectedAdjustmentDetail.warehouse.ware_house}
                  </Typography>
                </div>

                <div className="col-md-9">
                  <div
                    className="card p-2"
                    style={{
                      backgroundColor: "#e0f1ff",

                      padding: "5px",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="row">
                      <div className="col-sm-3">
                        <Typography
                          variant="body1"
                          style={{
                            textTransform: "capitalize",
                            color:
                              selectedAdjustmentDetail.action == "stock out"
                                ? "red"
                                : "green",
                          }}
                        >
                          <span style={{ color: "#9dbcf5" }}>Action:</span>{" "}
                          <b>{selectedAdjustmentDetail.action}</b>
                        </Typography>
                      </div>
                      <div className="col-sm-3">
                        <Typography variant="body1">
                          <span style={{ color: "#9dbcf5" }}>Date:</span>{" "}
                          <b style={{ color: "black" }}>
                            {new Date(
                              selectedAdjustmentDetail.date
                            ).toLocaleDateString()}
                          </b>
                        </Typography>
                      </div>
                      <div className="col-sm-3">
                        <Typography variant="body1">
                          <span style={{ color: "#9dbcf5" }}>Products:</span>{" "}
                          <b style={{ color: "black" }}>
                            {selectedAdjustmentDetail.total_products}
                          </b>
                        </Typography>
                      </div>
                      <div className="col-sm-3">
                        <Typography variant="body1">
                          <span style={{ color: "#9dbcf5" }}>Note:</span>{" "}
                          {selectedAdjustmentDetail.note}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <TableContainer
                component={Paper}
                style={{
                  marginTop: "16px",
                  maxHeight: "350px",
                  overflow: "auto",
                }}
              >
                <Table size="small">
                  <TableHead className="table-head">
                    <TableRow>
                      <TableCell>SKU</TableCell>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Part Number</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedAdjustmentDetail.adjustment_history_detail.map(
                      (product) => (
                        <TableRow key={product.product_ref__product_sku}>
                          <TableCell>
                            {product.product_ref__product_sku}
                          </TableCell>
                          <TableCell>
                            {product.product_ref__product_name}
                          </TableCell>
                          <TableCell>
                            {product.product_ref__part_number}
                          </TableCell>
                          <TableCell>{product.quantity}</TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default ViewAdjustmentModal;
