import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_customer,
  create_sale,
  getLastPrice,
  fetch_customer_address,
  fetch_sale_product_cashsale,
  fetch_sale_chilproducts,
  fetch_warehouse,
} from "../../../Api";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaTrashAlt } from "react-icons/fa";
import CreateCustomer from "../../OurClients/Clients/CreateCustomer";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { debounce } from "lodash";
import { Popup } from "semantic-ui-react";
import { IoAlertCircle } from "react-icons/io5";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import { toast, ToastContainer } from "react-toastify";
import { IoMdArrowRoundBack } from "react-icons/io";
import { Autocomplete } from "@mui/material";

const style = () => {
  const width = window.innerWidth;

  if (width <= 480) {
    return { width: 320 };
  } else if (width <= 768) {
    return { width: 450 };
  } else {
    return { width: 550 };
  }
};

function AddCashSale() {
  const getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const inputRef = useRef(null); // Create a ref for the input field

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [success, setSuccess] = useState("");

  const [products, setProducts] = useState([]);
  const [scannedProducts, setScannedProducts] = useState([]);
  // const [scannedProducts, setScannedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const searchListRef = useRef(null);
  const [quantities, setQuantities] = useState({});
  const [mrps, setMrps] = useState({});
  const [last_price, setLast_price] = useState({});
  const [customers, setCustomers] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState("");
  const [customeraddress, setCustomeraddress] = useState([]);
  const [address, setAddress] = useState("");
  const [discount_amount, setDiscount] = useState("");
  const [sale_date, setS_date] = useState(getCurrentDate());
  const [other_charge, setOtherCharge] = useState("");
  const [shipping_charge, setShippingCharge] = useState("");
  const [notes, setNotes] = useState("");

  const [openRemoveP, setOpenRemoveP] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const [discounts, setDiscounts] = useState({});
  const [numericDiscounts, setNumericDiscounts] = useState({});

  const [scanData, setScanData] = useState("");

  const [warehouses, setWarehouses] = useState([]);
  const [warehouse, setWarehouse] = useState(
    userDetails.primary_warehouse_pk || ""
  );

  const handleWarehouseChange = (e) => {
    const ware = e.target.value;
    if (scannedProducts && scannedProducts.length > 0) {
      setErrorMsg("Dear Bhai/Behen,");
      setError(
        "Tum agar warehouse change karoge toh saare products hat jayenge!"
      );
      setOpenError(true);
    } else {
      setWarehouse(ware);
    }
    // console.log(warehouse);
  };

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchCustomers();
    fetchDataWarehouse();
  }, [searchquery, warehouse]);

  const fetchDataWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        // console.log(response.data.warehouses);
        setWarehouses(response.data.warehouses);
        // setLoading(false);
      })
      .catch((error) => {
        setError(error);
        // setLoading(false);
      });
  };

  useEffect(() => {
    handleInputChange(scanData);
  }, [scanData]);

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const handleInputChange = (scanData) => {
    const newValue = scanData;

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    if (newValue.length > 5) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;

        for (const product of scannedProducts) {
          if (
            product.sku === newValue.split("_")[0] &&
            product.additional_info?.includes(
              parseInt(newValue.split("_")[1], 10)
            )
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(newValue.split("_")[0]);
          setAdditionalInfoToRemove(parseInt(newValue.split("_")[1], 10));
          setOpenRemoveP(true);

          return;
        } else {
          fetchProductData(newValue);
          playSound();
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      const [baseSku, additionalInfoString] = sku.split("_");
      const additionalInfo = parseInt(additionalInfoString, 10);

      setScannedProducts((prev) => {
        const existingProduct = prev.find((item) => item.sku === baseSku);

        if (existingProduct) {
          if (existingProduct.additional_info?.includes(additionalInfo)) {
            setSkuToRemove(baseSku);
            setAdditionalInfoToRemove(additionalInfo);
            setOpenRemoveP(true);
            return prev;
          }

          const updatedProducts = prev.map((item) =>
            item.sku === baseSku
              ? {
                  ...item,
                  qty: item.qty + 1,
                  additional_info: [
                    ...(item.additional_info || []),
                    additionalInfo,
                  ],
                }
              : item
          );

          // Update quantities with the length of additional_info array
          setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [baseSku]: existingProduct.additional_info.length + 1,
          }));

          return updatedProducts;
        } else {
          const newProduct = {
            sku: baseSku,
            product_name: productData.product_name,
            product_sku: productData.product_sku,
            mrp: productData.mrp,
            type: "big",
            last_price: productData.last_price,
            qty: 1,
            additional_info: [additionalInfo],
          };

          // Update quantities with the length of additional_info array
          setQuantities((prevQuantities) => ({
            ...prevQuantities,
            [baseSku]: 1, // since it's a new product with a single additional info
          }));

          return [...prev, newProduct];
        }
      });
    }, 300),
    [] // dependencies
  );

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    let quantity = parseInt(value, 10);

    if (isNaN(quantity) || quantity <= 0) {
      quantity = 1; // Ensure minimum quantity is 1
    }

    if (quantity > product.in_stock) {
      quantity = product.in_stock;
      toast.error(
        `Quantity can't exceed available stock ${product.in_stock} !`
      );
    }

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => {
        const newQuantities = { ...prevQuantities };

        const productIndex = scannedProducts.findIndex(
          (p) => p.product_sku === product.product_sku
        );

        if (productIndex !== -1) {
          const additionalInfo = scannedProducts[productIndex].additional_info;

          if (additionalInfo && Array.isArray(additionalInfo)) {
            newQuantities[product.product_sku] = additionalInfo.length;
          } else {
            newQuantities[product.product_sku] = quantity; // or handle as appropriate
          }
        }

        return newQuantities;
      });
    }
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      axios
        .get(fetch_sale_product_cashsale, {
          params: {
            product_value: sku,
            customer_pk: customer || 0,
            warehouse_pk: parseInt(warehouse),
          },
          headers: {
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          if (response.data.status === 1) {
            const productData = response.data.product;
            addScannedProduct(sku, productData);
          } else if (response.data.status === 0) {
            setError(response.data.message);
            setOpenError(true);
          }
          setScanData("");
        })
        .catch((error) => {
          setOpenError(true);

          setError(error.response.data.message);
          setScanData("");
        });
    }, 400),
    [customer, warehouse]
  );

  const handleRemoveProduct = () => {
    setScannedProducts((prev) => {
      return prev
        .map((item) => {
          if (item.sku === skuToRemove) {
            return {
              ...item,
              additional_info: item.additional_info.filter(
                (info) => info !== additionalInfoToRemove
              ),
              qty: item.additional_info.length === 1 ? 0 : item.qty,
            };
          }
          return item;
        })
        .filter((item) => item.qty > 0);
    });
    setOpenRemoveP(false);
    setSkuToRemove("");
    setAdditionalInfoToRemove(null);
    setScanData("");
  };

  const handleCloseRemove = () => {
    setOpenRemoveP(false);
    setScanData("");
  };

  //SMall Ityems search
  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const response = await axios.get(fetch_sale_chilproducts, {
          params: { search: value, customer_pk: parseInt(customer) || 0 },
          headers: { "API-Key": apiKey },
        });

        const fetchedProducts = response.data.data || [];

        console.log(fetchedProducts);
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer] // Dependency array
  );

  const handleInputChangeSmall = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length <= 1) {
        setDisplay(false);
      } else if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const handleCheckboxChange = (product) => {
    setScannedProducts((prevSelected) => {
      const isSelected = prevSelected.some(
        (p) => p.product_sku === product.product_sku
      );

      if (isSelected) {
        // Remove product from selectedProducts
        return prevSelected.filter(
          (p) => p.product_sku !== product.product_sku
        );
      } else {
        // Add product to selectedProducts
        setLast_price((prevLastMrps) => ({
          ...prevLastMrps,
          [product.product_sku]: product.last_price,
        }));
        return [...prevSelected, product];
      }
    });
  };

  const isProductSelected = (product) => {
    return scannedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const fetchCustomers = (query = searchquery) => {
    axios
      .get(fetch_customer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: query,
        },
      })
      .then((response) => {
        // console.log(response);
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCustomerChange = (event, newValue) => {
    const customerPk = newValue ? newValue.id : "";
    setCustomer(customerPk);

    // Create an array of SKUs from selected products
    const skus = scannedProducts.map((product) => product.product_sku);

    try {
      axios
        .get(fetch_customer_address, {
          headers: {
            "API-Key": apiKey,
          },
          params: {
            customer_pk: customerPk,
          },
        })
        .then((response) => {
          setCustomeraddress(response.data.address);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}

    // Fetch updated prices for the new customer
    try {
      axios
        .post(
          getLastPrice,
          { customer_pk: customerPk, skus },
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        )
        .then((response) => {
          const lastMrpsData = response.data.prices;
          const lastMrpsDataObject = lastMrpsData.reduce((acc, item) => {
            acc[item.sku] = item.last_price;
            return acc;
          }, {});

          const updatedProducts = scannedProducts.map((product) => ({
            ...product,
            last_price:
              lastMrpsDataObject[product.product_sku] || product.last_price,
          }));

          setScannedProducts(updatedProducts);
        })
        .catch((error) => {
          console.error("Error fetching updated prices:", error);
        });
    } catch (error) {
      console.error("Error fetching updated prices:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [opencustomer, setCustomerOpen] = useState(false);
  const handleOpencustomer = () => setCustomerOpen(true);
  const handleClosecustomer = () => setCustomerOpen(false);

  const handleDeleteProduct = (product_sku) => {
    setScannedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  const handleButtonClick = () => {
    window.open("/add_product", "_blank");
  };

  useEffect(() => {
    const getCustomerDetails = (customerId) => {
      const foundCustomer = customers.find((cust) => cust.id === customerId);
      if (foundCustomer) {
        return {
          name: foundCustomer.customer_name,
          primaryShipping: foundCustomer.primary_shipping || {}, // Default to an empty object if not available
        };
      }
      return {
        name: "",
        primaryShipping: {},
      };
    };

    const customerDetails = getCustomerDetails(customer);
    const location = customerDetails.primaryShipping?.id || "";

    setAddress(location); // Update address when customerDetails.primaryShipping.id changes
  }, [customer, customers, setAddress]);

  const [itemDisplay, setItemDisplay] = useState(0);

  const handleClickSmallItems = () => {
    setItemDisplay((prevValue) => (prevValue === 1 ? 0 : 1)); // Toggle between 1 and 0
    setScanData("");
    setSearchInput("");
  };

  const handleDiscountChange = (e, product, type) => {
    const { value } = e.target;
    const sku = product.product_sku;
    const originalMRP = product.mrp; // Use the original MRP from the product
    const currentMRP = mrps[sku];
    let newMRP;

    // Parse and validate the discount value
    const parseValue = (val) => parseFloat(val) || 0;

    if (type === "percentage") {
      // Handle percentage discount
      const percentageValue = parseValue(value);
      if (percentageValue < 0 || percentageValue > 100) {
        // Invalid percentage
        return;
      }
      newMRP = originalMRP - (originalMRP * percentageValue) / 100;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${percentageValue}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: Math.ceil(originalMRP - newMRP),
      }));
    } else if (type === "numeric") {
      // Handle numeric discount
      const numericValue = parseFloat(value) || 0;
      if (numericValue < 0) {
        // Invalid numeric discount
        return;
      }
      newMRP = currentMRP - numericValue;

      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: `${Math.ceil((numericValue / originalMRP) * 100)}`,
      }));

      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [sku]: Math.ceil(numericValue),
      }));
    }

    // Ensure MRP is not negative
    newMRP = Math.max(newMRP, 0);

    // Update MRP in state
    setMrps((prevMrps) => ({
      ...prevMrps,
      [sku]: Math.ceil(newMRP),
    }));
  };

  const handleMRPChange = (event, product) => {
    const { value } = event.target;
    const updatedMRP = parseInt(value, 10);

    // Retrieve the original MRP and current discount for the product
    const originalMRP = product.mrp;
    const discount = discounts[product.product_sku] || 0;

    // If MRP is less than or equal to 0, reset MRP and discounts to 0
    if (updatedMRP <= 0 || value === "") {
      setMrps((prevMrps) => ({
        ...prevMrps,
        [product.product_sku]: 0,
      }));
      setNumericDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [product.product_sku]: 0,
      }));
      setDiscounts((prevDiscounts) => ({
        ...prevDiscounts,
        [product.product_sku]: 0,
      }));
      return 0;
    }

    // Update the MRP in state
    setMrps((prevMrps) => ({
      ...prevMrps,
      [product.product_sku]: updatedMRP,
    }));

    // Handle flat discount
    const flatDiscount = parseFloat(discount);
    const newNumericDiscount = flatDiscount;
    const discountedPrice = Math.max(updatedMRP - newNumericDiscount, 0);

    // Calculate the discount based on the original MRP
    const calculatedPercentageDiscount =
      ((originalMRP - updatedMRP) / originalMRP) * 100;
    const calculatedNumericDiscount = originalMRP - updatedMRP;

    let discountratenumeric = 0;
    if (updatedMRP > originalMRP) {
      discountratenumeric = 0;
    } else {
      discountratenumeric = Math.ceil(calculatedNumericDiscount);
    }

    // Update the state with the recalculated discounts
    setNumericDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: discountratenumeric,
    }));

    let discountrate = 0;
    if (updatedMRP > originalMRP) {
      discountrate = 0;
    } else {
      discountrate = calculatedPercentageDiscount.toFixed(2);
    }
    setDiscounts((prevDiscounts) => ({
      ...prevDiscounts,
      [product.product_sku]: discountrate,
    }));

    return discountedPrice;
  };

  const calculateProductTotal = (product) => {
    // Use additional_info length if available, otherwise use quantities or default to 1
    const quantity =
      product.additional_info && product.additional_info.length
        ? product.additional_info.length
        : quantities[product.product_sku] || 1;

    const mrp = mrps[product.product_sku] || product.mrp;

    if (mrp <= 0) {
      return 0;
    }

    // Retrieve the discount value and check if it contains '%'
    // (Discount logic can be added here if needed)

    return Math.ceil(mrp * quantity);
  };

  const calculateTotalMRP = () => {
    const totalProductMRP = scannedProducts.reduce((total, product) => {
      const productMRP = mrps[product.product_sku] || product.mrp;
      const quantity =
        product.additional_info && product.additional_info.length
          ? product.additional_info.length
          : quantities[product.product_sku] || 1;

      return total + productMRP * quantity;
    }, 0);

    const otherCharge = parseFloat(other_charge) || 0;
    const shippingCharge = parseFloat(shipping_charge) || 0;
    const discountAmount = parseFloat(discount_amount) || 0;

    const totalCharges = otherCharge + shippingCharge;
    const totalMRP = totalProductMRP + totalCharges - discountAmount;

    return totalMRP;
  };

  const handleSubmit = async () => {
    if (!sale_date || !customer) {
      setError("Customer & Sale Date cannot be empty!");
      setOpenError(true);
      return;
    }
    const saleData = {
      customer_pk: parseInt(customer) || 0,
      warehouse_pk: parseInt(warehouse),
      sale_date: sale_date,
      other_charge: other_charge || 0,
      courier_charge: shipping_charge || 0,
      shipping_pk: parseInt(address),
      note: notes || "",
      sale_type: "cash",
      discount_amount: discount_amount || 0,
      user_name: userDetails.name || "",
      products: scannedProducts.map((product) => ({
        product_sku: product.product_sku,
        quantity:
          parseInt(quantities[product.product_sku]) ||
          parseInt(product.additional_info.length) ||
          1,
        mrp: mrps[product.product_sku] || product.mrp,
        item_discount: parseFloat(numericDiscounts[product.product_sku]) || 0.0,
        series: product.additional_info || [],
      })),
    };

    axios
      .post(create_sale, saleData, {
        headers: {
          "Content-Type": "application/json",
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        if (response.data.status === 1) {
          setOpenSuccess(true);
          setSuccess("Sale created successfully!");
          setScannedProducts([]);
          setProducts([]);
          setDiscount("0.00");
          setSearchInput("");
          setS_date("");
          setCustomer("");
          setTimeout(() => {
            navigate("/all_sales");
          }, 1000);
        } else {
          setError(response.data.message);
          setOpenError(true);
        }
      })
      .catch((error) => {
        setOpenError(true);

        setError(error.response.data.message);
      });
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [percentage, setPercentage] = useState(0);

  // const calculateTotalMRP = () => {
  //   // Replace this with your actual total MRP calculation
  //   return 100; // Example total MRP value
  // };

  const calculateTotalChangeMRP = () => {
    // Calculate total MRP from selected products
    const totalProductMRP = scannedProducts.reduce((total, product) => {
      const productMRP = mrps[product.product_sku] || product.mrp;
      return total + productMRP * (quantities[product.product_sku] || 1);
    }, 0);

    // Parse other charges, shipping charges, and discount amount

    const shippingCharge = parseFloat(shipping_charge) || 0;
    const discountAmount = parseFloat(discount_amount) || 0;

    // Calculate the final total MRP
    const totalMRP = totalProductMRP + shippingCharge - discountAmount;

    // Ensure the total MRP is not negative
    const finalTotalMRP = Math.max(totalMRP, 0);

    return finalTotalMRP; // Return the final total MRP
  };

  const handleOtherChargeChange = (e) => {
    const newOtherCharge = e.target.value || "";
    setOtherCharge(newOtherCharge);
    setPercentage((newOtherCharge / calculateTotalChangeMRP()) * 100);
  };

  const handlePercentageChange = (e) => {
    let newPercentage = e.target.value || "";
    if (newPercentage > 99) {
      newPercentage = 99;
    }
    setPercentage(newPercentage);
    let totalCal = (newPercentage / 100) * calculateTotalChangeMRP();
    setOtherCharge(totalCal);
  };

  const [ScanFocus, setScanFocus] = useState(0);

  const handleScanFocus = () => {
    setScanFocus((prevValue) => (prevValue === 1 ? 0 : 1)); // Toggle between 1 and 0
  };

  // Focus the input when itemDisplay is 0
  useEffect(() => {
    if (ScanFocus === 0) {
      inputRef.current.focus(); // Autofocus the input field
    }
  }, [ScanFocus]); // Depend on itemDisplay so it runs when itemDisplay changes

  return (
    <>
      <ToastContainer />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error
        openError={openError}
        setOpenError={setOpenError}
        error={error}
        errormsg={errormsg}
      />

      <CreateCustomer
        opencustomer={opencustomer}
        handleOpencustomer={handleOpencustomer}
        handleClosecustomer={handleClosecustomer}
        fetchCustomers={fetchCustomers}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div className="row">
            <div className="col-lg-9">
              <div style={{ display: "flex" }}>
                <IoMdArrowRoundBack
                  id="backbtn"
                  onClick={() => navigate("/all_sales")}
                />
                <Breadcrumbs aria-label="breadcrumb">
                  <Typography color="inherit">Sales</Typography>
                  <Typography sx={{ color: "text.primary" }}>
                    Create Cash Sale
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
            <div className="col-lg-3">
              <div style={{ display: "flex" }}>
                <FormControl size="small" fullWidth>
                  <InputLabel>Select Warehouse</InputLabel>
                  <Select
                    value={warehouse}
                    label="Select Warehouse"
                    onChange={handleWarehouseChange}
                  >
                    <MenuItem value="">--Select Warehouse--</MenuItem>
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  size="small"
                  variant="contained"
                  style={{ marginLeft: "5px", minWidth: "150px" }}
                  onClick={handleSubmit}
                >
                  Confirm Sale
                </Button>
              </div>
            </div>
          </div>

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-5 my-2">
                    <div style={{ display: "flex" }}>
                      <FormControl fullWidth size="small" required>
                        <Autocomplete
                          value={
                            customers.find((cust) => cust.id === customer) ||
                            null
                          }
                          onChange={handleCustomerChange}
                          inputValue={searchquery}
                          onInputChange={(event, newInputValue) => {
                            setSearchQuery(newInputValue);
                          }}
                          options={customers}
                          getOptionLabel={(option) =>
                            option.customer_name || ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Customer"
                              variant="outlined"
                              size="small"
                            />
                          )}
                        />
                      </FormControl>

                      <Button
                        variant="outlined"
                        style={{ marginLeft: "2px", height: "4.4vh" }}
                        onClick={handleOpencustomer}
                        size="small"
                      >
                        Add
                      </Button>
                    </div>

                    {customer ? (
                      <>
                        <FormControl
                          fullWidth
                          size="small"
                          required
                          style={{ marginTop: "8px", marginBottom: "0px" }}
                        >
                          <InputLabel id="customer_name_address">
                            Shipping Address
                          </InputLabel>
                          <Select
                            labelId="customer_name_address"
                            label="Shipping Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          >
                            <MenuItem value="0">In hand</MenuItem>

                            {customeraddress.map((cust) => (
                              <MenuItem key={cust.id} value={cust.id}>
                                {cust.contact_name}
                                {cust.address}, {cust.state}, {cust.pincode}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    ) : null}
                  </div>
                  <div className="col-lg-3 my-2">
                    <TextField
                      type="date"
                      value={sale_date}
                      size="small"
                      required
                      fullWidth
                      onChange={(e) => setS_date(e.target.value)}
                    />
                  </div>

                  <div
                    className="col-lg-4 my-2"
                    style={{
                      display: "flex",
                      marginTop: "1px",
                      borderLeft: "1px solid grey",
                      paddingLeft: "8px",
                    }}
                  >
                    <TextField
                      placeholder="Type your message here (if any)"
                      value={notes}
                      multiline
                      rows={3}
                      onChange={(e) => setNotes(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </div>
                </div>
              </div>

              <div
                className="card my-2 "
                style={{ padding: "20px 15px 2px 15px" }}
              >
                <div className="row">
                  <div className="col-8" style={{ display: "flex" }}>
                    <TextField
                      inputRef={inputRef} // Assign the ref to the TextField
                      onChange={
                        itemDisplay && itemDisplay === 1
                          ? handleInputChangeSmall
                          : (e) => setScanData(e.target.value)
                      }
                      value={
                        itemDisplay && itemDisplay === 1
                          ? searchInput
                          : scanData
                      }
                      // value={searchInput}
                      fullWidth
                      size="small"
                      label={
                        itemDisplay && itemDisplay === 1
                          ? "Search Child Parts"
                          : "Scan Product..."
                      }
                      variant="outlined"
                    />
                    {displaySearch ? (
                      <>
                        <div
                          id="searchList"
                          ref={searchListRef}
                          style={{
                            border: "1px solid #dedede",
                            position: "absolute",
                            zIndex: "2",
                            marginTop: "2px",
                            maxHeight: "50vh",
                            maxWidth: "50vw",
                            overflowY: "scroll",
                            backgroundColor: "white",
                            width: "100%",
                          }}
                        >
                          {products.map((product, index) => (
                            <>
                              <div
                                className="card p-3"
                                style={{ display: "flex" }}
                                key={index}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={isProductSelected(product)}
                                      onChange={() =>
                                        handleCheckboxChange(product)
                                      }
                                      name="check"
                                    />
                                  }
                                  label={
                                    product.product_sku +
                                    " : " +
                                    product.product_name
                                  }
                                />
                              </div>
                            </>
                          ))}
                        </div>
                      </>
                    ) : null}

                    {itemDisplay === 1 ? null : (
                      <>
                        <Button
                          onClick={handleScanFocus}
                          variant="contained"
                          color="success"
                          style={{ marginLeft: "5px" }}
                        >
                          SCAN
                        </Button>
                      </>
                    )}
                  </div>
                  <div className="col-2 ">
                    <Button
                      variant="outlined"
                      color={
                        itemDisplay && itemDisplay === 1 ? "error" : "success"
                      }
                      style={{
                        marginLeft: "2px",
                        width: "100%",
                        height: "40px",
                      }}
                      onClick={handleClickSmallItems}
                      size="small"
                    >
                      {itemDisplay && itemDisplay === 1
                        ? "Scan Product"
                        : "Add Child Parts"}
                    </Button>
                  </div>
                  <div className="col-2 ">
                    <Button
                      variant="outlined"
                      style={{
                        marginLeft: "2px",
                        width: "100%",
                        height: "40px",
                      }}
                      size="small"
                      onClick={handleButtonClick}
                    >
                      Add Product
                    </Button>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3">
                {/* <h5>Sale Summary</h5> */}
                <div className="row" style={{ marginTop: "2px" }}>
                  <div className="col-6 my-2">Total Discount (-)</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <TextField
                      placeholder="0.00"
                      value={discount_amount}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={(e) => setDiscount(e.target.value)}
                      size="small"
                      slotProps={{
                        input: {
                          startAdornment: (
                            <InputAdornment position="start">kg</InputAdornment>
                          ),
                        },
                      }}
                    />
                  </div>
                  <div className="col-6 my-2">Shipping Charges</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    <TextField
                      placeholder="0.00"
                      value={shipping_charge}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={(e) => setShippingCharge(e.target.value)}
                      size="small"
                      fullWidth
                    />
                  </div>
                  <div className="col-4 my-2">Other Charges</div>
                  <div
                    className="col-8 my-2"
                    style={{ textAlign: "right", display: "flex" }}
                  >
                    <OutlinedInput
                      size="small"
                      fullWidth
                      style={{ maxWidth: "4vw" }}
                      name="discountPercent"
                      value={percentage}
                      // disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      onChange={handlePercentageChange}
                      endAdornment={
                        <InputAdornment position="end">%</InputAdornment>
                      }
                    />

                    <OutlinedInput
                      size="small"
                      placeholder="0.00"
                      style={{ marginLeft: "2px" }}
                      fullWidth
                      name="discountAmount"
                      value={other_charge}
                      onChange={handleOtherChargeChange}
                      disabled={!calculateTotalMRP() || calculateTotalMRP() < 1}
                      startAdornment={
                        <InputAdornment position="start">₹</InputAdornment>
                      }
                    />
                  </div>

                  <div className="col-6 my-2">Grand Total</div>
                  <div
                    className="col-6 my-2"
                    style={{ textAlign: "right", color: "red" }}
                  >
                    <b>₹{calculateTotalMRP().toFixed(2)}</b>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="card p-4 my-2">
                <div className="products_table_class">
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead className="table-head">
                        <TableRow>
                          <TableCell>SKU</TableCell>
                          <TableCell align="left">Product</TableCell>

                          <TableCell align="left">MRP</TableCell>
                          <TableCell align="left">Discount</TableCell>
                          <TableCell align="left">Price</TableCell>
                          {/* <TableCell align="right">Last Price</TableCell> */}

                          <TableCell align="left">Quantity</TableCell>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right"></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {scannedProducts && scannedProducts.length > 0 ? (
                          <>
                            {scannedProducts.map((product, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                                style={{
                                  backgroundColor:
                                    product.type !== "big"
                                      ? "#cee2ed"
                                      : "transparent",
                                }}
                              >
                                <TableCell
                                  component="th"
                                  style={{ width: "6.5vw" }}
                                  scope="row"
                                >
                                  {product.product_sku || ""}
                                </TableCell>
                                <TableCell align="left">
                                  {product.product_name || ""}
                                </TableCell>

                                <TableCell align="left">
                                  ₹{product.mrp || ""}.00
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ width: "10vw" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <OutlinedInput
                                      size="small"
                                      style={{ width: "5vw" }}
                                      name="discountPercentage"
                                      value={
                                        discounts[product.product_sku] || 0
                                      }
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          product,
                                          "percentage"
                                        )
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          %
                                        </InputAdornment>
                                      }
                                    />

                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      name="discountValue"
                                      value={
                                        numericDiscounts[product.product_sku] ||
                                        0
                                      }
                                      onChange={(e) =>
                                        handleDiscountChange(
                                          e,
                                          product,
                                          "numeric"
                                        )
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          .00
                                        </InputAdornment>
                                      }
                                    />
                                  </div>
                                </TableCell>

                                <TableCell
                                  align="left"
                                  style={{ width: "9vw" }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <OutlinedInput
                                      size="small"
                                      style={{
                                        marginLeft: "3px",
                                        width: "6.5vw",
                                      }}
                                      type="text"
                                      value={
                                        numericDiscounts[product.product_sku] ==
                                        0
                                          ? product.mrp
                                          : mrps[product.product_sku] ||
                                            product.mrp
                                      }
                                      onChange={(e) =>
                                        handleMRPChange(e, product)
                                      }
                                      startAdornment={
                                        <InputAdornment position="start">
                                          ₹
                                        </InputAdornment>
                                      }
                                      endAdornment={
                                        <InputAdornment position="end">
                                          .00
                                        </InputAdornment>
                                      }
                                      onFocus={(e) => e.target.select()}
                                    />

                                    {product.last_price != 0 &&
                                    product.last_price != product.mrp ? (
                                      <>
                                        <Popup
                                          style={{
                                            backgroundColor: "white",
                                            border: "1px solid grey",
                                            borderRadius: "5px",
                                            color: "black",
                                            padding: "5px",
                                            marginLeft: "5px",
                                            cursor: "pointer",
                                          }}
                                          on="click"
                                          pinned
                                          content={`₹${product.last_price}.00`}
                                          trigger={
                                            <IoAlertCircle
                                              style={{
                                                cursor: "pointer",
                                                marginLeft: "5px",
                                                fontSize: "18px",
                                                color: "red",
                                              }}
                                            />
                                          }
                                        />
                                      </>
                                    ) : null}
                                  </div>
                                </TableCell>

                                {/* <TableCell align="right">
                                  ₹{product.last_price}.00
                                </TableCell> */}

                                <TableCell
                                  align="right"
                                  style={{ width: "6vw", display: "flex" }}
                                >
                                  <TextField
                                    type="number"
                                    size="small"
                                    disabled={product.type === "big"}
                                    value={
                                      product.type === "big"
                                        ? (product.additional_info &&
                                            product.additional_info.length) ||
                                          1
                                        : quantities[product.product_sku] || 1
                                    }
                                    onChange={(e) =>
                                      handleQuantityChange(e, product)
                                    }
                                    inputProps={{
                                      min: 1,
                                      max: product.in_stock,
                                    }}
                                    variant="outlined"
                                  />
                                </TableCell>

                                <TableCell
                                  align="right"
                                  style={{ width: "5vw", fontWeight: "bold" }}
                                >
                                  ₹{calculateProductTotal(product).toFixed(2)}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  style={{ width: "2vw" }}
                                >
                                  {product.type && product.type == "big" ? (
                                    <>
                                      <FaTrashAlt
                                        style={{
                                          color: "grey",
                                          cursor: "not-allowed",
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <FaTrashAlt
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleDeleteProduct(
                                            product.product_sku
                                          )
                                        }
                                      />
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        ) : (
                          <>
                            <TableRow>
                              <TableCell
                                colSpan="9"
                                style={{ textAlign: "center", color: "red" }}
                              >
                                No Products Selected
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 
      //Remove Product */}

      <Dialog
        open={openRemoveP}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCloseRemove();
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box sx={style}>
          <DialogTitle
            id="alert-dialog-title"
            style={{ color: "red", fontSize: "1.4rem", fontWeight: "bold" }}
          >
            {"Product Already Scanned!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"Do you want to remove this product?"}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              variant="outlined"
              onClick={handleRemoveProduct}
            >
              Remove Product
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={handleCloseRemove}
              color="error"
            >
              Skip
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default AddCashSale;
