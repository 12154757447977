import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  apiKey,
  fetch_warehouse,
  fetchProductsForTransfer,
  fetch_all_product,
  fetchProductInven,
  bulk_Stock_In,
  bilk_Stock_Out,
  fetch_all_product_transfer,
} from "../../../Api";
import { useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { debounce } from "lodash";
import { DataGrid } from "@mui/x-data-grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Success from "../../../Messages/Success";
import Error from "../../../Messages/Error";
import CustomToolbar from "../../../Components/CustomToolbar";
import AssignRackModal from "./AssignStockModal";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";
import Webcam from "react-webcam";
import Quagga from "quagga";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import SeriesModal from "./SeriesModal";
import RemoveSkipProduct from "../../../Messages/StockAdjustment/RemoveSkipProduct";

import soundEffect from "../../../Assets/sounds/beep.mp3";
import { BsUpcScan } from "react-icons/bs";

function AddStockAdjustment() {
  const webcamRef = useRef(null);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [displaySearch, setDisplay] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedProducts2, setSelectedProducts2] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [notes, setNotes] = useState("");
  const [type, setType] = useState();
  const searchListRef = useRef(null);
  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));
  const [productInventories, setProductInventories] = useState({});
  const [unallocated, setUnallocated] = useState({});
  const [inven, setInven] = useState([]);
  const [warehouse, setWarehouse] = useState(
    userDetails.primary_warehouse_pk || ""
  );
  const [warehouses, setWarehouses] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchWarehouse();
    fetchInven();
  }, []);

  const fetchInven = (sku) => {
    axios
      .get(fetchProductInven, {
        params: {
          warehouse_id: warehouse,
          sku: sku,
        },
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const { row_inventory, obj } = response.data;

        setProductInventories((prevInventories) => ({
          ...prevInventories,
          [sku]: row_inventory,
        }));

        // Update unallocated quantities for the specific SKU
        setUnallocated((prev) => ({
          ...prev,
          [sku]: obj.unallocated,
        }));
      })
      .catch((error) => {
        setError(error);
      });
  };

  const fetchWarehouse = () => {
    axios
      .get(fetch_warehouse, {
        headers: {
          "API-Key": apiKey,
        },
      })
      .then((response) => {
        const allWarehouses = response.data.warehouses;
        setWarehouses(allWarehouses); // Set all warehouses
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchProducts = useCallback(() => {
    if (type === 1) {
      try {
        axios
          .post(
            fetch_all_product,
            { search: searchQuery },
            {
              headers: {
                "Content-Type": "application/json",
                "API-Key": apiKey,
              },
            }
          )
          .then((response) => {
            const fetchedProducts = response.data.data.products;
            console.log(response);
            setProducts(fetchedProducts);
            setDisplay(true);
          });
        // console.log(response.data.data.products);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    } else if (type === 2) {
      axios
        .post(fetchProductsForTransfer, {
          headers: {
            "API-Key": apiKey,
          },
          params: {
            search: searchQuery,
            warehouse_id: parseInt(warehouse),
          },
        })
        .then((response) => {
          const fetchedProducts = response.data.data;
          setProducts(fetchedProducts);
          setDisplay(true);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [searchQuery, warehouse]);

  const [debouncedQuery, setDebouncedQuery] = useState("");

  // Debounce the setting of debouncedQuery
  const debouncedSetQuery = useCallback(
    debounce((query) => {
      setDebouncedQuery(query);
    }, 600),
    []
  );

  const handleSearchChange = useCallback(
    (query) => {
      const formattedQuery = query.split("_")[0];
      setSearchQuery(formattedQuery);
      // Automatically add the product if it contains '_'
      if (query.includes("_")) {
        const productToAdd = products.find(
          (product) => product.product_sku === `KTT-${formattedQuery}`
        );
        console.log("productToAdd : ", productToAdd);
        if (
          productToAdd &&
          !selectedProducts2.some(
            (p) => p.product_sku === productToAdd.product_sku
          )
        ) {
          setSelectedProducts2((prev) => [...prev, productToAdd]);
          setSearchQuery("");
        }
      }
      // Update the immediate search query for user feedback
      debouncedSetQuery(formattedQuery); // Update the debounced query
    },
    [products, selectedProducts2, debouncedSetQuery]
  );

  const [istyping, setIstyping] = useState(true);

  const handleInputChange = (e) => {
    const query = e.target.value;

    // If the input contains '_', do not allow further input
    if (query.includes("_")) {
      const sanitizedQuery = query.split("_")[0]; // Get the part before the underscore
      setSearchQuery(sanitizedQuery); // Set the visible input value
      handleSearchChange(query); // Call to handleSearchChange with the full query
      setIstyping(false);
      setTimeout(() => {
        setIstyping(true);
      }, 1000);
    } else {
      setSearchQuery(query); // Set the visible input value
      handleSearchChange(query); // Call to handleSearchChange with the current query
    }
  };

  useEffect(() => {
    if (debouncedQuery) {
      fetchProducts(debouncedQuery);
    }
  }, [debouncedQuery, fetchProducts]);

  // const handleCheckboxChange = (product) => {
  //   console.log("handleCheckboxChange Clicked !");
  //   const newEntry = {
  //     ...product,
  //     inventoryDetail: "",
  //     quantity: 0,
  //   };

  //   if (type == 2) {
  //     console.log("type 2 Clicked");
  //     if (product.product_status == 1) {
  //       // Call addScannedProduct with the appropriate parameters
  //       addScannedProduct(product.product_sku, product);
  //       return; // Exit the function after adding the scanned product
  //     }

  //     setScannedProducts((prevSelected) => {
  //       // Fetch inventory details for the newly added product
  //       console.log("setScannedProducts chala h")
  //       fetchInven(product.product_sku);
  //       return [...prevSelected, newEntry];
  //     });

  //   } else if (type == 1) {
  //     console.log("type 1 Clicked");
  //     setSelectedProducts2((prevSelected) => {
  //       if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
  //         return prevSelected.filter(
  //           (p) => p.product_sku !== product.product_sku
  //         );
  //       } else {
  //         fetchInven(product.product_sku);
  //         return [...prevSelected, newEntry];
  //       }
  //     });
  //   }
  // };

  const handleCheckboxChange = (product) => {
    console.log("handleCheckboxChange Clicked !");
    const newEntry = {
      ...product,
      inventoryDetail: "",
      quantity: 0,
    };

    if (type == 2) {
      console.log("type 2 Clicked");
      if (product.product_status == 1) {
        // Call addScannedProduct with the appropriate parameters
        addScannedProduct(product.product_sku, product);
        return; // Exit the function after adding the scanned product
      }

      // setScannedProducts((prevSelected) => {
      //   // Fetch inventory details for the newly added product
      //   fetchInven(product.product_sku);
      //   return [...prevSelected, newEntry];
      // });

      setScannedProducts((prevSelected) => {
        console.log("prevSelected : ", prevSelected);
        // Fetch inventory details for the newly added product
        if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
          return prevSelected.filter(
            (p) => p.product_sku !== product.product_sku
          );
        } else {
          fetchInven(product.product_sku);
          return [...prevSelected, newEntry];
        }
      });
    } else if (type == 1) {
      console.log("type 1 Clicked");
      setSelectedProducts2((prevSelected) => {
        if (prevSelected.some((p) => p.product_sku === product.product_sku)) {
          return prevSelected.filter(
            (p) => p.product_sku !== product.product_sku
          );
        } else {
          fetchInven(product.product_sku);
          return [...prevSelected, newEntry];
        }
      });
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchListRef.current &&
      !searchListRef.current.contains(event.target)
    ) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleQuantityChange = (productId, newValue) => {
    const numericValue = Number(newValue);
    const selectedProduct = selectedProducts.find((p) => p.id === productId);

    if (selectedProduct) {
      const selectedInventory = selectedProduct.inventoryDetail;
      const selectedInven =
        productInventories[selectedProduct.product_sku] || [];
      const inventory = selectedInven.find(
        (inv) => inv.id === selectedInventory
      );

      const availableQuantity = inventory?.quantity || 0;

      // Check if the idType is 2 and quantity exceeds available amount
      if (selectedProduct.idType == 2 && numericValue > availableQuantity) {
        alert("Quantity cannot exceed the available amount.");
        return; // Prevent updating state if validation fails
      }

      // Update the selected product or reset quantities if idType changes
      setSelectedProducts((prevSelected) =>
        prevSelected.map((product) =>
          product.id === productId
            ? {
                ...product,
                quantity:
                  selectedProduct.idType !== product.idType ? 0 : numericValue,
                idType: product.idType,
              }
            : product
        )
      );
    }
  };

  const handleTypeChange = (event) => {
    const newType = event.target.value;
    // Update the type state
    setType(parseInt(newType));
    setSelectedProducts([]);
    setSelectedProducts2([]);
  };

  const handleDelete = (id) => {
    // Filter out the row with the matching id
    const updatedRows = selectedProducts.filter((row) => row.id !== id);
    setSelectedProducts(updatedRows);
  };

  const stockout = [
    {
      field: "product_sku",
      headerName: "SKU",
      width: 150,
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 550,
    },
    {
      field: "inventoryDetail",
      headerName: "Inventory",
      width: 450,
      renderCell: (params) => {
        const inventories = productInventories[params.row.product_sku] || [];
        const unallocatedQuantity = unallocated[params.row.product_sku] || 0;

        // Get selected inventories for the current SKU
        const selectedInventories = selectedProducts
          .filter((product) => product.product_sku === params.row.product_sku)
          .map((product) => product.inventoryDetail);

        return (
          <FormControl fullWidth size="small" style={{ marginTop: "5px" }}>
            <InputLabel>Inventory</InputLabel>
            <Select
              label="Inventory"
              value={params.row.inventoryDetail || ""}
              onChange={(e) => {
                const selectedInventory = e.target.value;
                setSelectedProducts((prevSelected) =>
                  prevSelected.map((product) =>
                    product.id === params.row.id
                      ? { ...product, inventoryDetail: selectedInventory }
                      : product
                  )
                );
              }}
            >
              {/* Map over inventories */}
              {inventories.map((row) => (
                <MenuItem
                  key={row.id}
                  value={row.id}
                  disabled={selectedInventories.includes(row.id)}
                >
                  {row.row_ref ? row.row_ref.row_name : null}{" "}
                  <MdKeyboardDoubleArrowRight />
                  {row.rack ? row.rack.rack_name : null}{" "}
                  <MdKeyboardDoubleArrowRight />
                  {row.bin_ref ? row.bin_ref.bin_name : null}{" "}
                  <span style={{ color: "red" }}>({row.quantity})</span>
                </MenuItem>
              ))}
              {/* Include unallocated option if applicable */}
              {unallocatedQuantity && unallocatedQuantity > 0 && (
                <MenuItem key="0" value="0">
                  Unallocated{" "}
                  <span style={{ color: "red" }}>({unallocatedQuantity})</span>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 150,
      renderCell: (params) => {
        const isDisabled = !params.row.inventoryDetail; // Disable if no inventory is selected

        // Find the available quantity based on selected inventory
        const selectedProduct = selectedProducts.find(
          (p) => p.product_sku == params.row.product_sku
        );
        const selectedInventory = selectedProduct?.inventoryDetail;
        const selectedInven = productInventories[params.row.product_sku] || [];
        const inventory = selectedInven.find(
          (inv) => inv.id == selectedInventory
        );
        const availableQuantity = inventory?.quantity || 0;

        // Determine if the quantity is valid
        const isQuantityInvalid = params.row.quantity > availableQuantity;

        return (
          <>
            <TextField
              style={{ marginTop: "5px" }}
              size="small"
              type="number"
              label="Quantity"
              fullWidth
              value={params.row.quantity || ""}
              inputProps={{ min: 0 }}
              onChange={(e) => {
                const value = e.target.value;
                handleQuantityChange(params.row.id, value);
              }}
              disabled={isDisabled} // Conditionally disable the input
              error={isQuantityInvalid} // Show error if quantity is invalid
              helperText={
                isQuantityInvalid ? "Quantity exceeds available amount" : ""
              }
            />
          </>
        );
      },
    },
    // {
    //   field: "available",
    //   headerName: "Available",
    //   width: 120,
    //   renderCell: (params) => {
    //     const selectedProduct = selectedProducts.find(
    //       (p) => p.product_sku === params.row.product_sku
    //     );
    //     const selectedInventory = selectedProduct?.inventoryDetail;
    //     const selectedInven = productInventories[params.row.product_sku] || [];
    //     const inventory = selectedInven.find(
    //       (inv) => inv.id === selectedInventory
    //     );
    //     const availableQuantity = inventory?.quantity || 0;
    //     return (
    //       <TextField
    //         disabled
    //         style={{ marginTop: "5px" }}
    //         size="small"
    //         type="number"
    //         fullWidth
    //         value={availableQuantity}
    //       />
    //     );
    //   },
    // },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <FaTrashAlt
            style={{
              marginLeft: "20px",
              color: "red",
              cursor: "pointer",
            }}
            onClick={() => handleDelete(params.row.id)} // Pass unique identifier
          />
        </>
      ),
    },
  ];

  // =================================== Stock IN ===============================

  const [quantities, setQuantities] = useState({});

  const handleQtyChange = (sku, value) => {
    const numericValue = parseInt(value, 10);

    setQuantities((prevQuantities) => {
      const newQuantities = {
        ...prevQuantities,
        [sku]: isNaN(numericValue) ? "" : numericValue,
      };

      setSelectedProducts2((prevSelected) => {
        const existingProduct = prevSelected.find(
          (product) => product.product_sku === sku
        );

        if (existingProduct) {
          return prevSelected.map((product) =>
            product.product_sku === sku
              ? { ...product, product_quantity: newQuantities[sku] }
              : product
          );
        } else {
          return [
            ...prevSelected,
            { product_sku: sku, product_quantity: newQuantities[sku] },
          ];
        }
      });

      return newQuantities;
    });
  };

  const isQuantityValid = (productSku) => {
    return quantities[productSku] && quantities[productSku] > 0;
  };

  const stockin = [
    {
      field: "product_sku",
      headerName: "SKU",
      width: 150,
    },
    {
      field: "product_name",
      headerName: "Product",
      width: 400,
    },
    {
      field: "quantiy",
      headerName: "Quantity",
      width: 200,
      renderCell: (params) => (
        <>
          <TextField
            label="Quantity"
            variant="outlined"
            size="small"
            value={quantities[params.row.product_sku] || ""}
            onChange={(e) =>
              handleQtyChange(params.row.product_sku, e.target.value)
            }
            style={{ marginTop: "5px" }}
          />
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 350,
      renderCell: (params) => (
        <>
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDeleteStockin(params.row.product_sku)}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  const handleDeleteStockin = (productSku) => {
    setSelectedProducts2((prevProducts) =>
      prevProducts.filter((product) => product.product_sku !== productSku)
    );
  };

  // Debugging quantities state
  useEffect(() => {
    console.log("Quantities updated:", quantities);
  }, [quantities]);

  const [stockInModal, setStockInModal] = useState(false);
  const [p_sku, setP_SKU] = useState("");

  const [refreshKey, setRefreshKey] = useState(0);

  const [qty, setQty] = useState("");
  const handleEditClick = (sku) => {
    setP_SKU(sku);
    setStockInModal(true);
    const quantity = quantities[sku] || 0;
    setQty(quantity);
    console.log("Editing product SKU:", sku, "with quantity:", quantity);
  };

  // ============= Submit Data ===========

  const handleSubmitData = () => {
    // Step 1: Check for empty quantities
    // const hasEmptyQuantity = selectedProducts.some(
    //   (product) =>
    //     product.product_quantity === 0 ||
    //     product.product_quantity === null ||
    //     product.product_quantity === undefined
    // );

    // if (hasEmptyQuantity) {
    //   setError("Quantity cannot be empty for any product.");
    //   setOpenError(true);
    //   return;
    // }
    // Step 1: Identify duplicates
    const productCounts = selectedProducts.reduce((acc, product) => {
      acc[product.product_sku] = (acc[product.product_sku] || 0) + 1;
      return acc;
    }, {});

    const formattedInventory = selectedProducts.reduce((acc, product) => {
      const existingEntry = acc.find(
        (entry) => entry.product_sku === product.product_sku
      );

      if (existingEntry) {
        const existingInventoryDetail = existingEntry.inventoryDetails.find(
          (detail) => detail.inventory_pk == product.inventoryDetail
        );

        if (existingInventoryDetail) {
          existingInventoryDetail.quantity += product.quantity;
        } else {
          existingEntry.inventoryDetails.push({
            inventory_pk: parseInt(product.inventoryDetail),
            quantity: product.quantity,
          });
        }
      } else {
        acc.push({
          product_sku: product.product_sku,
          inventoryDetails: [
            {
              inventory_pk: parseInt(product.inventoryDetail),
              quantity: product.quantity,
            },
          ],
        });
      }

      return acc;
    }, []);

    // const hasEmptyQuantityinProduct = selectedProducts2.some(
    //   (product) =>
    //     product.quantity === 0 ||
    //     product.quantity === null ||
    //     product.quantity === undefined
    // );

    // if (hasEmptyQuantityinProduct) {
    //   setError("Quantity cannot be empty for any product.");
    //   setOpenError(true);
    //   return;
    // }

    const formattedData = {
      user_name: userDetails.name,
      remark: notes,
      inventory: formattedInventory,
      warehouse_id: warehouse,
    };

    const stockInformattedData = {
      user_name: userDetails.name,
      remark: notes,
      products: selectedProducts2.map((product) => ({
        sku: product.product_sku,
        quantity: parseInt(product.product_quantity),
      })),
      warehouse_id: warehouse,
    };
    console.log(stockInformattedData, "stockInformattedData");

    if (type == 1) {
      if (!selectedProducts2 || selectedProducts2.length === 0) {
        setError("Please Select a Product.");
        setOpenError(true);
        return;
      }

      const hasEmptyQuantityinProduct = selectedProducts2.some(
        (product) =>
          product.product_quantity === 0 ||
          product.product_quantity === null ||
          product.product_quantity === undefined
      );

      if (hasEmptyQuantityinProduct) {
        setError("Quantity cannot be empty for any product.");
        setOpenError(true);
        return;
      }

      axios
        .post(bulk_Stock_In, stockInformattedData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          console.log(response.data.status);
          if (response.data.status == 1) {
            setOpenSuccess(true);
            setSuccess(`Stock In Successful!`);
            setTimeout(() => {
              setSelectedProducts([]);
              setNotes("");
              setType("");
              setInven([]);
              navigate("/stock_adjustments");
            }, 2000);
          } else {
            console.log(response.data);
            setError(response.data.message);
            setOpenError(true);
          }
        })
        .catch((error) => {
          setError(error.response.data.message);
          setOpenError(true);
        });
    } else if (type == 2) {
      if (!scannedProducts || scannedProducts.length === 0) {
        setError("Please Select a Product.");
        setOpenError(true);
        return;
      }

      // const hasEmptyQuantityinProduct = scannedProducts.some(
      //   (product) =>
      //     product.qty === 0 ||
      //     product.qty === null ||
      //     product.qty === undefined
      // );

      // if (hasEmptyQuantityinProduct) {
      //   setError("Quantity cannot be empty for any product.");
      //   setOpenError(true);
      //   return;
      // }

      // Function to format the inventory data
      const formattedProducts = scannedProducts.map((product) => ({
        product_sku: product.product_sku,
        qty:
          product.type === "big"
            ? product.selectedSeries.length || 0
            : parseInt(product.qty) || 0,
        selected_series: product.selectedSeries || [],
      }));

      const formattedOutData = {
        user_name: userDetails.name,
        remark: notes,
        warehouse_pk: warehouse,
        products: formattedProducts,
      };

      console.log("formattedData data for stock out : ", formattedOutData);
      axios
        .post(bilk_Stock_Out, formattedOutData, {
          headers: {
            "Content-Type": "application/json",
            "API-Key": apiKey,
          },
        })
        .then((response) => {
          console.log(response);
          if (response.data.status == 1) {
            setOpenSuccess(true);
            setSuccess(`Stock Out Successfully`);
            setTimeout(() => {
              setSelectedProducts([]);
              setNotes("");
              setInven([]);
              setScannedProducts([]);
              setType("");
              navigate("/stock_adjustments");
            }, 2000);
          } else {
            setError(response.data.message);
            setOpenError(true);
          }
        })
        .catch((error) => {
          setError(error.response.data.message);
          setOpenError(true);
        });
    }
  };

  // ========== New Stock Out =========

  const inputRef = useRef(null); // Create a ref for the input field
  const [scanData, setScanData] = useState("");
  const [ScanFocus, setScanFocus] = useState(0);
  const [itemDisplay, setItemDisplay] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [customer, setCustomer] = useState("");
  const [scannedProducts, setScannedProducts] = useState([]);
  const [openRemoveP, setOpenRemoveP] = useState(false);
  const [skuToRemove, setSkuToRemove] = useState("");
  const [additionalInfoToRemove, setAdditionalInfoToRemove] = useState(null);

  const handleClickSmallItems = () => {
    setItemDisplay((prevValue) => (prevValue === 1 ? 0 : 1));
    // setScanData("");
    setSearchInput("");
  };

  const handleScanFocus = () => {
    setScanFocus((prevValue) => (prevValue === 1 ? 0 : 1));
  };

  // Focus the input when itemDisplay is 0
  useEffect(() => {
    if (ScanFocus === 0 && inputRef.current) {
      inputRef.current.focus();
    }
  }, [ScanFocus]);

  // === fetch_all_product_transfer ===

  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const body = {
          search: value,
          warehouse_pk: parseInt(warehouse),
        };
        console.log("Body :", body);
        const response = await axios.post(
          fetch_all_product_transfer,
          // { search: value, warehouse_pk: parseInt(warehouse) },
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        );

        const fetchedProducts = response.data.data || [];
        console.log("fetchedProducts ; ", fetchedProducts);
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer, warehouse] // Dependency array
  );

  const handleInputChangeSmall = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length <= 1) {
        setDisplay(false);
      } else if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const isProductSelected = (product) => {
    return scannedProducts.some((p) => p.product_sku === product.product_sku);
  };

  const handleDeleteProduct = (product_sku) => {
    setScannedProducts((prevSelected) =>
      prevSelected.filter((product) => product.product_sku !== product_sku)
    );
  };

  const isValidProductSku = (value) => {
    const regex = /^\d{6}_\d+$/; // First 5 characters are digits, followed by an underscore, then more digits
    return regex.test(value);
  };

  const fetchProductData = useCallback(
    debounce((sku) => {
      console.log("fetchProductData Chala");
      console.log(sku);
      const body = {
        search: sku,
        warehouse_pk: parseInt(warehouse),
      };
      axios
        .post(
          fetch_all_product_transfer,
          // { search: sku, warehouse_pk: parseInt(warehouse) },
          body,
          {
            headers: {
              "Content-Type": "application/json",
              "API-Key": apiKey,
            },
          }
        )
        .then((response) => {
          console.log("Response of Scanned Barcode ", response.data.data);
          console.log("Response of Data ", response.data);
          if (response.data.status === 1) {
            if (response.data.data.length > 0) {
              const productData = response.data.data[0];
              addScannedProduct(sku, productData);
            } else {
              setError("Product Not Found");
              setOpenError(true);
            }
          } else if (response.data.status === 0) {
            setError(response.data.message);
            setOpenError(true);
          }
          setScanData("");
        })
        .catch((error) => {
          setOpenError(true);

          setError(error.response.data.message);
          setScanData("");
        });
    }, 400),
    [customer, warehouse]
  );

  // const fetchProductData = useCallback(

  //   debounce((sku) => {
  //     console.log("fetchProductData Chala")
  //     axios
  //       .get(check_product_series_for_out, {
  //         params: {
  //           product_value: sku,
  //           // customer_pk: customer || 0,
  //         },
  //         headers: {
  //           "API-Key": apiKey,
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response)
  //         if (response.data.status === 1) {
  //           const productData = response.data.product;
  //           addScannedProduct(sku, productData);
  //         } else if (response.data.status === 0) {
  //           setError(response.data.message);
  //           setOpenError(true);
  //         }
  //         setScanData("");
  //       })
  //       .catch((error) => {
  //         setOpenError(true);

  //         setError(error.response.data.message);
  //         setScanData("");
  //       });
  //   }, 400),
  //   [customer]
  // );

  useEffect(() => {
    handleInputChangeScan(scanData);
  }, [scanData]);

  const handleInputChangeScan = (scanData) => {
    const newValue = scanData;

    if (newValue.length <= 5) {
      setScanData("");
      return;
    }

    if (newValue.length > 5) {
      if (isValidProductSku(newValue)) {
        let seriesExists = false;

        for (const product of scannedProducts) {
          if (
            product.sku === newValue.split("_")[0] &&
            product.additional_info?.includes(
              parseInt(newValue.split("_")[1], 10)
            )
          ) {
            seriesExists = true;
            break;
          }
        }

        if (seriesExists) {
          setSkuToRemove(newValue.split("_")[0]);
          setAdditionalInfoToRemove(parseInt(newValue.split("_")[1], 10));
          setOpenRemoveP(true);

          return;
        } else {
          fetchProductData(newValue);
          playSound();
        }
      } else {
        playSound();
      }
    }
    setScanData("");
  };

  const addScannedProduct = useCallback(
    debounce((sku, productData) => {
      // const [baseSku, additionalInfoString] = sku.split("_");
      // const additionalInfo = parseInt(additionalInfoString, 10);
      let baseSku;
      let additionalInfoString;
      let additionalInfo;

      console.log("productData : ", productData);

      if (sku.includes("_")) {
        [baseSku, additionalInfoString] = sku.split("_");
        additionalInfo = parseInt(additionalInfoString, 10);
      } else if (sku.includes("-")) {
        baseSku = sku.split("-")[1];
        console.log("sku.split :", sku.split("-")[1]);
        additionalInfoString = undefined;
        additionalInfo = undefined; // No additional info
      } else {
        baseSku = sku;
        additionalInfoString = undefined;
        additionalInfo = undefined; // Set to undefined or any default value as needed
      }
      const seriesAvailable = productData.serieses.map(
        (series) => series.series == additionalInfo
      );

      // console.log(seriesAvailable);

      if (additionalInfoString) {
        if (seriesAvailable.some((isAvailable) => isAvailable)) {
          setScannedProducts((prev) => {
            const existingProduct = prev.find(
              (item) =>
                item.sku.slice("-")[1] === baseSku ||
                item.product_sku.slice("-")[1] === baseSku ||
                item.sku === baseSku
            );

            console.log("prev :", prev);
            console.log("existingProduct :", existingProduct);

            if (existingProduct) {
              // Check if the cur_series is already in the selectedSeries array
              if (
                existingProduct.selectedSeries.includes(
                  productData.cur_series || parseInt(additionalInfoString)
                )
              ) {
                // setError(
                //   `Series ${
                //     productData.cur_series || additionalInfoString
                //   } is already selected for product ${baseSku}`
                // );
                // setOpenError(true);
                setOpenRemoveSkipProductModal(true);
                setSelectedRemoveSkipProductDetail({
                  existingProduct,
                  cur_series:
                    productData.cur_series || parseInt(additionalInfoString),
                });
                return prev; // Do not add the series if it is already selected
              }

              return prev.map((item) => {
                if (item.sku === baseSku) {
                  const newSeries = {
                    series:
                      productData.cur_series || parseInt(additionalInfoString),
                    ware_house__ware_house: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.ware_house__ware_house,
                    rack__rack_name: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.rack__rack_name,
                    rack__row__row_name: productData.serieses.find(
                      (series) => series.series === productData.cur_series
                    )?.rack__row__row_name,
                  };

                  return {
                    ...item,
                    selectedSeries: [
                      ...item.selectedSeries,
                      ...(productData.cur_series
                        ? [productData.cur_series]
                        : []),
                      ...(additionalInfoString
                        ? [parseInt(additionalInfoString)]
                        : []),
                    ],
                  };
                }
                return item;
              });
            }

            const newProduct = {
              sku: baseSku,
              product_name: productData.product_name,
              product_sku: productData.product_sku,
              type: "big",
              cur_series: productData.cur_series,
              all_series: productData.serieses.map((productSeries) => ({
                series: productSeries.series,
                ware_house__ware_house: productSeries.ware_house__ware_house,
                rack__rack_name: productSeries.rack__rack_name,
                rack__row__row_name: productSeries.rack__row__row_name,
              })),
              selectedSeries: productData.cur_series
                ? [productData.cur_series]
                : additionalInfo
                ? [additionalInfo]
                : [],
            };

            return [...prev, newProduct];
          });
        } else {
          console.log("Series is Not available.");
          setError(`Your Barcode is Expired ! `);
          setOpenError(true);
        }
      } else {
        setScannedProducts((prev) => {
          // const existingProduct = prev.find(
          //   (item) =>
          //     item.sku.slice("-")[1] === baseSku ||
          //     item.product_sku.slice("-")[1] === baseSku ||
          //     item.sku === baseSku
          // );

          const existingProduct = prev.find((item) => {
            const sku = item.sku || ""; // Default to empty string if sku is undefined
            const productSku = item.product_sku || ""; // Default to empty string if product_sku is undefined

            return (
              sku.slice("-")[1] === baseSku ||
              productSku.slice("-")[1] === baseSku ||
              sku === baseSku
            );
          });

          console.log("prev :", prev);
          console.log("existingProduct :", existingProduct);

          if (existingProduct) {
            return prev.filter(
              (item) => item.product_sku !== existingProduct.product_sku
            );
          }

          // if (existingProduct) {
          //   return scannedProducts.filter(
          //     (p) => p.product_sku !== existingProduct.product_sku
          //   )
          // }

          const newProduct = {
            sku: baseSku,
            product_name: productData.product_name,
            product_sku: productData.product_sku,
            type: "big",
            cur_series: productData.cur_series,
            all_series: productData.serieses.map((productSeries) => ({
              series: productSeries.series,
              ware_house__ware_house: productSeries.ware_house__ware_house,
              rack__rack_name: productSeries.rack__rack_name,
              rack__row__row_name: productSeries.rack__row__row_name,
            })),
            selectedSeries: productData.cur_series
              ? [productData.cur_series]
              : additionalInfo
              ? [additionalInfo]
              : [],
          };

          return [...prev, newProduct];
        });
      }
    }, 300),
    [] // dependencies
  );

  // Series Modal Open
  const [openSeriesModal, setOpenSeriesModal] = useState(false);
  const [selectedSeriesProduct, setSelectedSeriesProduct] = useState(null);

  const handleOpenSeriesModal = (product) => {
    setSelectedSeriesProduct(product); // Set the selected product to pass to the modal
    setOpenSeriesModal(true); // Open the modal
  };

  const handleCloseSeriesModal = () => {
    setOpenSeriesModal(false); // Close the modal
    setSelectedSeriesProduct(null); // Clear the selected product
  };

  // const [selectedSeriesCounts, setSelectedSeriesCounts] = useState({});

  // For Big product update Series.
  const [openRemoveSkipProductModal, setOpenRemoveSkipProductModal] =
    useState(false);
  const [selectedRemoveSkipProductDetail, setSelectedRemoveSkipProductDetail] =
    useState([]);

  const handleCloseOpenRemoveSkipProductModal = () => {
    setOpenRemoveSkipProductModal(false);
  };

  const updateSelectedSeries = (sku, selectedSeries, cur_series) => {
    setScannedProducts((prev) => {
      return prev.map((product) => {
        if (product.sku === sku) {
          // Check if the cur_series is already in the selectedSeries array
          if (selectedSeries.includes(cur_series)) {
            // setError(
            //   `Series ${cur_series} is already selected for product ${sku}`
            // );
            // setOpenError(true);
            setOpenRemoveSkipProductModal(true);
            setSelectedRemoveSkipProductDetail({
              product,
              cur_series: cur_series,
            });
            return product; // Do not update the product if the series is already selected
          }
          return { ...product, selectedSeries };
        }
        return product;
      });
    });
  };

  const handleSamllProductQuantityChange = (e, product, index) => {
    const value = Math.max(
      0,
      Math.min(Number(e.target.value), product.in_stock)
    );
    const updatedProducts = [...scannedProducts];
    updatedProducts[index].qty = value;
    setScannedProducts(updatedProducts);
  };

  // Warehouse check

  const [openWarehouseModalChange, setOpenWarehouseModalChange] =
    useState(false);
  const [pendingWarehouse, setPendingWarehouse] = useState("");

  const handleWarehouseChange = (e) => {
    const selectedWarehouseId = e.target.value;

    // Check if selectedWarehouseId is valid
    if (selectedWarehouseId) {
      const selectedWarehouse = warehouses.find(
        (wh) => wh.id === selectedWarehouseId
      );

      if (selectedWarehouse && warehouse == "") {
        setPendingWarehouse(selectedWarehouse.id);
        setWarehouse(selectedWarehouse.id);
      } else {
        setPendingWarehouse(selectedWarehouse.id);
        setOpenWarehouseModalChange(true);
      }
    }
  };

  const handleConfirmWarehouseChange = () => {
    setWarehouse(pendingWarehouse);
    setOpenWarehouseModalChange(false);
    setScannedProducts([]);
    setSelectedProducts([]);
    setSelectedProducts2([]);
  };

  const handleCancelWarehouseChange = () => {
    setOpenWarehouseModalChange(false);
    setPendingWarehouse("");
  };

  // Web Cam
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const [isScanned, setIsScanned] = useState(false); // State to track if a barcode has been scanned

  const playSound = () => {
    const sound = document.getElementById("scanSound");
    if (sound) {
      sound.play();
    }
  };

  const handleOpenCamera = () => {
    setIsCameraOpen(true);
    setIsScanned(false); // Reset scanning state when opening the camera
  };

  const handleCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const handleDetected = useCallback(
    (result) => {
      const scannedCode = result.codeResult.code;
      console.log("Scanned Code: ", scannedCode);

      // Only set scan data and play sound if a barcode has not already been scanned
      if (!isScanned) {
        if (type == 2) {
          setScanData(scannedCode);
        }
        playSound();
        setIsScanned(true); // Mark that a barcode has been scanned
        setIsCameraOpen(false);
      }
    },
    [isScanned]
  );

  useEffect(() => {
    if (isCameraOpen && webcamRef.current) {
      const interval = setInterval(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc && !isScanned) {
          // Only scan if not already scanned
          Quagga.decodeSingle(
            {
              src: imageSrc,
              numOfWorkers: 0, // Needs to be 0 when used with `decodeSingle`
              inputStream: {
                size: 800,
              },
              decoder: {
                readers: ["code_128_reader"], // Adjust according to your needs
              },
            },
            (result) => {
              if (result && result.codeResult) {
                handleDetected(result);
              }
            }
          );
        }
      }, 700);

      return () => clearInterval(interval);
    }
  }, [isCameraOpen, handleDetected, isScanned]);

  return (
    <>
      {console.log("selectedWarehouse :", warehouse)}
      {console.log("scannedProducts :", scannedProducts)}

      <Error openError={openError} setOpenError={setOpenError} error={error} />
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />

      <RemoveSkipProduct
        selectedRemoveSkipProductDetail={selectedRemoveSkipProductDetail}
        handleCloseOpenRemoveSkipProductModal={
          handleCloseOpenRemoveSkipProductModal
        }
        scannedProducts={scannedProducts}
        setScannedProducts={setScannedProducts}
        openRemoveSkipProductModal={openRemoveSkipProductModal}
      />

      {/* // Out Stock Big Type Size Product Series Selection Modal  */}
      {openSeriesModal && (
        <SeriesModal
          open={openSeriesModal}
          handleClose={handleCloseSeriesModal}
          product={selectedSeriesProduct}
          updateSelectedSeries={updateSelectedSeries}
        />
      )}

      <AssignRackModal
        openModal={stockInModal}
        setOpenModal={setStockInModal}
        warehouseId={warehouse}
        p_sku={p_sku}
        setInven={setInven}
        inven={inven}
        qty={qty}
      />

      {/* Confirmation Modal */}
      <Dialog
        open={openWarehouseModalChange}
        onClose={handleCancelWarehouseChange}
      >
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <p>
            Changing the warehouse will remove all data. Do you want to proceed?
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelWarehouseChange} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmWarehouseChange} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/stock_adjustments")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Inventory</Typography>
              <Typography color="inherit">Stock Adjustments</Typography>
              <Typography sx={{ color: "text.primary" }}>
                Adjust Stocks
              </Typography>
            </Breadcrumbs>
          </div>

          <div
            className="my-2"
            style={{
              height: "78vh",
              width: "100%",
              backgroundColor: "#ffffff",
              marginTop: "2px",
            }}
          >
            <div className="row" style={{ padding: "5px 15px" }}>
              <div className="col-lg-2 p-3">
                <FormControl fullWidth size="small">
                  <InputLabel id="warehouse-select-label">
                    Stock Type
                  </InputLabel>
                  <Select
                    labelId="stock-select-label"
                    value={type}
                    onChange={handleTypeChange} // Use the handler here
                    label="Stock Type"
                  >
                    <MenuItem value="1">Stock In</MenuItem>
                    <MenuItem value="2">Stock Out</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-2 p-3">
                <FormControl fullWidth size="small">
                  <InputLabel id="warehouse-select-label">Warehouse</InputLabel>
                  <Select
                    labelId="warehouse-select-label"
                    value={warehouse} // Ensure this is controlled by state
                    onChange={handleWarehouseChange}
                    label="Warehouse"
                  >
                    {warehouses.map((ware) => (
                      <MenuItem key={ware.id} value={ware.id}>
                        {ware.ware_house}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col-lg-3 p-3">
                <TextField
                  size="small"
                  multiline
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={1}
                  style={{ width: "100%" }}
                  label="Notes"
                ></TextField>
              </div>

              {type && type == 1 ? (
                <div className="col-lg-3 p-3">
                  <TextField
                    key={refreshKey}
                    fullWidth
                    type="search"
                    disabled={!type || !istyping}
                    size="small"
                    label="Search Products..."
                    variant="outlined"
                    value={searchQuery} // Control the value of the input
                    onChange={handleInputChange} // Update onChange
                  />
                  {displaySearch && displaySearch ? (
                    <>
                      <div
                        id="searchList"
                        ref={searchListRef}
                        style={{
                          border: "1px solid #dedede",
                          position: "absolute",
                          zIndex: "2",
                          right: "3.5vw",
                          marginTop: "3px",
                          maxHeight: "50vh",
                          overflowY: "scroll",

                          backgroundColor: "white",
                          width: "40%",
                        }}
                      >
                        {products.map((product, index) => (
                          <>
                            <div
                              className="card p-3"
                              style={{ display: "flex" }}
                              key={index}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => handleCheckboxChange(product)}
                              >
                                <Checkbox
                                  checked={selectedProducts2.some(
                                    (prev) =>
                                      prev.product_sku === product.product_sku
                                  )}
                                />
                                {/* {selectedProducts2.map((prev, ind) => (prev.product_sku == product.product_sku ? "✅" : "❌"))} */}
                                {product.product_sku +
                                  " : " +
                                  product.product_name}
                              </span>
                            </div>
                          </>
                        ))}
                      </div>
                    </>
                  ) : null}
                </div>
              ) : (
                <div className="col-lg-3 p-3"></div>
              )}

              <div className="col-lg-2 p-3">
                <Button
                  disabled={!warehouse || !type}
                  style={{ marginTop: "5px", float: "right" }}
                  onClick={handleSubmitData}
                  variant="contained"
                >
                  Confirm Adjustment
                </Button>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                {isCameraOpen ? (
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    width={1920}
                    height={220}
                    screenshotFormat="image/jpeg"
                    videoConstraints={{
                      facingMode: "environment",

                      border: "none",
                    }}
                    style={{
                      marginBottom: "12px",
                      border: "3px dashed green",
                      padding: "0",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : null}
              </div>
            </div>

            <div
              className="p-3"
              style={{
                height: "77vh",
                marginTop: "-15px",
                width: "100%",
                backgroundColor: "#ffffff",
              }}
            >
              {type && type == 1 ? (
                <>
                  <DataGrid
                    disableRowSelectionOnClick
                    slots={{ toolbar: CustomToolbar }}
                    rows={selectedProducts2}
                    columns={stockin}
                    pageSize={10}
                  />
                </>
              ) : type == 2 ? (
                <>
                  {/* =========== Stock Out Component ========  */}

                  <div className="row">
                    <div
                      className="col-md-8 col-12"
                      style={{ display: "flex" }}
                    >
                      <TextField
                        inputRef={inputRef} // Assign the ref to the TextField
                        onChange={
                          itemDisplay && itemDisplay === 1
                            ? handleInputChangeSmall
                            : (e) => setScanData(e.target.value)
                        }
                        value={
                          itemDisplay && itemDisplay === 1
                            ? searchInput
                            : scanData
                        }
                        // value={searchInput}
                        fullWidth
                        size="small"
                        label={
                          itemDisplay && itemDisplay === 1
                            ? "Search Products"
                            : "Scan Product..."
                        }
                        variant="outlined"
                      />
                      {displaySearch ? (
                        <>
                          <div
                            id="searchList"
                            ref={searchListRef}
                            style={{
                              border: "1px solid #dedede",
                              position: "absolute",
                              zIndex: "2",
                              marginTop: "2px",
                              maxHeight: "50vh",
                              maxWidth: "50vw",
                              overflowY: "scroll",
                              backgroundColor: "white",
                              width: "100%",
                            }}
                          >
                            {products.map((product, index) => (
                              <>
                                <div
                                  className="card p-3"
                                  style={{ display: "flex" }}
                                  key={index}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={isProductSelected(product)}
                                        onChange={() =>
                                          handleCheckboxChange(product)
                                        }
                                        name="check"
                                      />
                                    }
                                    label={
                                      product.product_sku +
                                      " : " +
                                      product.product_name
                                    }
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                        </>
                      ) : null}

                      {itemDisplay === 1 ? null : (
                        <>
                          <Button
                            onClick={handleScanFocus}
                            variant="contained"
                            color="success"
                            style={{ marginLeft: "5px" }}
                          >
                            SCAN
                          </Button>
                        </>
                      )}
                    </div>
                    <div className="col-md-2 col-12  mt-2 mt-md-0 ">
                      <Button
                        variant="outlined"
                        color={
                          itemDisplay && itemDisplay === 1 ? "error" : "success"
                        }
                        style={{
                          marginLeft: "2px",
                          width: "100%",
                          height: "40px",
                        }}
                        onClick={handleClickSmallItems}
                        size="small"
                      >
                        {itemDisplay && itemDisplay === 1
                          ? "Scan Product"
                          : "Search Product"}
                      </Button>
                    </div>
                  </div>

                  {/* // Stock Out  Table Data  */}
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="cards my-2">
                        <div className="products_table_class">
                          <TableContainer component={Paper}>
                            <Table
                              sx={{ minWidth: 650 }}
                              size="small"
                              aria-label="a dense table"
                            >
                              <TableHead className="table-head">
                                <TableRow>
                                  <TableCell className="p-md-1 p-0">
                                    SKU
                                  </TableCell>
                                  <TableCell className="p-md-1 p-0">
                                    Product
                                  </TableCell>
                                  <TableCell className="p-md-1 p-0">
                                    {" "}
                                    Quantity
                                  </TableCell>
                                  <TableCell className="p-md-1 p-0">
                                    In Stock
                                  </TableCell>
                                  <TableCell
                                    className="p-md-1 p-0"
                                    align="right"
                                  >
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {scannedProducts &&
                                scannedProducts.length > 0 ? (
                                  <>
                                    {scannedProducts.map((product, index) => (
                                      <TableRow
                                        key={index}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                        style={{
                                          backgroundColor:
                                            product.type !== "big"
                                              ? "#cee2ed"
                                              : "transparent",
                                        }}
                                      >
                                        <TableCell
                                          component="th"
                                          style={{ width: "6.5vw" }}
                                          scope="row"
                                        >
                                          {product.product_sku || ""}
                                        </TableCell>
                                        <TableCell align="left">
                                          {product.product_name || ""}
                                        </TableCell>

                                        {product.type == "big" ? (
                                          <>
                                            <TableCell align="left">
                                              <TextField
                                                className="p-0 m-0"
                                                size="small"
                                                type="number"
                                                value={
                                                  product.selectedSeries
                                                    ? product.selectedSeries
                                                        .length
                                                    : 0
                                                }
                                              />
                                            </TableCell>
                                            <TableCell>
                                              {product.all_series
                                                ? product.all_series.length
                                                : 0}
                                            </TableCell>

                                            {/* <TableCell>
                                                {product.selectedSeries && product.selectedSeries.map((selectedSeri) => (
                                                  <span>{selectedSeri}, </span>
                                                ))}
                                                Total : {product.selectedSeries ? product.selectedSeries.length : 0}
                                              </TableCell> */}
                                          </>
                                        ) : (
                                          <>
                                            <TableCell align="left">
                                              <TextField
                                                className="p-0 m-0"
                                                size="small"
                                                type="number"
                                                value={product.qty || ""}
                                                onChange={(e) =>
                                                  handleSamllProductQuantityChange(
                                                    e,
                                                    product,
                                                    index
                                                  )
                                                }
                                                InputProps={{
                                                  inputProps: {
                                                    min: 0, // Prevent negative values
                                                    max: product.quantity, // Limit the maximum value to available quantity
                                                  },
                                                }}
                                                placeholder="Quantity"
                                              />
                                            </TableCell>

                                            <TableCell>
                                              {/* {product.in_stock ? product.in_stock - (product.qty || 0) : 0} */}
                                              {product.in_stock
                                                ? product.in_stock
                                                : 0}
                                            </TableCell>
                                          </>
                                        )}

                                        <TableCell
                                          align="right"
                                          // className="d-flex align-items-center  gap-2"
                                        >
                                          <>
                                            {product.type == "big" ? (
                                              <Button
                                                onClick={() =>
                                                  handleOpenSeriesModal(product)
                                                } // Open the series modal
                                                style={{ cursor: "pointer" }}
                                                variant="outlined"
                                                color="primary"
                                                className="text-nowrap me-2"
                                              >
                                                Select Series
                                              </Button>
                                            ) : (
                                              ""
                                            )}
                                            <FaTrashAlt
                                              style={{
                                                color: "red",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleDeleteProduct(
                                                  product.product_sku
                                                )
                                              }
                                            />
                                          </>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </>
                                ) : (
                                  <>
                                    <TableRow>
                                      <TableCell
                                        colSpan="9"
                                        style={{
                                          textAlign: "center",
                                          color: "red",
                                        }}
                                      >
                                        No Products Selected
                                      </TableCell>
                                    </TableRow>
                                  </>
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="mobile-webcam-open d-block d-sm-none">
        <audio id="scanSound" src={soundEffect} />
        {isCameraOpen ? (
          <>
            <Button
              variant="contained"
              color="error"
              id="mobile_scan"
              onClick={handleCloseCamera}
              style={{
                position: "fixed",
                bottom: "15px",
                right: "15px",
                padding: "10px 10px",
              }}
            >
              <BsUpcScan style={{ fontSize: "35px", marginRight: "5px" }} />{" "}
              Close
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              id="mobile_scan"
              onClick={handleOpenCamera}
              style={{
                position: "fixed",
                bottom: "15px",
                right: "15px",
                padding: "10px 10px",
              }}
            >
              <BsUpcScan style={{ fontSize: "35px", marginRight: "5px" }} />
              Scan
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default AddStockAdjustment;
