import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import {
  apiKey,
  fetch_all_product,
  fetch_customer,
  fetch_sale_detail,
} from "../../Api";
import { useNavigate, useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CreateCustomer from "../OurClients/Clients/CreateCustomer";
import Success from "../../Messages/Success";
import Error from "../../Messages/Error";
import { debounce } from "lodash";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { IoMdArrowRoundBack } from "react-icons/io";

function ViewSaleDetail() {
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [displaySearch, setDisplay] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [quantities, setQuantities] = useState({});
  const [mrps, setMrps] = useState({});
  const [customers, setCustomers] = useState([]);
  const [searchquery, setSearchQuery] = useState("");
  const [customer, setCustomer] = useState("");
  const [address, setAddress] = useState("");
  const [note, setNote] = useState("");
  const [sale_date, setS_date] = useState("");
  const [discountedprice, setDiscountedPrice] = useState(0);
  const [totalamount, setTotalAMount] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [othercharge, setOtherCharge] = useState(0);

  const userDetails = JSON.parse(localStorage.getItem("ktt_users"));

  const navigate = useNavigate();
  useEffect(() => {
    fetchCustomers();
    fetchSales();
  }, [searchquery]);

  const fetchCustomers = (query = searchquery) => {
    axios
      .get(fetch_customer, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          search: query,
        },
      })
      .then((response) => {
        // console.log(response);
        setCustomers(response.data.customers);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchSales = () => {
    axios
      .get(fetch_sale_detail, {
        headers: {
          "API-Key": apiKey,
        },
        params: {
          sale_pk: parseInt(id),
        },
      })
      .then((response) => {
        // console.log(response.data.data);
        setCustomer(response.data.data.billing_customer.id);
        setNote(response.data.data.note);
        setAddress(response.data.data.billing_customer.primary_shipping.id);
        setS_date(response.data.data.sale_date);
        setDiscountedPrice(response.data.data.discount_amount);
        setTotalAMount(response.data.data.total_amount);
        setOtherCharge(response.data.data.other_charge);
        setShipping(response.data.data.courier_charge);
        setSelectedProducts(response.data.data.items);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const debounceFetchProducts = useCallback(
    debounce(async (value) => {
      try {
        const response = await axios.post(
          fetch_all_product,
          { search: value, customer_pk: parseInt(customer) },
          { headers: { "Content-Type": "application/json", "API-Key": apiKey } }
        );
        console.log(response.data.data.products);
        const fetchedProducts = response.data.data.products;
        setProducts(fetchedProducts);
        setDisplay(true);
      } catch (error) {
        setProducts([]);
        setDisplay(false);
      }
    }, 500), // 500ms delay
    [customer] // Dependency array
  );

  // Handle input change, using debounceFetchProducts
  const handleInputChange = useCallback(
    (event) => {
      const value = event.target.value;
      setSearchInput(value);

      if (value.trim().length >= 2) {
        debounceFetchProducts(value);
      } else {
        setProducts([]);
        setDisplay(false);
      }
    },
    [debounceFetchProducts]
  );

  const handleQuantityChange = (event, product) => {
    const { value } = event.target;
    const quantity = parseInt(value, 10);

    if (quantity > 0 || value === "") {
      setQuantities((prevQuantities) => ({
        ...prevQuantities,
        [product.product_sku]: quantity || 1,
      }));
    }
  };

  const [opencustomer, setCustomerOpen] = useState(false);
  const handleOpencustomer = () => setCustomerOpen(true);
  const handleClosecustomer = () => setCustomerOpen(false);

  useEffect(() => {
    const getCustomerDetails = (customerId) => {
      const foundCustomer = customers.find((cust) => cust.id === customerId);
      if (foundCustomer) {
        return {
          name: foundCustomer.customer_name,
          primaryShipping: foundCustomer.primary_shipping || {}, // Default to an empty object if not available
        };
      }
      return {
        name: "",
        primaryShipping: {},
      };
    };

    const customerDetails = getCustomerDetails(customer);
    const location = customerDetails.primaryShipping?.id || "";

    setAddress(location); // Update address when customerDetails.primaryShipping.id changes
  }, [customer, customers, setAddress]);

  const [openBarcode, setOpenBarcode] = useState(false);

  const handleCloseBarcode = () => {
    setOpenBarcode(false);
  };

  const handleSelectedProducts = (products) => {
    setSelectedProducts(products);
  };

  return (
    <>
      <Success
        openSuccess={openSuccess}
        setOpenSuccess={setOpenSuccess}
        success={success}
      />
      <Error openError={openError} setOpenError={setOpenError} error={error} />

      <CreateCustomer
        opencustomer={opencustomer}
        handleOpencustomer={handleOpencustomer}
        handleClosecustomer={handleClosecustomer}
        fetchCustomers={fetchCustomers}
      />

      <div className="main-panel">
        <div className="content-wrapper">
          <div style={{ display: "flex" }}>
            <IoMdArrowRoundBack
              id="backbtn"
              onClick={() => navigate("/all_sales")}
            />
            <Breadcrumbs aria-label="breadcrumb">
              <Typography color="inherit">Sales</Typography>
              <Typography color="inherit">Sale Details</Typography>
              <Typography sx={{ color: "text.primary" }}>#{id}</Typography>
            </Breadcrumbs>
          </div>

          <div className="row my-2">
            <div className="col-lg-9">
              <div className="card p-4">
                <div className="row">
                  <div className="col-lg-3">
                    Customer:{" "}
                    {customers.find((cust) => cust.id === customer) ? (
                      <span>
                        {
                          customers.find((cust) => cust.id === customer)
                            .customer_name
                        }
                      </span>
                    ) : (
                      <span>No matching customer found</span>
                    )}
                  </div>
                  <div className="col-lg-2">Date: {sale_date}</div>
                  <div className="col-lg-4">Bill to: {address}</div>
                  <div className="col-lg-3">Note: {note}</div>
                </div>
              </div>

              <div
                className="card my-2 "
                style={{ padding: "10px 10px 2px 10px" }}
              >
                <div className="row">
                  <div className="card p-4 ">
                    <div className="products_table_class">
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 600 }}
                          size="small"
                          aria-label="a dense table"
                        >
                          <TableHead className="table-head">
                            <TableRow>
                              <TableCell>SKU</TableCell>
                              <TableCell align="left">Product</TableCell>

                              <TableCell align="left">MRP</TableCell>
                              <TableCell align="left">Discount</TableCell>
                              <TableCell align="right">Discount Amt</TableCell>
                              {/* <TableCell align="right">Last Price</TableCell> */}

                              <TableCell align="center">Quantity</TableCell>
                              <TableCell align="right">Total</TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedProducts && selectedProducts.length > 0 ? (
                              <>
                                {selectedProducts.map((product, index) => (
                                  <TableRow
                                    key={index}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell
                                      component="th"
                                      style={{ width: "6.5vw" }}
                                      scope="row"
                                    >
                                      {product.product_sku || product.sku}
                                    </TableCell>
                                    <TableCell align="left">
                                      {product.product_name || product.name}
                                    </TableCell>

                                    <TableCell align="left">
                                      ₹{product.mrp}.00
                                    </TableCell>

                                    <TableCell align="left">
                                      {product.discount_amount
                                        ? (
                                            (product.discount_amount /
                                              product.mrp) *
                                            100
                                          ).toFixed(2)
                                        : "0.00"}
                                      %
                                    </TableCell>

                                    <TableCell align="right">
                                      ₹{product.discount_amount}.00
                                    </TableCell>

                                    <TableCell align="center">
                                      {product.quantity}
                                    </TableCell>

                                    <TableCell
                                      align="right"
                                      style={{
                                        width: "5vw",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      ₹{product.quantity * product.price}
                                      .00
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </>
                            ) : (
                              <>
                                <TableRow>
                                  <TableCell
                                    colSpan="9"
                                    style={{
                                      textAlign: "center",
                                      color: "red",
                                    }}
                                  >
                                    No Products Selected
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="card p-3">
                {/* <h5>Sale Summary</h5> */}
                <div className="row" style={{ marginTop: "2px" }}>
                  <div className="col-6 my-2">Sub-Total</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    ₹{totalamount}
                  </div>

                  <div className="col-6 my-2">Total Discount (-)</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    ₹{discountedprice}.00
                  </div>
                  <div className="col-6 my-2">Shipping Charge</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    ₹{shipping}.00
                  </div>
                  <div className="col-6 my-2">Other Charges</div>
                  <div className="col-6 my-2" style={{ textAlign: "right" }}>
                    ₹{othercharge}.00
                  </div>
                  <hr style={{ marginBottom: "0px" }} />
                  <div className="col-6 my-2" style={{ color: "black" }}>
                    Total
                  </div>
                  <div
                    className="col-6 my-2"
                    style={{
                      textAlign: "right",
                      color: "red",
                      fontWeight: "bold",
                      fontSize: "19px",
                    }}
                  >
                    ₹{totalamount + othercharge + shipping - discountedprice}.00
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSaleDetail;
